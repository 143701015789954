import React from 'react';

import {Icons, SelectOption, Text, Wrapper} from 'components';
import * as AppEnv from 'app-env';

interface SelectMinField<T> {
  placeholder: string | undefined;
  selected: SelectOption<T> | undefined;
  setIsActive: AppEnv.SetState<boolean>;
}

const SelectMinField = <T,>({
  placeholder,
  selected,
  setIsActive
}: SelectMinField<T>) => (
  <button className="select-min__field" onClick={() => setIsActive(true)}>
    <Wrapper alignItems="center" flexWrap="nowrap" gap={4}>
      {selected?.icon}

      <Text isNoWrap size={16}>
        {selected?.label || placeholder}
      </Text>
    </Wrapper>

    <Icons.MinArrowDown color="blue" />
  </button>
);

export default SelectMinField;

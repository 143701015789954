import React, {useState} from 'react';
import {isolateNumbers} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';
import upd from 'immutability-helper';

import {
  Button,
  Chips,
  Popup,
  PopupFooter,
  PopupWrapper,
  Text,
  Title,
  Wrapper
} from 'components';
import {SocialOption} from '../socials-env';
import {useChatContext} from '../../../../chat-context';
import * as AppEnv from 'app-env';

interface SocialForm {
  instanceOptions: SocialOption[];
  isSocialAdderActive: boolean;
  options: SocialOption[];
  setIsSocialAdderActive: AppEnv.SetState<boolean>;
}

const SocialForm = ({
  instanceOptions,
  isSocialAdderActive,
  options,
  setIsSocialAdderActive
}: SocialForm) => {
  const {setWidget} = useChatContext();
  const [value, setValue] = useState<string>();
  const {i18n, t} = useTranslation();

  const handleClick = () => {
    if (!value) return;

    const social = [...instanceOptions, ...options].find(
      option => option.value == value
    );

    if (!social) return;

    const url = social.comment
      ? social.version == 'telegram'
        ? `https://t.me/${isolateNumbers(social.comment)}`
        : social.version == 'whatcrm'
          ? `https://wa.me/${isolateNumbers(social.comment)}`
          : ''
      : '';

    setIsSocialAdderActive(false);
    setValue(undefined);

    setWidget(prevValue =>
      upd(prevValue, {socials: {$push: [{url, version: social.version}]}})
    );
  };

  return (
    <Popup
      isActive={isSocialAdderActive}
      width={408}
      onClick={() => setIsSocialAdderActive(false)}
    >
      <PopupWrapper>
        <Wrapper direction="column" flexWrap="nowrap" gap={16}>
          <Title.H3>{t`Select a social network`}</Title.H3>

          {instanceOptions.length > 0 && (
            <div>
              <Text color="secondary" size={14} style={{marginBottom: 8}}>
                {t`Your connections`}
              </Text>

              <Chips
                options={instanceOptions}
                value={value}
                onChange={setValue}
              />
            </div>
          )}

          <div>
            <Text color="secondary" size={14} style={{marginBottom: 8}}>
              {t`Other social networks`}
            </Text>

            <Chips options={options} value={value} onChange={setValue} />
          </div>

          {i18n.resolvedLanguage == 'ru' && (
            <Text color="light" size={12}>
              {`*Facebook и Instagram принадлежат организации Meta, которая признана экстремистской и запрещена на территории Российской Федерации.`}
            </Text>
          )}
        </Wrapper>
      </PopupWrapper>

      <PopupFooter>
        <Wrapper gap={12}>
          <Button onClick={handleClick} state={value ? undefined : 'disabled'}>
            {t`Add`}
          </Button>

          <Button color="fade" onClick={() => setIsSocialAdderActive(false)}>
            {t`Close`}
          </Button>
        </Wrapper>
      </PopupFooter>
    </Popup>
  );
};

export default SocialForm;

import React from 'react';
import {useTranslation} from 'react-i18next';

import {AccessMulti} from '../components';
import {SelectOption} from 'components';
import * as AppEnv from 'app-env';

const Messages = () => {
  const {t} = useTranslation();

  const options: SelectOption<keyof AppEnv.RoleAccesses>[] = [
    {label: t`Delete`, value: 'messages_delete'},
    {label: t`Edit`, value: 'messages_edit'},
    {label: t`Send files`, value: 'messages_file'},
    {label: t`Forward`, value: 'messages_forward'},
    {label: t`Send poll`, value: 'messages_poll'},
    {label: t`Reactions`, value: 'messages_reaction'},
    {label: t`Reply`, value: 'messages_reply'},
    {label: t`Send text message`, value: 'messages_text'},
    {label: t`Send voice message`, value: 'messages_voice'}
  ];

  return <AccessMulti options={options} placeholder={t`Messages`} />;
};

export default Messages;

import React from 'react';
import {useTranslation} from 'react-i18next';

import {Text} from 'components';
import {useInstanceBilling} from 'common/hooks';
import {useInstanceContext} from '../../instance-context';

const ChatId = () => {
  const {instance} = useInstanceContext();

  const {t} = useTranslation();
  const billing = useInstanceBilling(instance);

  return (
    <>
      {billing.tariff_plane == 'Enterprise' && instance.version != 'chat' && (
        <Text>
          <Text color="wild" isSpan>
            {t`IP address`}
          </Text>
          {': '}
          {instance.chat_id}
        </Text>
      )}
    </>
  );
};

export default ChatId;

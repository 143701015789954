import React from 'react';
import {Trans} from 'react-i18next';
import {useFormikContext} from 'formik';
import {useTranslation} from 'react-i18next';

import {AttachsPreview} from '../components';
import {
  Popup,
  PopupFooter,
  PopupWrapper,
  Text,
  Title,
  Wrapper
} from 'components';
import {useChatContext} from '../../../../chat-context';
import {useChatFooterContext} from '../../../chat-footer-context';
import {Values} from '../../../chat-footer-env';

import AllowMultipleAnswers from './allow-multiple-answers/allow-multiple-answers';
import Body from './body/body';
import Buttons from './buttons/buttons';
import Send from './send/send';

const ButtonsForm = () => {
  const {dialog} = useChatContext();
  const {isButtonsFormActive, setIsButtonsFormActive} = useChatFooterContext();
  const {setFieldValue, values} = useFormikContext<Values>();

  const {t} = useTranslation();

  const handleClick = async () => {
    setIsButtonsFormActive(false);
    await new Promise(resolve => setTimeout(resolve, 150));

    setFieldValue('attachs', []);
    setFieldValue('buttons', [{body: ''}, {body: ''}]);
  };

  return (
    <Popup isActive={isButtonsFormActive} onClick={handleClick} width={408}>
      <PopupWrapper>
        <Title.H3 style={{marginBottom: 16}}>
          {dialog.version == 'whatcrm'
            ? t`Poll`
            : t`Message with reply options`}
        </Title.H3>

        <Wrapper direction="column" gap={16}>
          <Body />

          {dialog.version == 'chat' && (
            <>
              {values.attachs.length > 0 && (
                <div>
                  <Text size={16} style={{marginBottom: 4}}>
                    <Trans count={values.attachs.length}>
                      {{attachsLength: values.attachs.length.toLocaleString()}}
                      {' file'}
                    </Trans>
                  </Text>

                  <AttachsPreview />
                </div>
              )}
            </>
          )}

          <Buttons />
          {dialog.version == 'whatcrm' && <AllowMultipleAnswers />}
        </Wrapper>
      </PopupWrapper>

      <PopupFooter>
        <Send />
      </PopupFooter>
    </Popup>
  );
};

export default ButtonsForm;

import {IMessageEvent} from 'websocket';

import {getMessageEventData} from 'common/actions';
import * as AppEnv from 'app-env';

import useHandleAddReaction from './use-handle-add-reaction/use-handle-add-reaction';
import useHandleMessage from './use-handle-message/use-handle-message';
import useHandleNewDialog from './use-handle-new-dialog/use-handle-new-dialog';
import useHandlePinDialog from './use-handle-pin-dialog/use-handle-pin-dialog';
import useHandleRemoveReaction from './use-handle-remove-reaction/use-handle-remove-reaction';

type Event =
  | 'add_reaction'
  | 'message'
  | 'new_dialog'
  | 'pin_dialog'
  | 'remove_reaction'
  | 'unpin_dialog';

interface Data {
  dialog?: AppEnv.ChatDialog;
  event: Event;
  message: AppEnv.ChatMessage;
}

const useHandleEvent = () => {
  const handleAddReaction = useHandleAddReaction();
  const handleMessage = useHandleMessage();
  const handleNewDialog = useHandleNewDialog();
  const handlePinDialog = useHandlePinDialog();
  const handleRemoveReaction = useHandleRemoveReaction();

  const handleEvent = (
    instance: AppEnv.Instance,
    messageEvent: IMessageEvent
  ) => {
    const data = getMessageEventData<Data>(messageEvent);

    if (!data) return;
    else if (data.event == 'add_reaction')
      handleAddReaction(instance, data.message);
    else if (data.event == 'message') handleMessage(instance, data.message);
    else if (data.event == 'new_dialog') handleNewDialog(instance, data.dialog);
    else if (data.event == 'pin_dialog')
      handlePinDialog(instance, data.dialog, true);
    else if (data.event == 'remove_reaction')
      handleRemoveReaction(instance, data.message);
    else if (data.event == 'unpin_dialog')
      handlePinDialog(instance, data.dialog, false);
  };

  return handleEvent;
};

export default useHandleEvent;

// assigned_dialog
// removed_dialog_manager

// closed_dialog

// recording
// typing

import {useChatContext} from '../chat-context';

const useIsOnline = () => {
  const {dialog, telegramUser} = useChatContext();

  const {user = undefined} = dialog.version == 'chat' ? dialog : {};
  const {status} = telegramUser || {};

  if (user?.status == 'online' || status?._ == 'userStatusOnline') return true;
  return false;
};

export {default as useContact} from './use-contact/use-contact';
export {default as useGetMsgSenderName} from './use-get-msg-sender-name/use-get-msg-sender-name';
export {useIsOnline};

import {useFormikContext} from 'formik';

const useHandleEnterDown = () => {
  const {isSubmitting, submitForm} = useFormikContext();

  const handleEnterDown = (e: KeyboardEvent | React.KeyboardEvent) => {
    if (e.key != 'Enter') return;
    else if (e.shiftKey) return;
    else if (isSubmitting) return;

    e.preventDefault();
    submitForm();
  };

  return handleEnterDown;
};

export default useHandleEnterDown;

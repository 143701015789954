import {useEffect, useState} from 'react';

import {getIsLimited} from 'common/actions';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

type UseContactList = [
  AppEnv.Contact[] | null | undefined,
  AppEnv.SetState<AppEnv.Contact[] | null | undefined>
];

const useContactList = (user: AppEnv.User): UseContactList => {
  const [contactList, setContactList] = useState<AppEnv.Contact[] | null>();
  const {fetchContactsList} = useRequest();

  const getContactList = async () => {
    const {instances, integration} = user;

    const isUnlimited = Object.values(instances).some(
      instance => !getIsLimited(instance)
    );

    if (!isUnlimited) return setContactList([]);

    const res = await fetchContactsList(integration.id);
    setContactList(res);
  };

  useEffect(() => {
    const {integration} = user;

    if (
      ![
        'mfdanilovv@yandex.ru',
        'whatcrm.bitrix24.by',
        'whatcrmproject.amocrm.ru'
      ].includes(integration.domain) &&
      (process.env.REACT_APP_ENV == 'prod' ||
        process.env.REACT_APP_ENV == 'rocket')
    )
      return;

    getContactList();
  }, []);

  return [contactList, setContactList];
};

export default useContactList;

import React from 'react';
import cn from 'classnames';

import {Text} from 'components';

interface Switch {
  isAlt?: boolean;
  label: string;
  onChange: (arg: boolean) => void;
  value: boolean;
}

const Switch = ({isAlt, label, onChange, value}: Switch) => (
  <div
    className={cn('switch', {switch_alt: isAlt})}
    onClick={() => onChange(!value)}
  >
    <div className={cn('switch__button', {switch__button_active: value})} />
    <Text size={14}>{label}</Text>
  </div>
);

export default Switch;

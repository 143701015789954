import React from 'react';
import {useTranslation} from 'react-i18next';

import {Title} from 'components';

import IsEmail from './is-email/is-email';
import IsName from './is-name/is-name';

const GuestForm = () => {
  const {t} = useTranslation();

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Registration form`}</Title.H3>
      <IsName />
      <IsEmail />
    </div>
  );
};

export default GuestForm;

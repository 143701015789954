import React from 'react';
import {useTranslation} from 'react-i18next';
import upd from 'immutability-helper';

import {Switch} from 'components';
import {useChatContext} from '../../../chat-context';

const IsCallback = () => {
  const {setWidget, widget} = useChatContext();
  const {t} = useTranslation();

  const handleChange = (value: boolean) =>
    setWidget(prevValue => upd(prevValue, {is_callback: {$set: value}}));

  return (
    <Switch
      label={t`“Call me back” button`}
      onChange={handleChange}
      value={widget.is_callback}
    />
  );
};

export default IsCallback;

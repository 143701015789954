import React from 'react';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {InputAlt} from 'components';

const ChatId = () => {
  const [field, meta, helpers] = useField<string>('chat_id');
  const {t} = useTranslation();

  const isValid = !(meta.error && meta.touched);

  return (
    <InputAlt
      {...field}
      autoCapitalize="off"
      isValid={isValid}
      onChange={helpers.setValue}
      placeholder={t`Phone number ID`}
      style={{marginBottom: 16}}
    />
  );
};

export default ChatId;

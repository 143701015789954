import React from 'react';

import {ProfileImage, Text, Wrapper} from 'components';
import * as AppEnv from 'app-env';

interface Manager {
  manager: AppEnv.Manager;
}

const Manager = ({manager}: Manager) => (
  <Wrapper flexWrap="nowrap" gap={8}>
    <ProfileImage id={manager.id} name={manager.fullname || manager.email} />

    <div style={{marginTop: 4, minWidth: 0}}>
      <Text fontWeight={500}>{manager.fullname || manager.email}</Text>

      {manager.fullname && (
        <Text color="light" isNoWrap>
          {manager.email}
        </Text>
      )}
    </div>
  </Wrapper>
);

export default Manager;

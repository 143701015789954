import React, {forwardRef, useEffect, useRef} from 'react';
import cn from 'classnames';
import ReactTextareaAutosize from 'react-textarea-autosize';

interface Textarea {
  autoFocus?: boolean;
  isAlt?: boolean;
  isDisabled?: boolean;
  isValid?: boolean;
  name: string;
  onChange: (arg: string) => void;
  onKeyDown?: React.KeyboardEventHandler<HTMLTextAreaElement>;
  placeholder: string;
  style?: React.CSSProperties;
  value: string;
}

const Textarea = forwardRef<HTMLTextAreaElement, Textarea>(
  (
    {
      autoFocus,
      isAlt,
      isDisabled,
      isValid = true,
      name,
      onChange,
      onKeyDown,
      placeholder,
      style,
      value
    },
    forwardedRed
  ) => {
    const ref = forwardedRed || useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
      if (!autoFocus) return;
      else if (!ref) return;
      else if (!('current' in ref)) return;

      setTimeout(() => {
        if (!ref.current) return;

        ref.current.focus();
        ref.current.selectionStart = value.length;
      }, 150);
    }, []);

    return (
      <ReactTextareaAutosize
        autoFocus={autoFocus}
        className={cn('textarea', {
          textarea_alt: isAlt,
          textarea_error: !isValid
        })}
        disabled={isDisabled}
        name={name}
        onChange={e => onChange(e.target.value)}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        ref={ref}
        style={style as any} // eslint-disable-line
        value={value}
      />
    );
  }
);

Textarea.displayName = 'Textarea';
export default Textarea;

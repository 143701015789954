import upd from 'immutability-helper';

import {getDialogId} from 'common/actions';
import {useChatContext} from '../../../../chat-context';
import {useDispatchContext} from 'pages/workspace/pages/messenger/dispatch/dispatch-context';
import {useMessengerContext} from 'pages/workspace/pages/messenger/messenger-context';
import * as AppEnv from 'app-env';

import useGetMyProfileImage from './use-get-my-profile-image/use-get-my-profile-image';
import {sortTgMsgs, sortTimestampMsgs} from './actions';

const useUpdMsgList = () => {
  const {setDialogs} = useMessengerContext();
  const {instance} = useDispatchContext();
  const {dialog} = useChatContext();

  const getMyProfileImage = useGetMyProfileImage();

  const updMsgList = (msgs: AppEnv.Message[]) => {
    const [msg] = msgs;

    const chatName = msg && 'chatName' in msg ? msg.chatName : undefined;
    const sortedMsgs = dialog.messages ? [...msgs, ...dialog.messages] : msgs;

    if (dialog.version == 'telegram') sortedMsgs?.sort(sortTgMsgs);
    else sortedMsgs?.sort(sortTimestampMsgs);

    setDialogs(prevValue => {
      const index = prevValue?.[instance.id]?.findIndex(
        item => getDialogId(item) == getDialogId(dialog)
      );

      if (index == undefined || index < 0) return prevValue;

      return upd(prevValue, {
        [instance.id]: {
          [index]: {
            messages: {$set: sortedMsgs as any}, // eslint-disable-line
            name: {$apply: target => chatName || target}
          }
        }
      });
    });

    if (!msgs.length) return;

    const isOutgoings = !![...msgs].find(item => {
      if ('direction' in item) return item.direction == 'out';
      else if ('isOutgoing' in item) return item.isOutgoing;
      else if ('fromMe' in item) return item.fromMe;
    });

    if (isOutgoings) getMyProfileImage();
  };

  return updMsgList;
};

export default useUpdMsgList;

import React from 'react';
import {useTranslation} from 'react-i18next';

import {ContextMenuOption, IconsAlt} from 'components';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

const useClear = (
  dialog: AppEnv.Dialog,
  setIsConfirmActive: AppEnv.SetState<boolean>
): ContextMenuOption | null => {
  const {user} = useWorkspaceContext();

  const {t} = useTranslation();

  const {roles} = user.manager;

  if (!['chat', 'telegram', 'whatcrm'].includes(dialog.version)) return null;
  else if (roles.dialogs_delete == false) return null;

  const clear = {
    icon: <IconsAlt.TrashSimple />,
    label: dialog.version == 'whatcrm' ? t`Clear` : t`Delete`,
    onClick: () => setIsConfirmActive(true)
  };

  return clear;
};

export default useClear;

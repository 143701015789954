import {getDialogId} from 'common/actions';
import {processTelegramDialog} from 'common/actions';
import {useAppContext} from 'app-context';
import {useGetDialog, useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

import useGetValidPhone from './use-get-valid-phone/use-get-valid-phone';

const useCreateDialogs = (activeInstances: AppEnv.Instance[]) => {
  const {chatIdList, phoneNumberList, usernameList} = useAppContext();

  const {fetchSearchPublicChat} = useRequest();
  const getDialog = useGetDialog();
  const getValidPhone = useGetValidPhone();

  const createDialogs = async () => {
    const sample: AppEnv.Dialogs = {};

    const promises = activeInstances.map(async instance => {
      const chatIdPromises = chatIdList?.map(async chatId => {
        const res = await getDialog(instance, {id: chatId});

        if (!res) return;

        const isDuplicate = sample[instance.id]?.some(
          dialog => getDialogId(dialog, true) == getDialogId(res, true)
        );

        if (isDuplicate) return;

        if (sample[instance.id]) return sample[instance.id]?.push(res);

        sample[instance.id] = [res];
      });

      const phoneNumberPromises = phoneNumberList?.map(async phoneNumber => {
        const phone = await getValidPhone(instance, phoneNumber);
        const res = await getDialog(instance, {phone});
        if (!res) return;

        const isDuplicate = sample[instance.id]?.some(
          dialog => getDialogId(dialog, true) == getDialogId(res, true)
        );

        if (isDuplicate) return;
        else if (sample[instance.id]) return sample[instance.id]?.push(res);

        sample[instance.id] = [res];
      });

      let usernamePromises: Promise<void>[] | undefined = undefined;

      if (instance.version == 'telegram') {
        usernamePromises = usernameList?.map(async username => {
          const res = await fetchSearchPublicChat(instance.chat_key, username);
          if (!res) return;

          const telegramDialog = processTelegramDialog(res);

          const isDuplicate = sample[instance.id]?.some(
            dialog =>
              getDialogId(dialog, true) == getDialogId(telegramDialog, true)
          );

          if (isDuplicate) return;

          if (sample[instance.id]) {
            sample[instance.id]?.push(telegramDialog);
            return;
          }

          sample[instance.id] = [telegramDialog];
        });
      }

      await Promise.all(chatIdPromises || []);
      await Promise.all(phoneNumberPromises || []);
      await Promise.all(usernamePromises || []);
    });

    await Promise.all(promises);
    return sample;
  };

  return createDialogs;
};

export default useCreateDialogs;

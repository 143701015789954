import React, {useState} from 'react';
import upd from 'immutability-helper';

import {Tag, TagDelete} from 'components';
import {useRequest} from 'common/hooks';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEvn from 'app-env';

interface Label {
  label: AppEvn.Label;
}

const Label = ({label}: Label) => {
  const {setLabelList, user} = useWorkspaceContext();
  const {fetchLabelsDelete} = useRequest();

  const [isDisabled, setIsDisabled] = useState(false);

  const handleClick = async () => {
    const {integration} = user;
    setIsDisabled(true);

    const res = await fetchLabelsDelete(integration.id, label.id);

    setIsDisabled(false);
    if (!res) return;

    setLabelList(prevValue => {
      const index = prevValue?.findIndex(item => item.id == label.id);
      if (index == undefined || index < 0) return prevValue;

      return upd(prevValue, {$splice: [[index, 1]]});
    });
  };

  const {roles} = user.manager;

  return (
    <Tag background={label.color} key={label.id} size={16} tag={label.tag}>
      {roles.dialogs_labels != false && (
        <TagDelete isDisabled={isDisabled} onClick={handleClick} />
      )}
    </Tag>
  );
};

export default Label;

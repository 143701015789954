import React from 'react';
import {useTranslation} from 'react-i18next';

import {Select} from 'components';
import * as AppEnv from 'app-env';

interface DomainFilter {
  domain: string;
  domains: string[] | undefined;
  setDomain: AppEnv.SetState<string>;
}

const DomainFilter = ({domain, domains, setDomain}: DomainFilter) => {
  const {t} = useTranslation();

  const options =
    domains?.map(domain => ({label: domain, value: domain})) || [];

  options.unshift({label: t`All domains`, value: ''});

  return (
    <>
      {options.length > 2 && (
        <Select
          onChange={setDomain}
          options={options}
          placeholder={t`All domains`}
          style={{marginBottom: 16}}
          value={domain}
        />
      )}
    </>
  );
};

export default DomainFilter;

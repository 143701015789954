import React, {forwardRef, useEffect} from 'react';
import {useField} from 'formik';

import {useChatFooterContext} from '../../../chat-footer-context';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

import AttachsForm from './attachs-form/attachs-form';
import Dropzone from './dropzone/dropzone';
import Input from './input/input';

const Attachs = forwardRef<HTMLInputElement>((props, ref) => {
  const {user} = useWorkspaceContext();
  const {isButtonsFormActive, setIsAttachsFormActive} = useChatFooterContext();

  const [field] = useField<AppEnv.Attach[]>('attachs');

  useEffect(() => {
    if (isButtonsFormActive) return;
    setIsAttachsFormActive(field.value.length > 0);
  }, [field.value]);

  const {roles} = user.manager;

  return (
    <>
      <AttachsForm />
      {roles.messages_file != false && <Dropzone />}
      <Input ref={ref} />
    </>
  );
});

Attachs.displayName = 'Attachs';
export default Attachs;

import upd from 'immutability-helper';
import * as AppEnv from 'app-env';

interface MessagePath {
  dialogIndex?: number;
  instanceId?: number;
  messageIndex?: number;
}

const useUpdateMessageBody = (
  dialogs: AppEnv.Dialogs | undefined,
  setDialogs: AppEnv.SetState<AppEnv.Dialogs | undefined>
) => {
  const getMessagePath = (messageId: number | string) => {
    let res: MessagePath = {};
    if (!dialogs) return res;

    Object.keys(dialogs).forEach(id => {
      const instanceId = parseInt(id);

      dialogs[instanceId]?.forEach((dialog, dialogIndex) => {
        const messageIndex = dialog.messages?.findIndex(
          message => message.id == messageId
        );

        if (messageIndex == undefined || messageIndex < 0) return;
        res = {dialogIndex, instanceId, messageIndex};
      });
    });

    return res;
  };

  const updateMessageBody = (
    messageId: number | string,
    body: string,
    editDate?: number
  ) => {
    const {dialogIndex, instanceId, messageIndex} = getMessagePath(messageId);

    if (
      dialogIndex == undefined ||
      instanceId == undefined ||
      messageIndex == undefined
    )
      return;

    setDialogs(prevValue =>
      upd(prevValue, {
        [instanceId]: {
          [dialogIndex]: {
            messages: {
              [messageIndex]: {
                body: {$set: body},
                editDate: {$set: editDate || 0}
              }
            }
          }
        }
      })
    );
  };

  const updateQuotedMsgBody = (messageId: number, body: string) => {
    const {dialogIndex, instanceId, messageIndex} = getMessagePath(messageId);

    if (dialogIndex == undefined) return;
    else if (instanceId == undefined) return;
    else if (messageIndex == undefined) return;

    setDialogs(prevValue =>
      upd(prevValue, {
        [instanceId]: {
          [dialogIndex]: {
            messages: {[messageIndex]: {quotedMsg: {body: {$set: body}}}}
          }
        }
      })
    );
  };

  return {updateMessageBody, updateQuotedMsgBody};
};

export default useUpdateMessageBody;

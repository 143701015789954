import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import upd from 'immutability-helper';

import {Button, Icons, TableData, TableRow, Text, Wrapper} from 'components';
import {RoleForm} from '../../components';
import {useAppContext} from 'app-context';
import {useRequest} from 'common/hooks';
import {useStaffContext} from '../../staff-context';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

import {useRoleTitle} from './hooks';
import RoleDelete from './role-delete/role-delete';

interface Role {
  role: AppEnv.Role;
}

const Role = ({role}: Role) => {
  const {setNotification} = useAppContext();
  const {user} = useWorkspaceContext();
  const {roleList, setRoleList} = useStaffContext();

  const [isDeleteActive, setIsDeleteActive] = useState(false);
  const [isEditActive, setIsEditActive] = useState(false);

  const {putRolesUpdate} = useRequest();
  const {t} = useTranslation();
  const title = useRoleTitle(role);

  const handleSubmit = async (
    values: Pick<AppEnv.Role, 'accesses' | 'title'>
  ) => {
    const {integration} = user;

    const res = await putRolesUpdate(integration.id, role.id, values);
    if (!res) return true;

    setIsEditActive(false);
    setNotification({title: t`Role updated`});

    setRoleList(prevValue => {
      const index = prevValue?.findIndex(item => item.id == role.id);
      if (index == undefined || index < 0) return prevValue;

      return upd(prevValue, {[index]: {$set: res}});
    });

    return true;
  };

  const {integration} = user;

  const pages = [
    {
      icon: (color: 'green' | 'light' = 'light') => (
        <Icons.WhatsAppLogo color={color} size={20} />
      ),
      key: 'instances'
    },
    {
      icon: (color: 'green' | 'light' = 'light') => (
        <Icons.Receipt color={color} size={20} />
      ),
      key: 'billings'
    },
    {
      icon: (color: 'green' | 'light' = 'light') => (
        <Icons.GitMerge color={color} size={20} />
      ),
      key: 'integrations',
      skip: integration.is_integration < 1
    },
    {
      icon: (color: 'green' | 'light' = 'light') => (
        <Icons.Handshake color={color} size={20} />
      ),
      key: 'partners',
      skip: integration.is_partner < 1
    },
    {
      icon: (color: 'green' | 'light' = 'light') => (
        <Icons.Users color={color} size={20} />
      ),
      key: 'managers'
    },
    {
      icon: (color: 'green' | 'light' = 'light') => (
        <Icons.Note color={color} size={20} />
      ),
      key: 'templates'
    },
    {
      icon: (color: 'green' | 'light' = 'light') => (
        <Icons.FolderSimpleLock color={color} size={20} />
      ),
      key: 'blacklists'
    },
    {
      icon: (color: 'green' | 'light' = 'light') => (
        <Icons.CirclesThree color={color} size={20} />
      ),
      key: 'widgets'
    },
    {
      icon: (color: 'green' | 'light' = 'light') => (
        <Icons.Bag color={color} size={20} />
      ),
      key: 'products'
    },
    {
      icon: (color: 'green' | 'light' = 'light') => (
        <Icons.GearSettings color={color} size={20} />
      ),
      key: 'settings'
    }
  ];

  const accesses: AppEnv.RoleAccesses = JSON.parse(role.accesses);

  const isActive = isDeleteActive || isEditActive;
  const isDelete = roleList != undefined && roleList?.length > 1;

  return (
    <TableRow isActive={isActive}>
      <TableData>
        <Text fontWeight={500} size={16}>
          {role.title}
        </Text>

        <Text color="optional" size={12}>
          {title}
        </Text>
      </TableData>

      <TableData>
        <Wrapper gap={8}>
          {pages.map(page => (
            <>
              {!page.skip && (
                <>
                  {accesses[`${page.key}_write` as keyof AppEnv.RoleAccesses] !=
                  false
                    ? page.icon('green')
                    : accesses[
                          `${page.key}_read` as keyof AppEnv.RoleAccesses
                        ] != false
                      ? page.icon()
                      : undefined}
                </>
              )}
            </>
          ))}
        </Wrapper>
      </TableData>

      <TableData isButton isIcon>
        <Button color="transparent" onClick={() => setIsEditActive(true)}>
          <Icons.PencilSimple size={20} />
        </Button>

        <RoleForm
          initialValues={role}
          isActive={isEditActive}
          isUpd
          onSubmit={handleSubmit}
          setIsActive={setIsEditActive}
        />
      </TableData>

      {isDelete && (
        <TableData isButton isIcon>
          <Button color="transparent" onClick={() => setIsDeleteActive(true)}>
            <Icons.TrashSimple size={20} />
          </Button>

          <RoleDelete
            isDeleteActive={isDeleteActive}
            role={role}
            setIsDeleteActive={setIsDeleteActive}
          />
        </TableData>
      )}
    </TableRow>
  );
};

export default Role;

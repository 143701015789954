import {useCopyToClipboard} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {getChatUser} from 'common/actions';
import {ProfileListItemParams} from '../../../../components';
import {useAppContext} from 'app-context';
import {useChatContext} from '../../../../../../chat-context';
import {useDispatchContext} from 'pages/workspace/pages/messenger/dispatch/dispatch-context';

const useReferer = () => {
  const {setNotification} = useAppContext();
  const {instance} = useDispatchContext();
  const {dialog} = useChatContext();

  const {t} = useTranslation();
  const copyToClipboard = useCopyToClipboard();

  const handleClick = async (str: string) => {
    const res = await copyToClipboard(str);
    setNotification({title: res.message});
  };

  if (dialog.version != 'chat') return null;

  const user = getChatUser(instance, dialog);
  if (!user) return null;
  else if (!('referer' in user)) return null;

  const domain: ProfileListItemParams = {
    label: t`http referer`,
    onClick: () => handleClick(user.referer),
    type: 'copy',
    value: user.referer
  };

  return domain;
};

export default useReferer;

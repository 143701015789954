import React, {useRef, useState} from 'react';

import {SelectDropdown} from '../components';
import {SelectOption} from '../select-env';

import SelectMinField from './select-min-field/select-min-field';

interface SelectMin<T> {
  onChange: (value: T) => void;
  options: SelectOption<T>[];
  placeholder?: string;
  value: T | undefined;
}

const SelectMin = <T,>({
  onChange,
  options,
  placeholder,
  value
}: SelectMin<T>) => {
  const [isActive, setIsActive] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const rect = ref.current?.getBoundingClientRect();
  const selected = options.find(option => option.value == value);

  return (
    <div className="select-min" ref={ref}>
      <SelectMinField
        placeholder={placeholder}
        selected={selected}
        setIsActive={setIsActive}
      />

      {rect && (
        <SelectDropdown
          isActive={isActive}
          left={rect.left - 16}
          minWidth={rect.width + 32}
          onChange={onChange}
          options={options}
          setIsActive={setIsActive}
          top={rect.bottom}
          value={value}
        />
      )}
    </div>
  );
};

export default SelectMin;

import React from 'react';
import {Form, Formik} from 'formik';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';
import upd from 'immutability-helper';

import {PageBody, PageFooter, PageMain, SaveButton, Title} from 'components';
import {useAppContext} from 'app-context';
import {useRequest} from 'common/hooks';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';

import DomainPartner from './domain-partner/domain-partner';

interface Values {
  domain_partner: string;
}

const validationSchema = yup
  .object()
  .shape({domain_partner: yup.string().trim().required().min(6)});

const PartnerAdd = () => {
  const {setNotification} = useAppContext();
  const {setUser, user} = useWorkspaceContext();

  const {postIntegrationsPartner} = useRequest();
  const {t} = useTranslation();

  const handleSubmit = async (values: Values) => {
    const {integration} = user;

    const res = await postIntegrationsPartner(
      integration.id,
      values.domain_partner
    );

    if (!res) return true;

    setNotification({title: t`Partner has been added`});

    setUser(prevValue =>
      upd(prevValue, {partner: {$set: {...res, date_add: Date.now() / 1000}}})
    );

    return true;
  };

  return (
    <Formik
      initialValues={{domain_partner: ''}}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validationSchema={validationSchema}
    >
      <Form>
        <PageBody>
          <PageMain>
            <Title.H3 style={{marginBottom: 8}}>{t`Add a partner`}</Title.H3>
            <DomainPartner />
          </PageMain>
        </PageBody>

        <PageFooter>
          <SaveButton>{t`Add`}</SaveButton>
        </PageFooter>
      </Form>
    </Formik>
  );
};

export default PartnerAdd;

import React from 'react';

import {MessageText, UserMessage} from '../../components';
import {Radio} from 'components';
import {useWhatsAppMessageContext} from '../whatsapp-message-context';

const PollCreation = () => {
  const {message} = useWhatsAppMessageContext();

  const options =
    message.pollOptions?.map(item => ({label: item.name, value: ''})) || [];

  return (
    <UserMessage>
      <MessageText isBold>{message.body}</MessageText>

      <Radio options={options} value="-1" isDisabled onChange={() => {}} />
    </UserMessage>
  );
};

export default PollCreation;

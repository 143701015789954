import upd from 'immutability-helper';

import {getDialogId} from 'common/actions';
import {useChatContext} from '../../../../chat-context';
import {useConvert, useRequest} from 'common/hooks';
import {useDispatchContext} from 'pages/workspace/pages/messenger/dispatch/dispatch-context';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import {Values} from '../../../chat-footer-env';
import * as AppEnv from 'app-env';

interface Loaded {
  filename: string | undefined;
  url: string;
}

const useSendAttachs = (setProgress: AppEnv.SetState<number[]>) => {
  const {user} = useWorkspaceContext();
  const {instance} = useDispatchContext();
  const {dialog, quotedMsg} = useChatContext();

  const {postFilesLoadData, postSendFile} = useRequest();
  const convert = useConvert();

  const sendFile = (values: Values, loaded: Loaded, index: number) => {
    const {message} = quotedMsg || {};

    const buttons = !index
      ? values.buttons.filter(button => button.body.trim())
      : undefined;

    const caption = !index ? values.body : undefined;
    const chatId = getDialogId(dialog, true);

    const username = dialog.version == 'telegram' ? dialog.username : undefined;
    const id = username ? {username} : {chatId: chatId || undefined};

    return postSendFile(instance.chat_key, {
      ...id,
      body: loaded.url,
      buttons: buttons?.length ? buttons : undefined,
      caption,
      filename: loaded.filename,
      quotedMsgId: message?.id
    });
  };

  const convertFile = async (src: string) => {
    const {integration} = user;
    const res = await convert(src, 'mp4', integration.domain);

    return res?.url || src;
  };

  const sendAttachs = async (values: Values) => {
    const loads: Loaded[] = [];

    const promises = values.attachs.map(async (attach, i) => {
      setProgress(prevValue => upd(prevValue, {[i]: {$set: 0}}));

      const {file} = attach;
      let src = file ? await postFilesLoadData(file, file.name) : attach.src;
      if (!src) return;

      setProgress(prevValue => upd(prevValue, {[i]: {$set: 45}}));

      const isShouldConvert =
        attach.type.startsWith('video/') &&
        attach.type != 'video/mp4' &&
        dialog.version == 'whatcrm';

      if (isShouldConvert) src = await convertFile(src);
      if (!src) return;

      loads[i] = {filename: attach.title || file?.name, url: src};
      setProgress(prevValue => upd(prevValue, {[i]: {$set: 90}}));
    });

    await Promise.all(promises);

    for (let i = 0; i < loads.length; i++) {
      await sendFile(values, loads[i], i);
      setProgress(prevValue => upd(prevValue, {[i]: {$set: 100}}));
    }

    setProgress([]);
    return true;
  };

  return sendAttachs;
};

export default useSendAttachs;

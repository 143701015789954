import React from 'react';
import {useTranslation} from 'react-i18next';
import upd from 'immutability-helper';

import {Select} from 'components';
import {useChatContext} from '../../../../chat-context';
import * as AppEnv from 'app-env';

interface Option {
  label: string;
  value: AppEnv.ChatWidgetCallbackFieldType;
}

const CallbackFieldType = () => {
  const {widget, setWidget} = useChatContext();
  const {t} = useTranslation();

  const handleChange = (value: AppEnv.ChatWidgetCallbackFieldType) =>
    setWidget(prevValue =>
      upd(prevValue, {callback_field_type: {$set: value}})
    );

  const options: Option[] = [
    {label: t`Nonlinear`, value: 'nonlinear'},
    {label: t`Linear`, value: 'linear'}
  ];

  return (
    <Select
      onChange={handleChange}
      options={options}
      placeholder={t`Phone number input field`}
      value={widget.callback_field_type}
    />
  );
};

export default CallbackFieldType;

import React from 'react';
import {useTranslation} from 'react-i18next';

import {Text} from 'components';
import {useInstanceContext} from '../../instance-context';

const AccessToken = () => {
  const {instance} = useInstanceContext();
  const {t} = useTranslation();

  return (
    <>
      {instance.access_token && instance.version == 'cloud' && (
        <Text>
          <Text color="wild" isSpan>
            {t`Token`}
            {':'}
          </Text>{' '}
          {instance.access_token}
        </Text>
      )}
    </>
  );
};

export default AccessToken;

import React from 'react';
import {formatPhoneNumber, useCopyToClipboard} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {getIsLimited} from 'common/actions';
import {ProfileListItem} from '../components';
import {useAppContext} from 'app-context';
import {useChatContext} from '../../../chat-context';
import {useDispatchContext} from 'pages/workspace/pages/messenger/dispatch/dispatch-context';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';

const Contact = () => {
  const {setNotification} = useAppContext();
  const {user} = useWorkspaceContext();
  const {instance} = useDispatchContext();
  const {contact} = useChatContext();

  const {t} = useTranslation();
  const copyToClipboard = useCopyToClipboard();

  const handleClick = async (str: string) => {
    const res = await copyToClipboard(str);
    setNotification({title: res.message});
  };

  const {integration} = user;
  const isLimited = getIsLimited(instance);
  const phones: string[] = JSON.parse(contact?.phones || '[]');

  return (
    <>
      {contact &&
        !isLimited &&
        ((process.env.REACT_APP_ENV != 'prod' &&
          process.env.REACT_APP_ENV != 'rocket') ||
          [
            'mfdanilovv@yandex.ru',
            'whatcrm.bitrix24.by',
            'whatcrmproject.amocrm.ru'
          ].includes(integration.domain)) && (
          <div className="profile__list">
            {phones.map((phone, i) => (
              <ProfileListItem
                key={i}
                label={t`phone number`}
                onClick={() => handleClick(phone)}
                type="copy"
                value={formatPhoneNumber(phone)}
              />
            ))}

            {contact.username && (
              <ProfileListItem
                label={t`username`}
                onClick={() => handleClick(contact.username)}
                type="copy"
                value={contact.username}
              />
            )}

            {contact.email && (
              <ProfileListItem
                label={t`email`}
                onClick={() => handleClick(contact.email)}
                type="copy"
                value={contact.email}
              />
            )}

            {contact.website && (
              <ProfileListItem
                label={t`website`}
                onClick={() => handleClick(contact.website)}
                value={contact.website}
                href={contact.website}
              />
            )}
          </div>
        )}
    </>
  );
};

export default Contact;

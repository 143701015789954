import React from 'react';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {Input} from 'components';

const Website = () => {
  const [field, , helpers] = useField<string | undefined>('website');
  const {t} = useTranslation();

  return (
    <Input
      {...field}
      onChange={helpers.setValue}
      placeholder={t`Website`}
      type="url"
    />
  );
};

export default Website;

import {useEffect, useState} from 'react';

import {getIsLimited} from 'common/actions';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

type UseLabelList = [
  AppEnv.Label[] | undefined,
  AppEnv.SetState<AppEnv.Label[] | undefined>
];

const useLabelList = (user: AppEnv.User): UseLabelList => {
  const [labelList, setLabelList] = useState<AppEnv.Label[]>();
  const {fetchLabelsList} = useRequest();

  const getLabelList = async () => {
    const {instances, integration} = user;

    const isUnlimited = Object.values(instances).some(
      instance => !getIsLimited(instance)
    );

    if (!isUnlimited) return setLabelList([]);

    const res = await fetchLabelsList(integration.id);
    setLabelList(res || []);
  };

  useEffect(() => {
    const {integration} = user;

    if (
      ![
        'mfdanilovv@yandex.ru',
        'whatcrm.bitrix24.by',
        'whatcrmproject.amocrm.ru'
      ].includes(integration.domain) &&
      (process.env.REACT_APP_ENV == 'prod' ||
        process.env.REACT_APP_ENV == 'rocket')
    )
      return;

    getLabelList();
  }, []);

  return [labelList, setLabelList];
};

export default useLabelList;

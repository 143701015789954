import React from 'react';
import {useTranslation} from 'react-i18next';
import upd from 'immutability-helper';

import {Textarea, Text} from 'components';
import {useChatContext} from '../../../../chat-context';

const UnavailableText = () => {
  const {setWidget, widget} = useChatContext();
  const {t} = useTranslation();

  const handleChange = (value: string) =>
    setWidget(prevValue => upd(prevValue, {unavailable_text: {$set: value}}));

  return (
    <div>
      <Text size={16} style={{marginBottom: 8}}>
        {t`Message text when all managers are unavailable`}
      </Text>

      <Textarea
        name="unavailable_text"
        value={widget.unavailable_text}
        onChange={handleChange}
        placeholder={t`Type text`}
      />
    </div>
  );
};

export default UnavailableText;

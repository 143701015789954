import React, {useContext} from 'react';
import * as AppEnv from 'app-env';

interface ChatFooterContext {
  isAttachsFormActive: boolean;
  isButtonsFormActive: boolean;
  isVoiceRecording: boolean;
  progress: number[];
  setIsAttachsFormActive: AppEnv.SetState<boolean>;
  setIsButtonsFormActive: AppEnv.SetState<boolean>;
  setIsVoiceRecording: AppEnv.SetState<boolean>;
  setTemplateState: AppEnv.SetState<AppEnv.TemplateState>;
  templateState: AppEnv.TemplateState;
}

const ChatFooterContext = React.createContext<ChatFooterContext>(
  {} as ChatFooterContext
);

export const useChatFooterContext = () => useContext(ChatFooterContext);
export default ChatFooterContext;

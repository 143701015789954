import React from 'react';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {ButtonColor, InputColor, Wrapper} from 'components';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';

const Color = () => {
  const {labelList} = useWorkspaceContext();

  const [field, , helpers] = useField<string>('color');
  const {t} = useTranslation();

  const colors = labelList?.reduce((acc, label) => {
    if (acc.includes(label.color)) return acc;
    acc.push(label.color);

    return acc;
  }, [] as string[]);

  return (
    <Wrapper direction="column" gap={8}>
      <InputColor
        {...field}
        onChange={helpers.setValue}
        placeholder={t`Color`}
      />

      {colors && colors.length > 0 && (
        <Wrapper gap={8}>
          {colors.map((color, i) => (
            <ButtonColor
              background={color}
              key={i}
              onClick={() => helpers.setValue(color)}
            />
          ))}
        </Wrapper>
      )}
    </Wrapper>
  );
};

export default Color;

import React from 'react';
import {useFormikContext} from 'formik';
import {useTranslation} from 'react-i18next';

import {SaveButton} from 'components';
import {useChatContext} from '../../../../../chat-context';
import {Values} from '../../../../chat-footer-env';

const Send = () => {
  const {dialog} = useChatContext();
  const {values} = useFormikContext<Values>();

  const {t} = useTranslation();

  const getIsValid = () => {
    const isAttachsValid = values.attachs.length > 0;
    const isBodyValid = values.body.trim().length > 0;

    if (dialog.version == 'chat') return isAttachsValid || isBodyValid;

    const isButtonsValid =
      values.buttons.filter(item => item.body.trim()).length > 1;

    return isBodyValid && isButtonsValid;
  };

  const isValid = getIsValid();

  return (
    <SaveButton state={!isValid ? 'disabled' : undefined} width="100%">
      {t`Send`}
    </SaveButton>
  );
};

export default Send;

import React from 'react';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {Select} from 'components';
import {useAccesses} from '../hooks';
import * as AppEnv from 'app-env';

interface Access {
  read: keyof AppEnv.RoleAccesses;
  write: keyof AppEnv.RoleAccesses;
  placeholder: string;
}

const Access = ({placeholder, read, write}: Access) => {
  const [, , helpers] = useField<string>('accesses');
  const {t} = useTranslation();
  const accesses = useAccesses();

  const handleChange = (value: string) => {
    const newRead = !!value;
    const newWrite = value == 'write';

    const newAccesses = {...accesses, [read]: newRead, [write]: newWrite};
    const newValue = JSON.stringify(newAccesses);

    helpers.setValue(newValue);
  };

  const options = [
    {label: t`No access`, value: ''},
    {label: t`Read-only`, value: 'read'},
    {label: t`Full access`, value: 'write'}
  ];

  const value =
    (accesses[write] ?? true)
      ? 'write'
      : (accesses[read] ?? true)
        ? 'read'
        : '';

  return (
    <Select
      onChange={handleChange}
      options={options}
      placeholder={placeholder}
      value={value}
    />
  );
};

export default Access;

import {getDialogId} from 'common/actions';
import {useChatContext} from '../../../../chat-context';
import {useDispatchContext} from 'pages/workspace/pages/messenger/dispatch/dispatch-context';
import {useRequest} from 'common/hooks';
import {Values} from '../../../chat-footer-env';

const useSendText = () => {
  const {instance} = useDispatchContext();
  const {dialog, quotedMsg} = useChatContext();

  const {postSendMessage} = useRequest();

  const sendText = async (values: Values) => {
    const chatId = getDialogId(dialog, true);
    const username = dialog.version == 'telegram' ? dialog.username : undefined;
    const id = username ? {username} : {chatId: chatId || undefined};

    const res = await postSendMessage(instance.chat_key, {
      ...id,
      body: values.body,
      quotedMsgId: quotedMsg?.message.id
    });

    return res != null;
  };

  return sendText;
};

export default useSendText;

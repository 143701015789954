import upd from 'immutability-helper';

import {useWebSocketProviderContext} from 'pages/workspace/web-socket-provider/web-socket-context';
import * as AppEnv from 'app-env';

const useHandleNewDialog = () => {
  const {setDialogs} = useWebSocketProviderContext();

  const handleNewDialog = (
    instance: AppEnv.Instance,
    dialog: AppEnv.ChatDialog | undefined
  ) => {
    if (!dialog) return;
    setDialogs(prevValue => upd(prevValue, {[instance.id]: {$push: [dialog]}}));
  };

  return handleNewDialog;
};

export default useHandleNewDialog;

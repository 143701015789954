import {useState} from 'react';
import {FFmpeg} from '@ffmpeg/ffmpeg';

const useGetFfmpeg = () => {
  const [ffmpeg, setFfmpeg] = useState<FFmpeg>();

  const getFfmpeg = async () => {
    if (ffmpeg) return ffmpeg;

    const ffmpegInstance = new FFmpeg();

    await ffmpegInstance.load({
      coreURL: '/static/ffmpeg-core/ffmpeg-core.js',
      wasmURL: '/static/ffmpeg-core/ffmpeg-core.wasm'
    });

    setFfmpeg(ffmpegInstance);
    return ffmpegInstance;
  };

  return getFfmpeg;
};

export default useGetFfmpeg;

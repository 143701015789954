import React, {useEffect, useRef, useState} from 'react';
import _ from 'lodash';

import {Tag} from 'components';
import * as AppEnv from 'app-env';

interface TagList {
  labels: AppEnv.Label[];
}

const TagList = ({labels}: TagList) => {
  const [end, setEnd] = useState<number>();
  const ref = useRef<HTMLDivElement>(null);

  const handleResize = () => {
    if (!ref.current) return;
    else if (ref.current.clientWidth >= ref.current.scrollWidth) return;

    let clientWidth = 0;
    let i = 0;

    Array.from(ref.current.children).forEach(child => {
      if (child.classList.contains('tag_count')) return;
      else if (!ref.current?.clientWidth) return;
      else if (clientWidth + child.clientWidth > ref.current?.clientWidth)
        return;

      clientWidth += child.clientWidth + 8;
      i += 1;
    });

    if (i < labels.length) return setEnd(i - 1);
    setEnd(i);
  };

  useEffect(() => {
    handleResize();
  }, [labels]);

  useEffect(() => {
    window.addEventListener('resize', _.debounce(handleResize));

    return () => {
      window.removeEventListener('resize', _.debounce(handleResize));
    };
  }, []);

  const sliced = labels.slice(0, end);

  return (
    <div className="tag-list" ref={ref}>
      {labels.map((label, i) => (
        <Tag
          background={label.color}
          key={label.id}
          order={i}
          tag={label.tag}
          visibility={i >= sliced.length ? 'hidden' : undefined}
        />
      ))}

      {sliced.length < labels.length && (
        <Tag
          isCount
          order={sliced.length - 1}
          tag={`+${labels.length - sliced.length}`}
        />
      )}
    </div>
  );
};

export default TagList;

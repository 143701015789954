import React, {RefObject, useEffect, useRef} from 'react';
import {Picker} from 'emoji-mart';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';
import data from '@emoji-mart/data';

import {Button, Icons} from 'components';
import {insertSubstr} from 'common/actions';
import {useAppContext} from 'app-context';

interface Value {
  native: string;
}

interface Emoji {
  textRef: RefObject<HTMLTextAreaElement>;
}

const Emoji = ({textRef}: Emoji) => {
  const {theme} = useAppContext();

  const [field, , helpers] = useField<string>('body');
  const {i18n: i18n} = useTranslation();
  const ref = useRef<HTMLDivElement>(null);

  const handleEmojiSelect = ({native}: Value) => {
    const value = insertSubstr(textRef, field.value, native);
    helpers.setValue(value);
  };

  useEffect(() => {
    const lang = i18n.resolvedLanguage?.replace('id', 'en') || 'en';

    // eslint-disable-next-line
    const json = require(`@emoji-mart/data/i18n/${lang}.json`);

    new Picker({
      data,
      i18n: json,
      onEmojiSelect: handleEmojiSelect,
      previewPosition: 'none',
      ref,
      theme: theme != 'system' ? theme : undefined
    });
  }, [field.value]);

  return (
    <div className="emoji-picker">
      <Button color="transparent" onClick={() => {}}>
        <Icons.Smiley />
      </Button>

      <div className="emoji-picker__mart" ref={ref} />
    </div>
  );
};

export default Emoji;

import React from 'react';
import {Formik, Form} from 'formik';
import {useTranslation} from 'react-i18next';

import {
  Button,
  Popup,
  PopupFooter,
  PopupWrapper,
  SaveButton,
  Title,
  Wrapper
} from 'components';
import {useDispatchContext} from 'pages/workspace/pages/messenger/dispatch/dispatch-context';
import {useChatContext} from 'pages/workspace/pages/messenger/dispatch/chat-provider/chat/chat-context';
import {useWhatsAppMessageContext} from '../../whatsapp-message-context';
import {useRequest} from 'common/hooks';
import {getDialogId} from 'common/actions';
import * as AppEnv from 'app-env';

import SectionList from './section-list/section-list';
import {Values} from './listing-env';

interface Listing {
  isListActive: boolean;
  setIsListActive: AppEnv.SetState<boolean>;
}

const Listing = ({isListActive, setIsListActive}: Listing) => {
  const {instance} = useDispatchContext();
  const {dialog} = useChatContext();
  const {message} = useWhatsAppMessageContext();

  const {postSendMessage} = useRequest();
  const {t} = useTranslation();

  const handleSubmit = async (values: Values) => {
    if (!values.button) return true;

    const chatId = getDialogId(dialog, true);
    if (!chatId) return true;

    const res = await postSendMessage(instance.chat_key, {
      body: values.button,
      chatId,
      quotedMsgId: message.id
    });

    if (!res) return true;
    setIsListActive(false);

    return true;
  };

  const initialValues: Values = {button: ''};

  return (
    <Popup
      width={408}
      isActive={isListActive}
      onClick={() => setIsListActive(false)}
    >
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({values}) => (
          <Form>
            <PopupWrapper>
              <Title.H3 style={{marginBottom: 16}}>
                {message._data.list?.buttonText}
              </Title.H3>

              <SectionList isListActive={isListActive} />
            </PopupWrapper>

            <PopupFooter>
              <Wrapper gap={12}>
                <SaveButton state={values.button ? undefined : 'disabled'}>
                  {t`Send`}
                </SaveButton>

                <Button color="fade" onClick={() => setIsListActive(false)}>
                  {t`Close`}
                </Button>
              </Wrapper>
            </PopupFooter>
          </Form>
        )}
      </Formik>
    </Popup>
  );
};

export default Listing;

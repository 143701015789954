import React from 'react';

import {Body, Page, PageBody, PageMain, Placeholder} from 'components';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

interface Firewall {
  children: React.ReactNode;
  keyof: keyof AppEnv.RoleAccesses;
}

const Firewall = ({children, keyof}: Firewall) => {
  const {user} = useWorkspaceContext();

  const {integration, manager} = user;
  const {roles} = manager;

  const isIntegrators =
    keyof != 'integrations_read' || integration.is_integration > 0;

  const isPartners = keyof != 'partners_read' || integration.is_partner > 0;

  const role = roles[keyof] ?? true;
  const isAccess = isIntegrators && isPartners && role;

  return (
    <>
      {isAccess ? (
        children
      ) : (
        <Body>
          <Page>
            <PageBody>
              <PageMain>
                <Placeholder isMaxHeight title="403 Forbidden" />
              </PageMain>
            </PageBody>
          </Page>
        </Body>
      )}
    </>
  );
};

export default Firewall;

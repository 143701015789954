import React from 'react';
import {Wrapper} from 'components';

import GuestForm from './guest-form/guest-form';
import Instance from './instance/instance';
import Privacy from './privacy/privacy';
import Title from './title/title';
import Website from './website/website';
import WorkSchedule from './work-schedule/work-schedule';

const General = () => (
  <Wrapper direction="column" gap={16}>
    <Title />
    <Instance />
    <Website />
    <Privacy />
    <GuestForm />
    <WorkSchedule />
  </Wrapper>
);

export default General;

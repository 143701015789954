import React, {forwardRef, useEffect} from 'react';
import ReactTextareaAutosize from 'react-textarea-autosize';
import {useField, useFormikContext} from 'formik';
import {useTranslation} from 'react-i18next';
import {isMobile} from 'react-device-detect';

import {useChatContext} from '../../../chat-context';
import {useChatFooterContext} from '../../chat-footer-context';
// import {useDispatchContext} from 'pages/workspace/pages/messenger/dispatch/dispatch-context';
import {useHandleEnterDown} from '../hooks';
// import {useRequest} from 'common/hooks';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import useHandleFiles from '../use-handle-files';

const Body = forwardRef<HTMLTextAreaElement>((props, ref) => {
  const {user} = useWorkspaceContext();
  // const {instance} = useDispatchContext();
  const {dialog, quotedMsg} = useChatContext();

  const {
    isAttachsFormActive,
    isButtonsFormActive,
    setTemplateState,
    templateState
  } = useChatFooterContext();

  const {isSubmitting} = useFormikContext();

  const [field, , helpers] = useField<string>('body');
  // const {fetchTyping} = useRequest();
  const {t} = useTranslation();
  const handleEnterDown = useHandleEnterDown();
  const handleFiles = useHandleFiles();

  const focusText = () => {
    if (isMobile || !ref || !('current' in ref) || !ref.current) return;

    ref.current.focus();
    ref.current.selectionStart = ref.current.value.length;
  };

  useEffect(() => {
    if (isAttachsFormActive) return;
    else if (isButtonsFormActive) return;
    else if (templateState.isActive) return;

    focusText();
  }, [isAttachsFormActive, isButtonsFormActive, templateState.isActive]);

  useEffect(() => {
    if (isButtonsFormActive) return;
    else if (isSubmitting) return;

    focusText();
  }, [isSubmitting]);

  useEffect(() => {
    focusText();
  }, [quotedMsg]);

  useEffect(() => {
    if (dialog.version == 'telegram' && dialog.draftMessage)
      helpers.setValue(dialog.draftMessage.inputMessageText.text.text);
  }, []);

  const handleKeyDown: React.KeyboardEventHandler<HTMLTextAreaElement> = e => {
    // fetchTyping(instance.chat_key);
    handleEnterDown(e);

    if (e.key != '/') return;
    else if (field.value) return;

    e.preventDefault();
    setTemplateState({isActive: true, isFocus: true});
  };

  const handlePaste: React.ClipboardEventHandler<HTMLTextAreaElement> = e => {
    const files = Object.values(e.clipboardData.files);
    if (!files.length) return;

    handleFiles(files);
    e.preventDefault();
  };

  const {roles} = user.manager;

  const placeholder =
    roles.messages_text != false ? `${t`Message`}...` : undefined;

  return (
    <ReactTextareaAutosize
      className="chat-footer__input"
      disabled={roles.messages_text == false}
      onChange={e => helpers.setValue(e.target.value)}
      onKeyDown={handleKeyDown}
      onPaste={handlePaste}
      placeholder={placeholder}
      ref={ref}
      value={isAttachsFormActive || isButtonsFormActive ? '' : field.value}
    />
  );
});

Body.displayName = 'Body';
export default Body;

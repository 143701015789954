import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Fuse from 'fuse.js';
import upd from 'immutability-helper';

import {
  Body,
  Button,
  Grid,
  Icons,
  InputAlt,
  Nav,
  Page,
  PageBody,
  PageMain,
  Placeholder
} from 'components';
import {useAppContext} from 'app-context';
import {useRequest} from 'common/hooks';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

import {TemplateForm, TemplateFormValues} from './components';
import Guide from './guide/guide';
import Template from './template/template';

const Templates = () => {
  const {setNotification} = useAppContext();
  const {setUser, user} = useWorkspaceContext();

  const [templateList, setTemplateList] = useState(user.templates);
  const [search, setSearch] = useState('');
  const [isEditActive, setIsEditActive] = useState(false);

  const {postFilesLoadData, postTemplatesCreate, postTemplatesFileAdd} =
    useRequest();

  const {t} = useTranslation();

  useEffect(() => {
    const fuse = new Fuse(user.templates, {
      keys: ['message', 'title'],
      threshold: 0.25
    });

    if (!search) return setTemplateList(user.templates);

    const newTemplateList = fuse.search(search).map(item => item.item);
    setTemplateList(newTemplateList);
  }, [search, user.templates]);

  useEffect(() => {
    document.title = `${t`Templates`} - Whatcrm Web`;
  }, []);

  const handleSubmit = async (values: TemplateFormValues) => {
    const {integration} = user;

    const template = await postTemplatesCreate(integration.id, values);
    if (!template) return true;

    const files: AppEnv.TemplateFile[] = [];

    const promises = values.attachs
      .filter(attach => !attach.isDelete)
      ?.map(async attach => {
        const {file} = attach;
        if (!file) return;

        const link = await postFilesLoadData(file, file.name);
        if (!link) return;

        const {integration} = user;

        const addedFile = await postTemplatesFileAdd(
          integration.id,
          template.id,
          {link, title: file.name, type: file.type}
        );

        if (!addedFile) return;
        files.push(addedFile);
      });

    await Promise.all(promises);
    setIsEditActive(false);

    setUser(prevValue =>
      upd(prevValue, {
        templates: {$push: [{...template, file_count: files.length, files}]}
      })
    );

    setNotification({text: t`Template created.`, title: template.title});

    return true;
  };

  const {roles} = user.manager;

  return (
    <>
      <Body>
        <Page>
          <Nav
            options={[{label: t`Templates`, value: 'templates'}]}
            value="templates"
          />

          <PageBody>
            <PageMain>
              <Guide />

              {roles.templates_write != false && (
                <Button
                  color="blue-dash"
                  onClick={() => setIsEditActive(true)}
                  style={{marginBottom: 16}}
                  width="100%"
                >
                  {t`Create template`}
                  <Icons.Plus color="blue" size={20} />
                </Button>
              )}

              <InputAlt
                autoCapitalize="off"
                maxWidth="100%"
                name="search"
                onChange={setSearch}
                placeholder={t`Search`}
                style={{marginBottom: 16}}
                type="search"
                value={search}
              />

              {templateList.length ? (
                <Grid>
                  {templateList.map(template => (
                    <Template key={template.id} template={template} />
                  ))}
                </Grid>
              ) : (
                <Placeholder isMaxHeight title={t`No templates`} />
              )}
            </PageMain>
          </PageBody>
        </Page>
      </Body>

      <TemplateForm
        isActive={isEditActive}
        onSubmit={handleSubmit}
        setIsActive={setIsEditActive}
      />
    </>
  );
};

export default Templates;

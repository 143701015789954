import React from 'react';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {InputMulti} from 'components';
import * as AppEnv from 'app-env';

const Phones = () => {
  const [field, , helpers] = useField<string | undefined>('phones');
  const {t} = useTranslation();

  const handleChange = (value: AppEnv.Button[]) => {
    const newValue = JSON.stringify(value.map(item => item.body));
    helpers.setValue(newValue);
  };

  const value = JSON.parse(field.value || '[]').map((phone: string) => ({
    body: phone
  }));

  return (
    <InputMulti
      {...field}
      onChange={handleChange}
      placeholder={t`Type a phone number`}
      style={{marginBottom: 16}}
      title={t`Phone numbers`}
      type="tel"
      value={value.length ? value : [{body: ''}]}
    />
  );
};

export default Phones;

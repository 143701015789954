import React from 'react';
import {useTranslation} from 'react-i18next';
import upd from 'immutability-helper';

import {Input} from 'components';
import {useChatContext} from '../../../../chat-context';

const CallbackHeader = () => {
  const {setWidget, widget} = useChatContext();
  const {t} = useTranslation();

  const handleChange = (value: string) =>
    setWidget(prevValue => upd(prevValue, {callback_header: {$set: value}}));

  return (
    <Input
      name="callback_header"
      onChange={handleChange}
      placeholder={t`Header`}
      value={widget.callback_header}
    />
  );
};

export default CallbackHeader;

import React from 'react';
import {useTranslation} from 'react-i18next';
import upd from 'immutability-helper';

import {Switch} from 'components';
import {useChatContext} from '../../../chat-context';

const IsChat = () => {
  const {setWidget, widget} = useChatContext();
  const {t} = useTranslation();

  const handleChange = (value: boolean) =>
    setWidget(prevValue => upd(prevValue, {is_chat: {$set: value}}));

  return (
    <Switch
      label={t`“Chat with manager” button`}
      onChange={handleChange}
      value={widget.is_chat}
    />
  );
};

export default IsChat;

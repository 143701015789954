import React, {useState} from 'react';

import {Page} from 'components';
import {useScript} from './hooks';

const Window = () => {
  const [input, setInput] = useState('');
  const [url, setUrl] = useState('');

  const script = useScript();

  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = e => {
    if (e.key != 'Enter') return;
    e.preventDefault();

    if (!input) return setUrl('');
    else if (input.startsWith('http://') || input.startsWith('https://'))
      return setUrl(input);

    const normalized = `https://${input}`;

    setInput(normalized);
    setUrl(normalized);
  };

  return (
    <Page>
      <div className="widget__window">
        <div className="widget__controls">
          <div className="widget__controls-item" />
          <div className="widget__controls-item" />
          <div className="widget__controls-item" />
        </div>

        <div className="widget__browser">
          <div className="widget__bar">
            <input
              className="widget__address-bar"
              onChange={e => setInput(e.target.value)}
              onKeyDown={handleKeyDown}
              placeholder="https://"
              value={input}
            />
          </div>

          <div className="widget__page">
            {url && (
              <iframe className="widget__iframe widget__iframe_bg" src={url} />
            )}

            <iframe
              className="widget__iframe"
              srcDoc={`<div></div>${script}`}
            />
          </div>
        </div>
      </div>
    </Page>
  );
};

export default Window;

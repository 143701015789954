import {Values} from '../../../chat-footer-env';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import {useChatContext} from '../../../../chat-context';

const useGetBody = () => {
  const {user} = useWorkspaceContext();
  const {dialog} = useChatContext();

  const getBody = (values: Values) => {
    const {integration} = user;
    let {body} = values;

    if (!integration.is_manager) return body;
    else if (!body) return body;

    const bold = dialog.version == 'whatcrm' ? '*' : '';
    body = body.replace(/^\*.*:\*\n/, '');
    body = `${bold}${user.manager.fullname}:${bold}\n` + body;

    return body;
  };

  return getBody;
};

export default useGetBody;

import React from 'react';
import {useTranslation} from 'react-i18next';
import upd from 'immutability-helper';

import {ChipsAlt, ChipsAltOption, Title} from 'components';
import {useChatContext} from '../../../chat-context';
import * as AppEnv from 'app-env';

const Type = () => {
  const {setWidget, widget} = useChatContext();
  const {t} = useTranslation();

  const handleChange = (value: AppEnv.ChatWidgetType) =>
    setWidget(prevValue => upd(prevValue, {type: {$set: value}}));

  const options: ChipsAltOption<AppEnv.ChatWidgetType>[] = [
    {img: '/static/widget/type/radial.svg', value: 'radial'},
    {img: '/static/widget/type/vertical.svg', value: 'vertical'},
    {img: '/static/widget/type/horizontal.svg', value: 'horizontal'}
  ];

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Button type`}</Title.H3>
      <ChipsAlt onChange={handleChange} options={options} value={widget.type} />
    </div>
  );
};

export default Type;

import React, {useContext} from 'react';
import * as AppEnv from 'app-env';

interface ChatContext {
  chat: AppEnv.Chat;
  isCodeActive: boolean;
  setIsCodeActive: AppEnv.SetState<boolean>;
  setWidget: AppEnv.SetState<AppEnv.ChatWidget | undefined>;
  submitForm: (values: AppEnv.ChatPut) => Promise<AppEnv.Chat | null>;
  widget: AppEnv.ChatWidget;
}

const ChatContext = React.createContext<ChatContext>({} as ChatContext);

export const useChatContext = () => useContext(ChatContext);
export default ChatContext;

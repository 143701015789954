import React from 'react';
import {Form, Formik} from 'formik';
import {useTranslation} from 'react-i18next';

import {
  Button,
  Popup,
  PopupFooter,
  PopupWrapper,
  SaveButton,
  Title,
  Wrapper
} from 'components';
import {useChatContext} from '../../../chat-context';
import {useUpdateContact} from '../../hooks';
import * as AppEnv from 'app-env';

import Email from './email/email';
import Fullname from './fullname/fullname';
import Phones from './phones/phones';
import Username from './username/username';
import Website from './website/website';

interface ContactForm {
  isActive: boolean;
  setIsActive: AppEnv.SetState<boolean>;
}

const ContactForm = ({isActive, setIsActive}: ContactForm) => {
  const {contact} = useChatContext();

  const {t} = useTranslation();
  const updateContact = useUpdateContact();

  const handleSubmit = async (values: Partial<AppEnv.ContactShape>) => {
    const res = await updateContact(values);
    if (!res) return true;

    setIsActive(false);
    return true;
  };

  return (
    <Popup isActive={isActive} onClick={() => setIsActive(false)} width={408}>
      <Formik initialValues={contact || {}} onSubmit={handleSubmit}>
        <Form>
          <PopupWrapper>
            <Title.H3 style={{marginBottom: 16}}>
              {t`Edit the contact`}
            </Title.H3>

            <Fullname />
            <Phones />
            <Username />
            <Email />
            <Website />
          </PopupWrapper>

          <PopupFooter>
            <Wrapper gap={8}>
              <SaveButton
                state={contact === undefined ? 'disabled' : undefined}
              >
                {t`Save`}
              </SaveButton>

              <Button color="fade" onClick={() => setIsActive(false)}>
                {t`Close`}
              </Button>
            </Wrapper>
          </PopupFooter>
        </Form>
      </Formik>
    </Popup>
  );
};

export default ContactForm;

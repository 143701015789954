import * as AppEnv from 'app-env';

import getTariff from './get-tariff/get-tariff';

const bytes = {
  mb5: 5242880,
  mb16: 16777216,
  mb20: 20971520,
  mb32: 33554432,
  mb50: 52428800,
  mb64: 67108864,
  mb100: 104857600,
  mb128: 134217728,
  mb200: 209715200
};

const getSizeLimit = (instance: AppEnv.Instance, file: File) => {
  const tariff = getTariff(instance);

  if (file.type.includes('image')) {
    switch (tariff) {
      case 'Start':
        return instance.version == 'chat' ? bytes.mb5 : bytes.mb16;
      case 'Pro':
        return instance.version == 'chat' ? bytes.mb16 : bytes.mb32;
      case 'Enterprise':
        return bytes.mb64;
    }
  } else if (file.type.includes('video')) {
    switch (tariff) {
      case 'Start':
        return instance.version == 'chat' ? bytes.mb20 : bytes.mb16;
      case 'Pro':
        return bytes.mb64;
      case 'Enterprise':
        return bytes.mb128;
    }
  }

  switch (tariff) {
    case 'Start':
      return bytes.mb50;
    case 'Pro':
      return bytes.mb100;
    case 'Enterprise':
      return bytes.mb200;
  }
};

export default getSizeLimit;

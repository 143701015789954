import React from 'react';

interface Bullet {
  background: string;
}

const Bullet = ({background}: Bullet) => (
  <div className="bullet" style={{background}} />
);

export default Bullet;

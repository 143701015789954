import React from 'react';
import {Form, Formik} from 'formik';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';
import jwtEncode from 'jwt-encode';

import {
  Button,
  Popup,
  PopupFooter,
  PopupWrapper,
  SaveButton,
  Title as CoreTitle,
  Wrapper
} from 'components';
import {useAppContext} from 'app-context';
import {useRequest} from 'common/hooks';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

import {getInitialWidget} from './actions';
import InstanceId from './instance-id/instance-id';
import Title from './title/title';

interface Values {
  instance_id: number | undefined;
  title: string;
}

interface WidgetForm {
  isActive: boolean;
  setIsActive: AppEnv.SetState<boolean>;
}

const initialValues: Values = {instance_id: undefined, title: ''};

const validationSchema = yup
  .object()
  .shape({title: yup.string().trim().required()});

const WidgetForm = ({isActive, setIsActive}: WidgetForm) => {
  const {setNotification} = useAppContext();
  const {activeInstances, user} = useWorkspaceContext();

  const {postChatsCreate} = useRequest();
  const {t} = useTranslation();
  const navigate = useNavigate();

  const getUid = () => {
    let uid = '';

    for (let i = 0; i < 16; i++) {
      uid += Math.floor(Math.random() * 10);
    }

    return uid;
  };

  const handleError = () => {
    setNotification({text: t`Something went wrong.`, title: t`Error`});
    return true;
  };

  const handleSubmit = async (values: Values) => {
    const {integration} = user;

    const instance = activeInstances.find(
      instance => instance.id == values.instance_id
    );

    const initialWidget = getInitialWidget(instance);
    const token = jwtEncode(initialWidget, '');
    const uid = getUid();

    const res = await postChatsCreate(integration.id, {
      ...values,
      instance_id: values.instance_id || 0,
      token,
      uid
    });

    if (!res?.id) return handleError();
    navigate(`/widgets/${res.uid}`);

    return true;
  };

  return (
    <Popup isActive={isActive} onClick={() => setIsActive(false)} width={408}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validationSchema={validationSchema}
      >
        <Form>
          <PopupWrapper>
            <CoreTitle.H3 style={{marginBottom: 16}}>
              {t`New widget`}
            </CoreTitle.H3>

            <Title />
            <InstanceId />
          </PopupWrapper>

          <PopupFooter>
            <Wrapper gap={12}>
              <SaveButton>{t`Continue`}</SaveButton>

              <Button color="fade" onClick={() => setIsActive(false)}>
                {t`Close`}
              </Button>
            </Wrapper>
          </PopupFooter>
        </Form>
      </Formik>
    </Popup>
  );
};

export default WidgetForm;

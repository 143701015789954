import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import cn from 'classnames';

import {Button, Confirm, Icons} from 'components';

interface Seq {
  children: React.ReactNode;
  confirm?: string;
  href?: string;
  onDelete?: () => Promise<boolean>;
}

const Seq = ({children, confirm, href, onDelete}: Seq) => {
  const [isActive, setIsActive] = useState(false);
  const {t} = useTranslation();

  const handleClick = (res: boolean) => {
    if (!onDelete) return;
    else if (res) return onDelete();

    setIsActive(false);
  };

  const isButtonGroup = !!(href || onDelete);

  return (
    <div className={cn('seq', {seq_active: isActive})}>
      <div>{children}</div>

      {isButtonGroup && (
        <div className="seq__button-group">
          {href && (
            <Link to={href}>
              <Button color="transparent">
                <Icons.PencilSimple size={20} />
              </Button>
            </Link>
          )}

          {onDelete && (
            <Button color="transparent" onClick={() => setIsActive(true)}>
              <Icons.TrashSimple size={20} />
            </Button>
          )}
        </div>
      )}

      <Confirm
        isActive={isActive}
        onClick={handleClick}
        title={confirm || t`Delete?`}
      />
    </div>
  );
};

export default Seq;

import React from 'react';
import {Text} from 'components';

export interface InputAutocompleteOption {
  icon?: React.ReactNode;
  label: string;
  value: number;
}

interface Option {
  onChange: (value: string) => void;
  onClick: (value: number) => void;
  option: InputAutocompleteOption;
}

const Option = ({onChange, onClick, option}: Option) => {
  const handleClick = () => {
    onChange(option.label);
    onClick(option.value);
  };

  return (
    <button
      className="input-autocomplete__option"
      onClick={handleClick}
      type="button"
    >
      {option.icon}

      <Text isNoWrap isSpan size={16}>
        {option.label}
      </Text>
    </button>
  );
};

export default Option;

import {getIsLimited, getDialogId} from 'common/actions';
import {useDispatchContext} from '../../../../dispatch-context';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

const useContact = (dialog: AppEnv.Dialog) => {
  const {contactList} = useWorkspaceContext();
  const {instance} = useDispatchContext();

  const isLimited = getIsLimited(instance);

  if (!contactList) return undefined;
  else if (isLimited) return null;

  const contact =
    contactList.find(
      contact => contact.chatId == getDialogId(dialog, true)?.toString()
    ) || null;

  return contact;
};

export default useContact;

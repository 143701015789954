import React from 'react';

import {Bullet, Icons, SelectMulti} from 'components';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

interface Labels {
  labels: number[];
  setLabels: AppEnv.SetState<number[]>;
}

const Labels = ({labels, setLabels}: Labels) => {
  const {labelList} = useWorkspaceContext();

  const options =
    labelList?.map(label => ({
      icon: <Bullet background={label.color} />,
      label: label.tag,
      value: label.id
    })) || [];

  const icon = (
    <Icons.TagSimple
      color={labels.length > 0 ? 'green' : undefined}
      size={20}
    />
  );

  return (
    <>
      {options.length > 0 && (
        <SelectMulti
          icon={icon}
          isAlt
          onChange={setLabels}
          options={options}
          value={labels}
        />
      )}
    </>
  );
};

export default Labels;

import React from 'react';
import {useScrollClassName} from 'whatcrm-core';

interface Table {
  children: React.ReactNode;
}

const Table = ({children}: Table) => {
  const scrollClassName = useScrollClassName();

  return (
    <div className={scrollClassName}>
      <table className="table">{children}</table>
    </div>
  );
};

export {default as TableBody} from './table-body/table-body';
export {default as TableData} from './table-data/table-data';
export {default as TableHead} from './table-head/table-head';
export {default as TableRow} from './table-row/table-row';

export default Table;

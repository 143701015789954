import React from 'react';
import {useTranslation} from 'react-i18next';

import {ContextMenuOption, Icons} from 'components';
import {getIsLimited} from 'common/actions';
import {useDispatchContext} from 'pages/workspace/pages/messenger/dispatch/dispatch-context';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

const useLabel = (
  setIsLabelFormActive: AppEnv.SetState<boolean>
): ContextMenuOption | null => {
  const {user} = useWorkspaceContext();
  const {instance} = useDispatchContext();

  const {t} = useTranslation();

  const {integration} = user;

  if (
    ![
      'mfdanilovv@yandex.ru',
      'whatcrm.bitrix24.by',
      'whatcrmproject.amocrm.ru'
    ].includes(integration.domain) &&
    (process.env.REACT_APP_ENV == 'prod' ||
      process.env.REACT_APP_ENV == 'rocket')
  )
    return null;

  const {roles} = user.manager;
  const isLimited = getIsLimited(instance);

  if (isLimited) return null;
  else if (roles.dialogs_labels == false) return null;

  const label: ContextMenuOption = {
    icon: <Icons.TagSimple size={20} />,
    label: t`Add tag`,
    onClick: () => setIsLabelFormActive(true)
  };

  return label;
};

export default useLabel;

import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {Select} from 'components';
import {useInstanceOptions} from 'common/hooks';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';

const InstanceId = () => {
  const {activeInstances} = useWorkspaceContext();
  const {instanceId} = useParams();

  const [field, meta, helpers] = useField<number | undefined>('instanceId');
  const {t} = useTranslation();

  const instances = activeInstances.filter(
    item => item.version == 'telegram' || item.version == 'whatcrm'
  );

  const options = useInstanceOptions(instances);

  useEffect(() => {
    if (!instanceId) return;
    helpers.setValue(parseInt(instanceId));
  }, []);

  const isValid = !(meta.error && meta.touched);

  return (
    <Select
      {...field}
      isValid={isValid}
      onChange={helpers.setValue}
      options={options}
      placeholder={t`Connection`}
    />
  );
};

export default InstanceId;

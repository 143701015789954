import React from 'react';
import {useTranslation} from 'react-i18next';
import upd from 'immutability-helper';

import {Input} from 'components';
import {useChatContext} from '../../../../chat-context';

const CallbackButtonText = () => {
  const {setWidget, widget} = useChatContext();
  const {t} = useTranslation();

  const handleChange = (value: string) =>
    setWidget(prevValue =>
      upd(prevValue, {callback_button_text: {$set: value}})
    );

  return (
    <Input
      name="callback_button_text"
      onChange={handleChange}
      placeholder={t`Button text`}
      value={widget.callback_button_text}
    />
  );
};

export default CallbackButtonText;

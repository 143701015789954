import React from 'react';
import {Formik, Form} from 'formik';
import {useOnKeyDown} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {
  Button,
  Popup,
  PopupWrapper,
  SaveButton,
  Text,
  Wrapper
} from 'components';

export interface Confirm {
  isActive: boolean;
  onClick: (arg: boolean) => void | Promise<boolean>;
  text?: string;
  title: string;
}

const Confirm = ({isActive, onClick, text, title}: Confirm) => {
  const {t} = useTranslation();

  useOnKeyDown(
    e => {
      if (e.key == 'Enter') onClick(true);
      else if (e.key == 'Escape') onClick(false);
    },
    [isActive]
  );

  return (
    <Popup isActive={isActive} isConfirm onClick={() => onClick(false)}>
      <Formik
        initialValues={{}}
        validateOnBlur={false}
        onSubmit={() => onClick(true)}
      >
        <Form>
          <PopupWrapper>
            <Wrapper direction="column" gap={8} style={{marginBottom: 16}}>
              <Text fontWeight={500} size={16} textAlign="center">
                {title}
              </Text>

              {text && (
                <Text color="secondary" textAlign="center">
                  {text}
                </Text>
              )}
            </Wrapper>

            <div className="confirm">
              <SaveButton>{t`Yes`}</SaveButton>

              <Button color="fade" onClick={() => onClick(false)}>
                {t`No`}
              </Button>
            </div>
          </PopupWrapper>
        </Form>
      </Formik>
    </Popup>
  );
};

export default Confirm;

import {getDialogId} from 'common/actions';
import {useChatContext} from '../../../../chat-context';
import {useDispatchContext} from 'pages/workspace/pages/messenger/dispatch/dispatch-context';
import {useRequest} from 'common/hooks';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import {Values} from '../../../chat-footer-env';

const useEditMsg = () => {
  const {updateMessageBody} = useWorkspaceContext();
  const {instance} = useDispatchContext();
  const {dialog, quotedMsg} = useChatContext();

  const {postEditMessage} = useRequest();

  const editMsg = async (values: Values) => {
    if (!quotedMsg) return false;

    const chatId = getDialogId(dialog, true);
    if (!chatId) return false;

    const res = await postEditMessage(
      instance.chat_key,
      chatId,
      quotedMsg.message.id,
      values.body
    );

    // deprecated
    if (dialog.version == 'telegram')
      updateMessageBody(quotedMsg.message.id, values.body, +new Date() / 1000);

    return res ? ('edit' in res ? res.edit : true) : false;
  };

  return editMsg;
};

export default useEditMsg;

import React from 'react';
import {useTranslation} from 'react-i18next';

import {ContextMenuOption, IconsAlt} from 'components';
import * as AppEnv from 'app-env';

const useManager = (
  dialog: AppEnv.Dialog,
  setIsManagerAssignActive: AppEnv.SetState<boolean>
): ContextMenuOption | null => {
  const {t} = useTranslation();

  if (dialog.version != 'chat') return null;
  else if (dialog.type != 'guest_chat') return null;

  const manager: ContextMenuOption = {
    icon: <IconsAlt.Manager />,
    label: t`Assign responsible`,
    onClick: () => setIsManagerAssignActive(true)
  };

  return manager;
};

export default useManager;

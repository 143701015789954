import React from 'react';
import {Icons, Text, Wrapper} from 'components';

interface SelectField {
  icon: React.ReactNode | undefined;
  label: string | undefined;
  onClick: () => void;
  placeholder: string;
}

const SelectField = ({icon, label, onClick, placeholder}: SelectField) => (
  <button className="select-field" onClick={onClick} type="button">
    <div style={{minWidth: 0}}>
      <Text
        className="select-field__placeholder"
        fontWeight={500}
        isNoWrap
        size={label == undefined ? 16 : 12}
      >
        {placeholder}
      </Text>

      {label != undefined && (
        <Wrapper alignItems="center" flexWrap="nowrap" gap={4}>
          {icon}

          <Text fontWeight={500} isNoWrap size={16}>
            {label}
          </Text>
        </Wrapper>
      )}
    </div>

    <Icons.MinArrowDown color="blue" />
  </button>
);

export default SelectField;

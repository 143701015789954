import React, {useRef, useState} from 'react';
import {
  findPosition,
  useOnClickOutside,
  useOnKeyDown,
  useScrollClassName
} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';
import cn from 'classnames';

import {Icons, Text, Wrapper} from 'components';
import {SelectOption} from '../../select-env';
import * as AppEnv from 'app-env';

interface Position {
  left: number;
  top: number;
}

interface Select<T> {
  icon?: React.ReactNode;
  isActive: boolean;
  isAlt?: boolean;
  isValid?: boolean;
  label: string | undefined;
  maxWidth?: 160;
  onClick: (value: T) => void;
  optgroup?: string;
  options: SelectOption<T>[] | undefined;
  placeholder?: string;
  setIsActive: AppEnv.SetState<boolean>;
  style?: React.CSSProperties;
  value: T | T[];
}

const Select = <T,>({
  icon,
  isActive,
  isAlt,
  isValid = true,
  label,
  maxWidth,
  onClick,
  optgroup,
  options,
  placeholder,
  setIsActive,
  style,
  value
}: Select<T>) => {
  const [position, setPosition] = useState<Position>();

  const {t} = useTranslation();
  const scrollClassName = useScrollClassName();
  const targetRef = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(targetRef, {onClick: () => setIsActive(false)});

  useOnKeyDown(
    e => {
      if (e.key == 'Escape') setIsActive(false);
    },
    [isActive]
  );

  const handleActive = () => {
    const res = findPosition(targetRef, {triggerRef});
    const triggerRect = triggerRef.current?.getBoundingClientRect();
    const height = triggerRect?.height || 0;

    const left = isAlt && !icon ? res.left - 16 : res.left;
    const top = res.top + height + (isAlt ? 0 : 8);

    setPosition({left, top});
    setIsActive(true);
  };

  const isPlaceholder = placeholder && !(isAlt && label);
  const triggerRect = triggerRef.current?.getBoundingClientRect();

  return (
    <div
      className={cn('select', {
        select_active: isActive,
        select_alt: isAlt,
        select_disabled: !options,
        select_multiple: Array.isArray(value)
      })}
      style={{...style, maxWidth}}
    >
      <div
        className={cn('select__field', {
          select__field_error: !isValid,
          select__field_icon: icon
        })}
        onClick={handleActive}
        ref={triggerRef}
      >
        {!icon && (
          <div style={{overflow: 'hidden'}}>
            {isPlaceholder && (
              <Text
                className="select__placeholder"
                fontWeight={isAlt ? undefined : 500}
                isNoWrap
                size={label ? 12 : 16}
              >
                {placeholder}
              </Text>
            )}

            {label && (
              <Text fontWeight={isAlt ? undefined : 500} isNoWrap size={16}>
                {label}
              </Text>
            )}
          </div>
        )}

        {icon || <Icons.MinArrowDown color="blue" />}
      </div>

      <div className="select__dropdown">
        <div
          className={cn('select__dropdown-wrapper', {
            [scrollClassName]: scrollClassName
          })}
          ref={targetRef}
          style={{
            ...position,
            minWidth: (triggerRect?.width || 0) + (isAlt ? 32 : 6)
          }}
        >
          {options?.length ? (
            <>
              {optgroup && (
                <Text
                  className="select__optgroup"
                  color="optional"
                  size={14}
                  style={{marginBottom: 8}}
                >
                  {optgroup}
                </Text>
              )}

              {options.map((item, i) => (
                <div
                  className={cn('select__option', {
                    select__option_active: Array.isArray(value)
                      ? value.includes(item.value)
                      : value == item.value,
                    select__option_plus: item.plus
                  })}
                  key={i}
                  onClick={() => onClick(item.value)}
                >
                  <div className="select__group">
                    {item.icon}

                    <div>
                      <Wrapper flexWrap="nowrap">
                        <Text isNoWrap size={16}>
                          {item.label}
                        </Text>

                        {item.plus}
                      </Wrapper>

                      {item.comment && <Text size={12}>{item.comment}</Text>}
                    </div>
                  </div>

                  <Icons.Check size={20} />
                </div>
              ))}
            </>
          ) : (
            <Text color="light" textAlign="center" size={14}>
              {t`No options`}
            </Text>
          )}
        </div>
      </div>
    </div>
  );
};

export default Select;

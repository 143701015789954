import {useTranslation} from 'react-i18next';

import {ProfileListItemParams} from '../../../../components';
import {useChatContext} from '../../../../../../chat-context';

const useBulletin = (): ProfileListItemParams | null => {
  const {dialog} = useChatContext();
  const {t} = useTranslation();

  if (dialog.version != 'avito') return null;

  const {value} = dialog.context;
  if (!value.url) return null;

  const bulletin: ProfileListItemParams = {
    href: value.url,
    label: t`bulletin`,
    type: 'copy',
    value: `${value.title} • ${value.price_string}`
  };

  return bulletin;
};

export default useBulletin;

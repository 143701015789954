import React from 'react';
import {useTranslation} from 'react-i18next';

import {Placeholder, Preloader, Table, TableBody} from 'components';
import {useStaffContext} from '../staff-context';

import Role from './role/role';
import RoleCreate from './role-create/role-create';

const Roles = () => {
  const {roleList} = useStaffContext();
  const {t} = useTranslation();

  return (
    <>
      <RoleCreate />

      {roleList ? (
        roleList.length > 0 ? (
          <Table>
            <TableBody>
              {roleList.map(role => (
                <Role key={role.id} role={role} />
              ))}
            </TableBody>
          </Table>
        ) : (
          <Placeholder isMaxHeight title={t`No roles`} />
        )
      ) : (
        <Preloader isMaxHeight />
      )}
    </>
  );
};

export default Roles;

import {formatPhoneNumber} from 'whatcrm-core';

import {getChatUser} from 'common/actions';
import {useDispatchContext} from '../../../../dispatch-context';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

const useGetMsgSenderName = () => {
  const {user} = useWorkspaceContext();
  const {instance} = useDispatchContext();

  const {manager} = user;

  const getMessageSenderName = (
    dialog: AppEnv.Dialog,
    message: AppEnv.Message
  ) => {
    if (dialog.version == 'avito' && 'author_id' in message) {
      const user = dialog.users.find(user => user.id == message.author_id);
      return user?.name;
    } else if (dialog.version == 'chat' && 'sender_id' in message) {
      const user = getChatUser(instance, dialog);
      if (message.sender_id == user?.id) return user.name || user.email;

      return manager.fullname || manager.email;
    } else if ('senderName' in message && message.senderName) {
      return message.senderName;
    } else if ('_data' in message) {
      const {_data} = message;
      const {author} = message._data;

      if (_data.notifyName) return _data.notifyName;
      else if (author?.user) return formatPhoneNumber(author.user);
    }
  };

  return getMessageSenderName;
};

export default useGetMsgSenderName;

import React from 'react';
import {IconParams, Icons, Text} from 'components';

export interface ContentParams {
  href?: string;
  label?: string;
  type?: 'block' | 'copy';
  value: string;
}

const Content = ({href, label, type, value}: ContentParams) => {
  const params: IconParams = {className: 'profile__item-icon', size: 24};

  return (
    <div className="profile__item-content">
      <div>
        {label && <Text size={12}>{label}</Text>}

        <Text size={16} fontWeight={type != 'block' ? 600 : undefined}>
          {value}
        </Text>
      </div>

      {href ? (
        <Icons.ArrowSquareOut {...params} />
      ) : (
        type == 'copy' && <Icons.Files {...params} />
      )}
    </div>
  );
};

export default Content;

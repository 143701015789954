import React from 'react';
import cn from 'classnames';

interface Anchor {
  children: React.ReactNode;
  color?: 'gold';
  direction?: 'rtl';
  fontWeight?: 500;
  isDisabled?: boolean;
  onClick?: () => void;
  size?: 14 | 16;
  style?: React.CSSProperties;
  textAlign?: 'center';
}

const Anchor = ({
  children,
  color,
  direction,
  fontWeight,
  isDisabled,
  onClick,
  size,
  style,
  textAlign
}: Anchor) => (
  <button
    className={cn('anchor', {
      [`anchor_${color}`]: color,
      [`anchor_${size}`]: size
    })}
    disabled={isDisabled}
    onClick={() => (onClick ? onClick() : undefined)}
    style={{...style, direction, fontWeight, textAlign}}
    type="button"
  >
    {children}
  </button>
);

export default Anchor;

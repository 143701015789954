import {useField} from 'formik';
import * as AppEnv from 'app-env';

const useAccesses = () => {
  const [field] = useField<string>('accesses');
  const accesses: AppEnv.RoleAccesses = JSON.parse(field.value);

  return accesses;
};

export default useAccesses;

import React, {useEffect, useState} from 'react';
import {Form, Formik} from 'formik';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';

import {
  Confirm,
  PageBody,
  PageFooter,
  PageMain,
  Placeholder,
  Preloader,
  SaveButton
} from 'components';
import {useAppContext} from 'app-context';
import {useRequest} from 'common/hooks';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

import Description from './description/description';
import DescriptionShort from './description-short/description-short';
import NewImage from './new-image/new-image';
import Webhook from './webhook/webhook';

interface Settings {
  integrator: AppEnv.Integrator | null | undefined;
  setIntegrator: AppEnv.SetState<AppEnv.Integrator | null | undefined>;
}

interface Values extends AppEnv.Integrator {
  new_image?: File;
}

const validationSchema = yup
  .object()
  .shape({description_short: yup.string().trim().required()});

const Settings = ({integrator, setIntegrator}: Settings) => {
  const {setNotification} = useAppContext();
  const {user} = useWorkspaceContext();

  const [isConfirmActive, setIsConfirmActive] = useState(false);
  const [values, setValues] = useState<Values>();

  const {postFilesLoadData, postIntegratorsUpdate} = useRequest();
  const {t} = useTranslation();

  useEffect(() => {
    document.title = `${t`Integration settings`} - Whatcrm Web`;
  }, []);

  const getImage = async (values: Values) => {
    const {new_image} = values;

    if (!new_image) return values.image;

    const res = await postFilesLoadData(new_image, new_image.name);
    return res || values.image;
  };

  const submit = async () => {
    if (!values) return true;

    const {integration} = user;
    const image = await getImage(values);

    const res = await postIntegratorsUpdate(integration.id, {...values, image});
    if (!res) return true;

    setIntegrator(prevValue => (prevValue ? {...prevValue, res} : prevValue));
    setIsConfirmActive(false);
    setNotification({title: t`Integration has been updated`});
    setValues(undefined);

    return true;
  };

  const handleClick = (res: boolean) => {
    if (res) return submit();
    setIsConfirmActive(false);
  };

  const handleSubmit = async (newValues: Values) => {
    setIsConfirmActive(true);
    setValues(newValues);
    return true;
  };

  const {roles} = user.manager;

  return (
    <>
      {roles.integrations_write != false ? (
        typeof integrator == 'object' ? (
          integrator ? (
            <Formik
              initialValues={integrator}
              onSubmit={handleSubmit}
              validateOnBlur={false}
              validationSchema={validationSchema}
            >
              <Form>
                <PageBody>
                  <PageMain>
                    <NewImage integrator={integrator} />
                    <DescriptionShort />
                    <Description />
                    <Webhook />
                  </PageMain>
                </PageBody>

                <PageFooter>
                  <SaveButton>{t`Save`}</SaveButton>
                </PageFooter>
              </Form>
            </Formik>
          ) : (
            <PageBody>
              <PageMain>
                <Placeholder isMaxHeight title={t`No integration data`} />
              </PageMain>
            </PageBody>
          )
        ) : (
          <PageBody>
            <PageMain>
              <Preloader isMaxHeight />
            </PageMain>
          </PageBody>
        )
      ) : (
        <Placeholder isMaxHeight title={t`Access denied`} />
      )}

      <Confirm
        isActive={isConfirmActive}
        onClick={handleClick}
        text={t`Integration will be removed from publication and sent back for moderation.`}
        title={`Save settings?`}
      />
    </>
  );
};

export default Settings;

import {formatPhoneNumber, useCopyToClipboard} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {getDialogPhoneNumber} from 'common/actions';
import {ProfileListItemParams} from '../../../../components';
import {useAppContext} from 'app-context';
import {useChatContext} from '../../../../../../chat-context';

const usePhoneNumber = (): ProfileListItemParams | null => {
  const {setNotification} = useAppContext();
  const {dialog, telegramUser} = useChatContext();

  const copyToClipboard = useCopyToClipboard();
  const {t} = useTranslation();

  const handleClick = async (str: string) => {
    const res = await copyToClipboard(str);
    setNotification({title: res.message});
  };

  const dialogPhoneNumber = getDialogPhoneNumber(dialog, telegramUser);
  if (!dialogPhoneNumber) return null;

  const phoneNumber: ProfileListItemParams = {
    label: t`phone number`,
    onClick: () => handleClick(dialogPhoneNumber),
    type: 'copy',
    value: formatPhoneNumber(dialogPhoneNumber)
  };

  return phoneNumber;
};

export default usePhoneNumber;

import React, {useId} from 'react';
import {Text, Wrapper} from 'components';

interface Checkbox {
  comment?: string;
  icon?: React.ReactNode;
  label: React.ReactNode;
  name: string;
  onChange: (arg: boolean) => void;
  style?: React.CSSProperties;
  value: boolean | 0 | 1;
}

const Checkbox = ({
  comment,
  icon,
  label,
  name,
  onChange,
  style,
  value
}: Checkbox) => {
  const id = useId();

  return (
    <label className="checkbox" htmlFor={id} style={style}>
      <input
        id={id}
        name={name}
        type="checkbox"
        checked={!!value}
        hidden
        onChange={e => onChange(e.target.checked)}
      />

      <div className="checkbox__button" />

      <Wrapper flexWrap="nowrap" gap={2} style={{minWidth: 0}}>
        {icon}

        <div style={{minWidth: 0}}>
          <Text isNoWrap size={16}>
            {label}
          </Text>

          {comment && (
            <Text color="secondary" isNoWrap size={12}>
              {comment}
            </Text>
          )}
        </div>
      </Wrapper>
    </label>
  );
};

export default Checkbox;

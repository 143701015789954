import React from 'react';
import upd from 'immutability-helper';

import {ColorPicker} from 'components';
import {useChatContext} from '../../../../chat-context';

interface Color {
  name: 'primary' | 'secondary';
}

const Color = ({name}: Color) => {
  const {setWidget, widget} = useChatContext();

  const handleChange = (value: string) => {
    const newValue = upd(widget.colors, {[name]: {$set: value}});
    setWidget(prevValue => upd(prevValue, {colors: {$set: newValue}}));
  };

  const {colors} = widget;

  return (
    <ColorPicker name={name} onChange={handleChange} value={colors[name]} />
  );
};

export default Color;

import React from 'react';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {Select} from 'components';

interface Option {
  label: string;
  value: 'phone' | 'username';
}

const Type = () => {
  const [field, meta, helpers] = useField<'phone' | 'username'>('type');
  const {t} = useTranslation();

  const options: Option[] = [
    {label: t`Phone number`, value: 'phone'},
    {label: t`Username`, value: 'username'}
  ];

  const isValid = !(meta.error && meta.touched);

  return (
    <Select
      {...field}
      isValid={isValid}
      onChange={helpers.setValue}
      options={options}
      placeholder={t`Search type`}
    />
  );
};

export default Type;

import {ProfileListItemParams} from '../../../components';

import useBulletin from './use-bulletin/use-bulletin';
import useDomain from './use-domain/use-domain';
import useEmail from './use-email/use-email';
import useLocation from './use-location/use-location';
import usePhoneNumber from './use-phone-number/use-phone-number';
import useReferer from './use-referer/use-referer';
import useUsername from './use-username/use-username';

const useProfileListItems = (): ProfileListItemParams[] => {
  const bulletin = useBulletin();
  const domain = useDomain();
  const email = useEmail();
  const location = useLocation();
  const phoneNumber = usePhoneNumber();
  const referer = useReferer();
  const username = useUsername();

  const profileListItems = [
    phoneNumber,
    username,
    email,
    bulletin,
    domain,
    location,
    referer
  ].filter(item => item != null);

  return profileListItems as ProfileListItemParams[];
};

export default useProfileListItems;

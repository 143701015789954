import React, {useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import upd from 'immutability-helper';

import {Button, Icons, InputAlt, Text, Wrapper} from 'components';
import * as AppEnv from 'app-env';

interface InputMulti {
  autoFocus?: boolean;
  name: string;
  onChange: (value: AppEnv.Button[]) => void;
  onClear?: () => void;
  placeholder: string;
  style?: React.CSSProperties;
  title: string;
  type?: React.HTMLInputTypeAttribute;
  value: AppEnv.Button[];
}

const InputMulti = ({
  autoFocus,
  name,
  onChange,
  onClear,
  placeholder,
  style,
  title,
  type,
  value
}: InputMulti) => {
  const {t} = useTranslation();
  const ref = useRef<HTMLDivElement>(null);

  const focus = (n: 'first' | 'last') =>
    setTimeout(() => {
      const input = ref.current?.querySelector(`.input-alt:${n}-of-type`);

      const inputField: HTMLDivElement | null | undefined =
        input?.querySelector('.input-alt__field');

      inputField?.focus();
    });

  useEffect(() => {
    if (!autoFocus) return;
    focus('first');
  }, []);

  const handleChange = (input: string, index: number) => {
    const newValue = upd(value, {[index]: {body: {$set: input}}});
    onChange(newValue);
  };

  const handleClear = (index: number) => {
    const newValue = upd(value, {$splice: [[index, 1]]});
    onChange(newValue);
  };

  const handleClick = () => {
    const newValue = upd(value, {$push: [{body: ''}]});

    focus('last');
    onChange(newValue);
  };

  const length = 12 - value.length;

  return (
    <div style={style}>
      <Wrapper
        alignItems="center"
        flexWrap="nowrap"
        justifyContent="space-between"
        style={{marginBottom: onClear ? 0 : 4, minHeight: 32}}
      >
        <Text size={16}>{title}</Text>

        {onClear && (
          <Button color="transparent" onClick={onClear}>
            <Icons.TrashSimple size={20} />
          </Button>
        )}
      </Wrapper>

      {value.length > 0 && (
        <div className="input-multi" ref={ref} style={{marginBottom: 8}}>
          {value.map((button, i) => (
            <InputAlt
              key={i}
              name={`${name}-${i}`}
              onChange={input => handleChange(input, i)}
              onClear={value.length > 2 ? () => handleClear(i) : undefined}
              placeholder={placeholder}
              type={type}
              value={button.body}
            />
          ))}
        </div>
      )}

      <Button
        color="blue-dash"
        style={{marginBottom: 8}}
        onClick={handleClick}
        state={length ? undefined : 'disabled'}
        width="100%"
      >
        {t`Add`}
        <Icons.Plus color="blue" size={20} />
      </Button>

      <Text color="secondary" size={12}>
        {t('{{length}} more can be added', {length})}
      </Text>
    </div>
  );
};

export default InputMulti;

import React from 'react';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {Input} from 'components';

const Email = () => {
  const [field, , helpers] = useField<string | undefined>('email');
  const {t} = useTranslation();

  return (
    <Input
      {...field}
      onChange={helpers.setValue}
      placeholder={t`Email`}
      style={{marginBottom: 16}}
      type="email"
    />
  );
};

export default Email;

import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import upd from 'immutability-helper';

import {Button, Icons} from 'components';
import {RoleForm} from '../../components';
import {useRequest} from 'common/hooks';
import {useStaffContext} from '../../staff-context';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

const RoleCreate = () => {
  const {user} = useWorkspaceContext();
  const {setRoleList} = useStaffContext();

  const [isActive, setIsActive] = useState(false);

  const {postRolesCreate} = useRequest();
  const {t} = useTranslation();

  const handleSubmit = async (
    values: Pick<AppEnv.Role, 'accesses' | 'title'>
  ) => {
    const {integration} = user;

    const res = await postRolesCreate(integration.id, values);
    if (!res) return true;

    setIsActive(false);
    setRoleList(prevValue => upd(prevValue, {$push: [res]}));

    return true;
  };

  return (
    <>
      <Button
        color="blue-dash"
        onClick={() => setIsActive(true)}
        style={{marginBottom: 16}}
      >
        {t`Add role`}
        <Icons.Plus color="blue" size={20} />
      </Button>

      <RoleForm
        isActive={isActive}
        onSubmit={handleSubmit}
        setIsActive={setIsActive}
      />
    </>
  );
};

export default RoleCreate;

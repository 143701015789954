import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {
  Nav,
  Page,
  PageBody,
  PageMain,
  Placeholder,
  Preloader
} from 'components';
import {useRequest} from 'common/hooks';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

import Widget from './widget/widget';
import WidgetCreate from './widget-create/widget-create';

const WidgetsMain = () => {
  const {user} = useWorkspaceContext();
  const [widgetList, setWidgetList] = useState<AppEnv.Chat[]>();

  const {t} = useTranslation();
  const {fetchChatsList} = useRequest();

  useEffect(() => {
    document.title = `${t`Widgets`} - Whatcrm Web`;
  }, []);

  const getWidgetList = async () => {
    const {integration} = user;

    const res = await fetchChatsList(integration.id);
    setWidgetList(res || []);
  };

  useEffect(() => {
    getWidgetList();
  }, []);

  const {roles} = user.manager;

  return (
    <Page>
      <PageBody>
        <Nav options={[{label: t`Widgets`, value: ''}]} value="" />

        {roles.widgets_write != false ? (
          <WidgetCreate />
        ) : (
          <div style={{marginBottom: 20}} />
        )}

        {widgetList ? (
          widgetList.length > 0 ? (
            widgetList.map(widget => (
              <Widget
                key={widget.id}
                setWidgetList={setWidgetList}
                widget={widget}
              />
            ))
          ) : (
            <PageMain>
              <Placeholder isMaxHeight title={t`No widgets`} />
            </PageMain>
          )
        ) : (
          <PageMain>
            <Preloader isMaxHeight />
          </PageMain>
        )}
      </PageBody>
    </Page>
  );
};

export default WidgetsMain;

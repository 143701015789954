import React, {useEffect, useRef, useState} from 'react';
import {useDropzone} from 'react-dropzone';

import {useDispatchContext} from '../../dispatch-context';
import {useGetDialogName} from 'pages/workspace/hooks';
import * as AppEnv from 'app-env';

import {useContact} from './hooks';
import ChatContext from './chat-context';
import ChatFooter from './chat-footer/chat-footer';
import ChatHeader from './chat-header/chat-header';
import Convo from './convo/convo';
import useTelegramUser from './use-telegram-user';

interface Chat {
  dialog: AppEnv.Dialog;
  setWallpaperId: AppEnv.SetState<string | undefined>;
  wallpaperId: string | undefined;
}

const Chat = ({dialog, setWallpaperId, wallpaperId}: Chat) => {
  const {instance, requestDialogProfileImage} = useDispatchContext();
  const [quotedMsg, setQuotedMsg] = useState<AppEnv.QuotedMsg>();

  const {getRootProps, isDragAccept: isDropzoneActive} = useDropzone({
    noClick: true,
    noKeyboard: true
  });

  const contact = useContact(dialog);
  const convoRef = useRef<HTMLDivElement>(null);
  const getDialogName = useGetDialogName();
  const telegramUser = useTelegramUser(dialog);

  useEffect(() => {
    const dialogName = getDialogName(instance, dialog);
    if (dialogName) document.title = `${dialogName} - Whatcrm Web`;
  }, [dialog]);

  useEffect(() => {
    requestDialogProfileImage(dialog);
  }, []);

  return (
    <ChatContext.Provider
      value={{
        contact,
        convoRef,
        dialog,
        isDropzoneActive,
        quotedMsg,
        setQuotedMsg,
        setWallpaperId,
        telegramUser,
        wallpaperId
      }}
    >
      <div {...getRootProps()} className="chat__wrapper">
        <ChatHeader />
        <Convo ref={convoRef} />
        <ChatFooter />
      </div>
    </ChatContext.Provider>
  );
};

export default Chat;

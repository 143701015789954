import React from 'react';
import {useTranslation} from 'react-i18next';
import upd from 'immutability-helper';

import {Seq, Text} from 'components';
import {useAppContext} from 'app-context';
import {useRequest} from 'common/hooks';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

interface Widget {
  setWidgetList: AppEnv.SetState<AppEnv.Chat[] | undefined>;
  widget: AppEnv.Chat;
}

const Widget = ({setWidgetList, widget}: Widget) => {
  const {setNotification} = useAppContext();
  const {user} = useWorkspaceContext();

  const {fetchChatsDelete} = useRequest();
  const {t} = useTranslation();

  const handleClick = async (id: number) => {
    const {integration} = user;
    const res = await fetchChatsDelete(integration.id, id);
    if (!res) return true;

    setNotification({title: t`Widget deleted`});

    setWidgetList(prevValue => {
      const index = prevValue?.findIndex(widget => widget.id == id);
      if (index == undefined || index < 0) return prevValue;

      return upd(prevValue, {$splice: [[index, 1]]});
    });

    return true;
  };

  const {roles} = user.manager;

  const onDelete =
    roles.widgets_write != false ? () => handleClick(widget.id) : undefined;

  return (
    <Seq
      confirm={t`Delete this widget?`}
      href={roles.widgets_write != false ? `/widgets/${widget.uid}` : undefined}
      key={widget.id}
      onDelete={onDelete}
    >
      <Text fontWeight={600} size={14}>
        {widget.title}
      </Text>
    </Seq>
  );
};

export default Widget;

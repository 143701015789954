import {getChatUser} from 'common/actions';
import * as AppEnv from 'app-env';

const getProfileNameChat = (
  instance: AppEnv.Instance,
  dialog: AppEnv.ChatDialog
) => {
  const user = getChatUser(instance, dialog);
  return user?.name || user?.email || null;
};

export default getProfileNameChat;

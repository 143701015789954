import * as AppEnv from 'app-env';
import useGetProfileName from '../use-get-profile-name/use-get-profile-name';

const useGetDialogName = () => {
  const getProfileName = useGetProfileName();

  const getDialogNameAvito = (
    instance: AppEnv.Instance,
    dialog: AppEnv.AvitoDialog
  ) => {
    const profileName = getProfileName(instance, dialog);
    const {value} = dialog.context;

    return [profileName, value.title, value.price_string].join(' • ');
  };

  const getDialogName = (instance: AppEnv.Instance, dialog: AppEnv.Dialog) => {
    if (dialog.version == 'avito') return getDialogNameAvito(instance, dialog);

    const profileName = getProfileName(instance, dialog);
    return profileName;
  };

  return getDialogName;
};

export default useGetDialogName;

import React from 'react';
import {useField} from 'formik';

import {InputAlt} from 'components';

const Url = () => {
  const [field, , helpers] = useField<string>('url');

  return (
    <InputAlt
      {...field}
      maxWidth="100%"
      onChange={helpers.setValue}
      placeholder="https://"
      type="url"
    />
  );
};

export default Url;

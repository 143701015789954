import React from 'react';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {InputAlt, Text, Textarea} from 'components';
import {useChatContext} from '../../../../../chat-context';
import {useHandleEnterDown} from '../../../hooks';

const Body = () => {
  const {dialog} = useChatContext();

  const [field, meta, helpers] = useField<string>('body');
  const {t} = useTranslation();
  const handleEnterDown = useHandleEnterDown();

  const isValid = !(meta.error && meta.touched);

  const params = {
    ...field,
    autoFocus: true,
    isValid,
    onChange: helpers.setValue
  };

  return (
    <div>
      <Text size={16} style={{marginBottom: 4}}>
        {dialog.version == 'whatcrm' ? t`Question` : t`Message`}
      </Text>

      {dialog.version == 'whatcrm' ? (
        <InputAlt
          {...params}
          autoComplete="off"
          placeholder={t`Ask a question`}
        />
      ) : (
        <Textarea
          {...params}
          isAlt
          onKeyDown={handleEnterDown}
          placeholder={t`Type a message`}
        />
      )}
    </div>
  );
};

export default Body;

import React from 'react';
import {useTranslation} from 'react-i18next';

import {Label} from '../../components';
import {PopupWrapper, ProgressBar} from 'components';
import {useInstanceContext} from '../../instance-context';

const LoadingScreen = () => {
  const {instance} = useInstanceContext();
  const {t} = useTranslation();

  const {condition} = instance;

  return (
    <PopupWrapper>
      <Label style={{marginBottom: 8}} />

      <ProgressBar
        label={t`Loading...`}
        percent={condition?.percent || 0}
        isMaxWidth
      />
    </PopupWrapper>
  );
};

export default LoadingScreen;

import {useGetAvailableDialogList, useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

const useGetDialogs = (activeInstances: AppEnv.Instance[]) => {
  const {fetchDialogs} = useRequest();
  const getAvailableDialogList = useGetAvailableDialogList();

  const getDialogs = async () => {
    const dialogs: AppEnv.Dialogs = {};

    const promises = activeInstances.map(async instance => {
      const {access_manager} = instance;

      const res = await fetchDialogs(
        instance.chat_key,
        access_manager?.access_token
      );

      if (!res) return;

      const availableDialogList = getAvailableDialogList(instance, res);
      dialogs[instance.id] = availableDialogList;
    });

    await Promise.all(promises);
    return dialogs;
  };

  return getDialogs;
};

export default useGetDialogs;

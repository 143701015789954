import React from 'react';
import {useTranslation} from 'react-i18next';
import upd from 'immutability-helper';

import {Input} from 'components';
import {useChatContext} from '../../../chat-context';

const Privacy = () => {
  const {setWidget, widget} = useChatContext();
  const {t} = useTranslation();

  const handleChange = (value: string) =>
    setWidget(prevValue => upd(prevValue, {privacy: {$set: value}}));

  return (
    <Input
      name="privacy"
      onChange={handleChange}
      placeholder={t`Privacy Policy link`}
      type="url"
      value={widget.privacy}
    />
  );
};

export default Privacy;

import React from 'react';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';
import upd from 'immutability-helper';

import {Select, Text} from 'components';
import {useChatContext} from '../../../../chat-context';
import {useGetInstanceLabel} from 'common/hooks';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

const InstanceId = () => {
  const {user} = useWorkspaceContext();
  const {setWidget} = useChatContext();

  const [field, meta, helpers] = useField<number>('instance_id');
  const {t} = useTranslation();
  const getInstanceLabel = useGetInstanceLabel();

  const handleChange = (value: number) => {
    const instance = Object.values(user.instances).find(
      (instance: AppEnv.Instance) => instance.id == value
    );

    if (!instance) return;
    helpers.setValue(value);

    setWidget(prevValue =>
      upd(prevValue, {instance_chat_key: {$set: instance.chat_key}})
    );
  };

  const isValid = !(meta.error && meta.touched);

  const options = Object.values(user.instances)
    .filter((instance: AppEnv.Instance) => instance.version == 'chat')
    .map((instance: AppEnv.Instance) => ({
      label: getInstanceLabel(instance),
      value: instance.id
    }));

  return (
    <div>
      <Text size={16} style={{marginBottom: 8}}>
        {t`Connection is required for online chat`}
      </Text>

      <Select
        {...field}
        isValid={isValid}
        onChange={handleChange}
        options={options}
        placeholder={t`Connection`}
      />
    </div>
  );
};

export default InstanceId;

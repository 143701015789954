import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

import {Anchor, Wrapper} from 'components';
import {getDialogId} from 'common/actions';
import {LabelForm} from 'pages/workspace/components';
import {useChatContext} from '../../../chat-context';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';

import Label from './label/label';

const Labels = () => {
  const {labelList, user} = useWorkspaceContext();
  const {dialog} = useChatContext();

  const [isActive, setIsActive] = useState(false);
  const {t} = useTranslation();

  const {roles} = user.manager;

  const labels =
    labelList?.filter(
      label => label.chatId == getDialogId(dialog, true)?.toString()
    ) || [];

  return (
    <>
      {(labels.length > 0 || roles.dialogs_labels != false) && (
        <>
          <Wrapper direction="column" gap={8}>
            {labels.length > 0 && (
              <Wrapper gap={8}>
                {labels.map(label => (
                  <Label key={label.id} label={label} />
                ))}
              </Wrapper>
            )}

            {roles.dialogs_labels != false && (
              <Anchor onClick={() => setIsActive(true)} size={16}>
                {t`Add a tag`}
              </Anchor>
            )}
          </Wrapper>

          <LabelForm
            dialog={dialog}
            isActive={isActive}
            setIsActive={setIsActive}
          />
        </>
      )}
    </>
  );
};

export default Labels;

import React, {forwardRef} from 'react';
import {useFormikContext} from 'formik';

import {getIsLimited} from 'common/actions';
import {Icons, SaveButton, Voice, Wrapper} from 'components';
import {useChatContext} from '../../chat-context';
import {useChatFooterContext} from '../chat-footer-context';
import {useDispatchContext} from 'pages/workspace/pages/messenger/dispatch/dispatch-context';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import {Values} from '../chat-footer-env';

import Body from './body/body';
import Clear from './clear/clear';
import Emoji from './emoji/emoji';
import PaperClip from './paper-clip/paper-clip';

interface MessageInput {
  textRef: React.RefObject<HTMLTextAreaElement>;
}

const MessageInput = forwardRef<HTMLTextAreaElement, MessageInput>(
  ({textRef}, ref) => {
    const {user} = useWorkspaceContext();
    const {instance} = useDispatchContext();
    const {quotedMsg} = useChatContext();

    const {isAttachsFormActive, isVoiceRecording, setIsVoiceRecording} =
      useChatFooterContext();

    const {values} = useFormikContext<Values>();

    const {roles} = user.manager;
    const isLimited = getIsLimited(instance);
    const isSendButtonShown = !isAttachsFormActive && !!values.body;

    const isVoice =
      (instance.version != 'chat' || !isLimited) &&
      roles.messages_voice != false;

    return (
      <div className="chat-footer__wrapper">
        {!isVoiceRecording && (
          <>
            <PaperClip />
            <Body ref={ref} />
          </>
        )}

        <Wrapper
          flexGrow={isVoiceRecording ? 1 : undefined}
          flexWrap="nowrap"
          gap={8}
        >
          {!isVoiceRecording && (
            <>
              {roles.messages_text != false ? (
                <Emoji textRef={textRef} />
              ) : (
                values.body && <Clear />
              )}
            </>
          )}

          {isSendButtonShown ? (
            <SaveButton
              color="transparent"
              state={!values.body ? 'disabled' : undefined}
            >
              <Icons.PaperPlaneRight />
            </SaveButton>
          ) : (
            isVoice && (
              <Voice
                isDisabled={quotedMsg?.isEditing}
                isVoiceRecording={isVoiceRecording}
                setIsVoiceRecording={setIsVoiceRecording}
                version={instance.version}
              />
            )
          )}
        </Wrapper>
      </div>
    );
  }
);

MessageInput.displayName = 'MessageInput';
export default MessageInput;

import React from 'react';
import {useTranslation} from 'react-i18next';
import upd from 'immutability-helper';

import {SelectAlt, Title} from 'components';
import {useChatContext} from '../../../chat-context';

const values = [
  'faq-chat',
  'help-center',
  'live-chat',
  'support-24',
  'virtual-assistant'
];

const options = values.map(value => ({
  icon: `/static/widget/icons.svg#${value}`,
  value
}));

const Type = () => {
  const {setWidget, widget} = useChatContext();
  const {t} = useTranslation();

  const handleChange = (value: string) =>
    setWidget(prevValue => upd(prevValue, {icon: {$set: value}}));

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Icon`}</Title.H3>

      <SelectAlt
        onChange={handleChange}
        options={options}
        value={widget.icon}
      />
    </div>
  );
};

export default Type;

import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import upd from 'immutability-helper';

import {Button, Icons} from 'components';
import {ManagerForm} from '../components';
import {useAppContext} from 'app-context';
import {useRequest} from 'common/hooks';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

interface ManagerCreate {
  setManagerList: AppEnv.SetState<AppEnv.Manager[] | undefined>;
}

const ManagerCreate = ({setManagerList}: ManagerCreate) => {
  const {setNotification} = useAppContext();
  const {user} = useWorkspaceContext();

  const [isActive, setIsActive] = useState(false);

  const {postManagersCreate} = useRequest();
  const {t} = useTranslation();

  const handleSubmit = async (values: Partial<AppEnv.Manager>) => {
    const {integration} = user;

    const res = await postManagersCreate(integration.id, values);
    if (!res) return true;

    setIsActive(false);
    setManagerList(prevValue => upd(prevValue, {$push: [res]}));
    setNotification({title: t`Staff member has been added`});

    return true;
  };

  return (
    <>
      <Button
        color="blue-dash"
        onClick={() => setIsActive(true)}
        style={{marginBottom: 16}}
      >
        {t`Add staff member`}
        <Icons.Plus color="blue" size={20} />
      </Button>

      <ManagerForm
        isActive={isActive}
        onSubmit={handleSubmit}
        setIsActive={setIsActive}
      />
    </>
  );
};

export default ManagerCreate;

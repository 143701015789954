import upd from 'immutability-helper';

import {getDialogId} from 'common/actions';
import {useWebSocketProviderContext} from 'pages/workspace/web-socket-provider/web-socket-context';
import * as AppEnv from 'app-env';

const useHandlePinDialog = () => {
  const {setDialogs} = useWebSocketProviderContext();

  const handlePinDialog = (
    instance: AppEnv.Instance,
    dialog: AppEnv.ChatDialog | undefined,
    is_pinned: boolean
  ) => {
    if (!dialog) return;

    setDialogs(prevValue => {
      const index = prevValue?.[instance.id]?.findIndex(
        item => getDialogId(item, true) == getDialogId(dialog, true)
      );

      if (index == undefined || index < 0) return prevValue;

      return upd(prevValue, {
        [instance.id]: {[index]: {is_pinned: {$set: is_pinned}}}
      });
    });
  };

  return handlePinDialog;
};

export default useHandlePinDialog;

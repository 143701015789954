import {useGetDialogName} from '..';
import * as AppEnv from 'app-env';

const useDialogName = (
  instance: AppEnv.Instance,
  dialog: AppEnv.Dialog | undefined
) => {
  const getDialogName = useGetDialogName();

  if (!dialog) return null;
  const dialogName = getDialogName(instance, dialog);

  return dialogName;
};

export default useDialogName;

import React from 'react';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {InputMulti} from 'components';
import {useChatContext} from '../../../../../chat-context';
import * as AppEnv from 'app-env';

const Buttons = () => {
  const {dialog} = useChatContext();

  const [field, , helpers] = useField<AppEnv.Button[]>('buttons');
  const {t} = useTranslation();

  const placeholder =
    dialog.version == 'whatcrm' ? t`Type an answer` : t`Type a reply`;

  return (
    <InputMulti
      {...field}
      onChange={helpers.setValue}
      placeholder={placeholder}
      title={dialog.version == 'whatcrm' ? t`Answer options` : t`Reply options`}
    />
  );
};

export default Buttons;

import React from 'react';
import {Button, Icons} from 'components';

interface TagDelete {
  isDisabled?: boolean;
  onClick: () => void;
}

const TagDelete = ({isDisabled, onClick}: TagDelete) => (
  <Button
    className="tag__delete"
    color="transparent"
    onClick={onClick}
    state={isDisabled ? 'disabled' : undefined}
  >
    <Icons.Close size={20} />
  </Button>
);

export default TagDelete;

import React from 'react';

import {getDialogId, getDialogProfileImage} from 'common/actions';
import {Text, Wrapper, ProfileImage} from 'components';
import {useChatContext} from '../../../chat-context';
import {useDispatchContext} from 'pages/workspace/pages/messenger/dispatch/dispatch-context';
import {useProfileName} from 'pages/workspace/hooks';

const Account = () => {
  const {instance} = useDispatchContext();
  const {dialog} = useChatContext();

  const name = useProfileName(instance, dialog);
  const originalName = useProfileName(instance, dialog, true);

  return (
    <Wrapper alignItems="center" direction="column" gap={8}>
      <ProfileImage
        id={getDialogId(dialog)}
        name={name}
        size={116}
        src={getDialogProfileImage(instance, dialog)}
      />

      <div>
        {name != originalName && (
          <Text size={16} color="light" fontWeight={500} textAlign="center">
            {originalName}
          </Text>
        )}

        <Text size={24} fontWeight={500} textAlign="center">
          {name}
        </Text>
      </div>
    </Wrapper>
  );
};

export default Account;

import {useEffect, useState} from 'react';

import {useDispatchContext} from '../../dispatch-context';
import {useIsVisible} from 'common/hooks';
import * as AppEnv from 'app-env';

const useProfileImage = (
  dialog: AppEnv.Dialog,
  ref: React.RefObject<HTMLDivElement>
) => {
  const {requestDialogProfileImage} = useDispatchContext();
  const [isRequested, setIsRequested] = useState(false);
  const isVisible = useIsVisible(ref);

  useEffect(() => {
    if (isRequested || !isVisible) return;

    requestDialogProfileImage(dialog);
    setIsRequested(true);
  }, [isVisible]);
};

export default useProfileImage;

import React from 'react';
import {ProfileListItem} from '../components';
import {useProfileListItems} from './hooks';

const ProfileList = () => {
  const profileListItems = useProfileListItems();

  return (
    <>
      {profileListItems.length > 0 && (
        <div className="profile__list">
          {profileListItems.map((profileListItem, i) => (
            <ProfileListItem {...profileListItem} key={i} />
          ))}
        </div>
      )}
    </>
  );
};

export default ProfileList;

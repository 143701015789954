import React from 'react';
import {useTranslation} from 'react-i18next';
import upd from 'immutability-helper';

import {Checkbox} from 'components';
import {useChatContext} from '../../../../chat-context';

const IsEmail = () => {
  const {setWidget, widget} = useChatContext();
  const {t} = useTranslation();

  const handleChange = (value: boolean) =>
    setWidget(prevValue => upd(prevValue, {is_email: {$set: value}}));

  return (
    <Checkbox
      label={t`Email required`}
      name="is_email"
      onChange={handleChange}
      style={{marginBottom: 8}}
      value={widget.is_email}
    />
  );
};

export default IsEmail;

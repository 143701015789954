import {useChatContext} from '../../../chat-context';
import {Values} from '../../chat-footer-env';
import * as AppEnv from 'app-env';

import useEditMsg from './use-edit-msg/use-edit-msg';
import useGetBody from './use-get-body/use-get-body';
import useSendAttachs from './use-send-attachs/use-send-attachs';
import useSendButtons from './use-send-buttons/use-send-buttons';
import useSendText from './use-send-text/use-send-text';
import useSendVoice from './use-send-voice/use-send-voice';

const useSendMsg = (
  setIsVoiceRecording: AppEnv.SetState<boolean>,
  setProgress: AppEnv.SetState<number[]>
) => {
  const {quotedMsg} = useChatContext();

  const editMsg = useEditMsg();
  const getBody = useGetBody();
  const sendAttachs = useSendAttachs(setProgress);
  const sendButtons = useSendButtons();
  const sendText = useSendText();
  const sendVoice = useSendVoice(setIsVoiceRecording);

  const sendMsg = (values: Values) => {
    const body = getBody(values);
    const newValues = {...values, body};

    if (quotedMsg?.isEditing) return editMsg(newValues);
    else if (values.attachs.length) return sendAttachs(newValues);
    else if (values.buttons.some(button => button.body))
      return sendButtons(newValues);
    else if (values.voice) return sendVoice(newValues);

    return sendText(newValues);
  };

  return sendMsg;
};

export default useSendMsg;

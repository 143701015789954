import React from 'react';

import {
  Button,
  Card,
  CardBody,
  CardHead,
  CardMenu,
  Icons,
  Link,
  Text,
  Title
} from 'components';
import {Product as ProductProps} from '../../products-env';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';

const style: React.CSSProperties = {borderRadius: 4, height: 32, width: 32};

interface Product {
  product: ProductProps;
}

const Product = ({product}: Product) => {
  const {user} = useWorkspaceContext();

  const {roles} = user.manager;

  return (
    <Card>
      <CardHead>
        <img src={product.image} style={style} />
        <Title.H3 style={{marginTop: 6}}>{product.description_short}</Title.H3>
      </CardHead>

      <CardBody>
        <Text size={14}>{product.description}</Text>
      </CardBody>

      {roles.products_write != false && (
        <CardMenu>
          <Link href={product.link} isTargetBlank>
            <Button color="transparent">
              <Icons.ArrowSquareOut size={20} />
              <span>{product.label}</span>
            </Button>
          </Link>
        </CardMenu>
      )}
    </Card>
  );
};

export default Product;

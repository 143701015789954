import {useTranslation} from 'react-i18next';
import * as AppEnv from 'app-env';

const useRoleTitle = (role: AppEnv.Role) => {
  const {t} = useTranslation();

  const accesses: AppEnv.RoleAccesses = JSON.parse(role.accesses);
  if (!Object.keys(accesses).length) return t`Full access`;

  return t`Limited access`;
};

export default useRoleTitle;

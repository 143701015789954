import React from 'react';

import {Button, Wrapper} from 'components';
import * as AppEnv from 'app-env';

interface MsgButtonList {
  buttons: AppEnv.Button[];
}

const MsgButtonList = ({buttons}: MsgButtonList) => (
  <Wrapper gap={8}>
    {buttons.map((button, i) => (
      <Button color="green-border" key={i} minHeight={32} state="disabled">
        {button.body}
      </Button>
    ))}
  </Wrapper>
);

export default MsgButtonList;

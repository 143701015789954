import React from 'react';
import upd from 'immutability-helper';

import {ChipsAlt, ChipsAltOption} from 'components';
import {useChatContext} from '../../../../chat-context';
import * as AppEnv from 'app-env';

const Type = () => {
  const {setWidget, widget} = useChatContext();

  const handleChange = (value: AppEnv.ChatWidgetCallbackType) =>
    setWidget(prevValue => upd(prevValue, {callback_type: {$set: value}}));

  const options: ChipsAltOption<AppEnv.ChatWidgetCallbackType>[] = [
    {
      img: '/static/widget/callback-type/horizontal.svg',
      value: 'horizontal'
    },
    {img: '/static/widget/callback-type/image.svg', value: 'image'},
    {
      img: '/static/widget/callback-type/vertical.svg',
      value: 'vertical'
    }
  ];

  return (
    <ChipsAlt
      onChange={handleChange}
      options={options}
      value={widget.callback_type}
    />
  );
};

export default Type;

import {useLocation, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import upd from 'immutability-helper';

import {getDialogId} from 'common/actions';
import {useAppContext} from 'app-context';
import {useDispatchContext} from 'pages/workspace/pages/messenger/dispatch/dispatch-context';
import {useGetDialogName} from 'pages/workspace/hooks';
import {useMessengerContext} from '../../messenger-context';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

const useDeleteDialog = (
  dialog: AppEnv.Dialog,
  setIsConfirmationActive: AppEnv.SetState<boolean>
) => {
  const {isIframe, setNotification} = useAppContext();
  const {setDialogs} = useMessengerContext();
  const {dialogList, instance} = useDispatchContext();

  const {postRemoveChat} = useRequest();
  const {t} = useTranslation();
  const getDialogName = useGetDialogName();
  const location = useLocation();
  const navigate = useNavigate();

  const handleFail = () => {
    setNotification({title: t`Failed to delete the chat`});
    return true;
  };

  const handleDelete = (index: number) => {
    setDialogs(prevValue =>
      upd(prevValue, {[instance.id]: {$splice: [[index, 1]]}})
    );

    setNotification({
      title: getDialogName(instance, dialog) || '',
      text: t`The chat has been deleted.`
    });

    if (!location.pathname.includes(dialog.id.toString())) return true;
    navigate(`/messenger/${instance.id}`, {replace: isIframe});

    return true;
  };

  const handleClear = (index: number) => {
    setDialogs(prevValue =>
      upd(prevValue, {
        [instance.id]: {
          [index]: {lastMessage: {$set: undefined}, messages: {$set: []}}
        }
      })
    );

    setNotification({
      title: getDialogName(instance, dialog) || '',
      text: t`The chat has been cleared.`
    });

    return true;
  };

  const deleteDialog = async () => {
    const id = getDialogId(dialog, true);
    if (!id) return true;

    const res = await postRemoveChat(instance.chat_key, id);
    if (res?._ != 'ok' && !res?.result && !res?.success) return handleFail();

    setIsConfirmationActive(false);

    const index = dialogList.findIndex(item => getDialogId(item, true) == id);
    if (index < 0) return true;

    await new Promise(resolve => setTimeout(resolve, 150));

    if (dialog.version != 'whatcrm') return handleDelete(index);
    return handleClear(index);
  };

  return deleteDialog;
};

export default useDeleteDialog;

import React, {useState} from 'react';

import {ContextMenu} from 'components';
import {getIsLimited} from 'common/actions';
import {useChatContext} from 'pages/workspace/pages/messenger/dispatch/chat-provider/chat/chat-context';
import {useDispatchContext} from 'pages/workspace/pages/messenger/dispatch/dispatch-context';
import {useMessageContext} from '../../../message-context';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

import {useOptions} from './hooks';
import DeleteMessage from './delete-message/delete-message';
import Forward from './forward/forward';
import MenuContext from './menu-context';
import ReactionPicker from './reaction-picker/reaction-picker';
import ReactionTrigger from './reaction-trigger/reaction-trigger';

interface TriggerPostion {
  left: number;
  top: number;
}

interface Menu {
  isContextMenuActive: boolean;
  isReactionPickerActive: boolean;
  setIsContextMenuActive: AppEnv.SetState<boolean>;
  setIsReactionPickerActive: AppEnv.SetState<boolean>;
  triggerPosition: TriggerPostion | undefined;
}

const Menu = ({
  isContextMenuActive,
  isReactionPickerActive,
  setIsContextMenuActive,
  setIsReactionPickerActive,
  triggerPosition
}: Menu) => {
  const {user} = useWorkspaceContext();
  const {instance} = useDispatchContext();
  const {dialog} = useChatContext();
  const {message} = useMessageContext();

  const [isConfirmActive, setIsConfirmActive] = useState(false);
  const [isForwardActive, setIsForwardActive] = useState(false);

  const getIsReactions = () => {
    const {roles} = user.manager;

    if (!['chat', 'telegram', 'whatcrm'].includes(dialog.version)) return false;
    else if (isLimited) return false;
    else if (roles.messages_reaction == false) return false;

    return true;
  };

  const isLimited = getIsLimited(instance);
  const isReactions = getIsReactions();

  const options = useOptions({
    msg: message,
    setIsConfirmActive,
    setIsForwardActive
  });

  return (
    <MenuContext.Provider value={{setIsContextMenuActive}}>
      <ContextMenu
        isActive={isContextMenuActive}
        options={options}
        setIsActive={setIsContextMenuActive}
        triggerPosition={triggerPosition}
      >
        {isReactions && (
          <ReactionTrigger
            isContextMenuActive={isContextMenuActive}
            setIsReactionPickerActive={setIsReactionPickerActive}
          />
        )}
      </ContextMenu>

      <DeleteMessage
        isConfirmationActive={isConfirmActive}
        setIsConfirmationActive={setIsConfirmActive}
      />

      <Forward
        isForwardActive={isForwardActive}
        setIsForwardActive={setIsForwardActive}
      />

      {isReactions && (
        <ReactionPicker
          isReactionPickerActive={isReactionPickerActive}
          setIsReactionPickerActive={setIsReactionPickerActive}
          triggerPosition={triggerPosition}
        />
      )}
    </MenuContext.Provider>
  );
};

export default Menu;

import React, {useRef, useState} from 'react';
import {Formik, Form} from 'formik';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';

import {
  Button,
  InputAlt,
  Popup,
  PopupFooter,
  PopupWrapper,
  SaveButton,
  Title,
  Wrapper
} from 'components';
import {getDialogId} from 'common/actions';
import {useAppContext} from 'app-context';
import {useChatContext} from 'pages/workspace/pages/messenger/dispatch/chat-provider/chat/chat-context';
import {useDispatchContext} from 'pages/workspace/pages/messenger/dispatch/dispatch-context';
import {useMessageContext} from '../../../../message-context';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

import ChatId from './chat-id/chat-id';

interface Values {
  chatId: string;
}

interface Forward {
  isForwardActive: boolean;
  setIsForwardActive: AppEnv.SetState<boolean>;
}

const Forward = ({isForwardActive, setIsForwardActive}: Forward) => {
  const {isIframe} = useAppContext();
  const {dialogList, instance} = useDispatchContext();
  const {dialog} = useChatContext();
  const {message} = useMessageContext();

  const [search, setSearch] = useState('');

  const {postForwardMessage} = useRequest();
  const {t} = useTranslation();
  const navigate = useNavigate();
  const popupRef = useRef<HTMLDivElement>(null);

  const handleSubmit = async (values: Values) => {
    const dialogId = getDialogId(dialog, true);
    if (!dialogId) return;

    await postForwardMessage(
      instance.chat_key,
      dialogId,
      values.chatId,
      message.id
    );

    setIsForwardActive(false);

    const chat = dialogList.find(
      item => getDialogId(item, true) == values.chatId
    );

    if (!chat) return true;

    const id = getDialogId(chat);
    if (!id) return;

    navigate(`/messenger/${instance.id}/${id}`, {replace: isIframe});
    return true;
  };

  const initialValues: Values = {chatId: ''};

  const validationSchema = yup
    .object()
    .shape({chatId: yup.string().trim().required()});

  return (
    <Popup
      ref={popupRef}
      width={408}
      isActive={isForwardActive}
      isMaxHeight
      onClick={() => setIsForwardActive(false)}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnBlur={false}
        onSubmit={handleSubmit}
      >
        {({values}) => (
          <Form>
            <PopupWrapper>
              <Title.H3 style={{marginBottom: 16}}>
                {t`Forward message`}
              </Title.H3>

              <InputAlt
                autoFocus
                name="new-search"
                onChange={setSearch}
                placeholder={t`Search`}
                style={{marginBottom: 16}}
                type="search"
                value={search}
              />

              <ChatId popupRef={popupRef} search={search} />
            </PopupWrapper>

            <PopupFooter>
              <Wrapper gap={12}>
                <SaveButton state={values.chatId ? undefined : 'disabled'}>
                  {t`Send`}
                </SaveButton>

                <Button color="fade" onClick={() => setIsForwardActive(false)}>
                  {t`Close`}
                </Button>
              </Wrapper>
            </PopupFooter>
          </Form>
        )}
      </Formik>
    </Popup>
  );
};

export default Forward;

import React from 'react';
import {useTranslation} from 'react-i18next';

import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import {Wrapper} from 'components';

import Access from './access/access';
import Dialogs from './dialogs/dialogs';
import Messages from './messages/messages';
import Settings from './settings/settings';

const Accesses = () => {
  const {user} = useWorkspaceContext();
  const {t} = useTranslation();

  const {integration} = user;

  return (
    <Wrapper direction="column" gap={16}>
      <Dialogs />
      <Messages />

      <Access
        placeholder={t`Connections`}
        read="instances_read"
        write="instances_write"
      />

      <Access
        placeholder={t`Billing`}
        read="billings_read"
        write="billings_write"
      />

      {integration.is_integration > 0 && (
        <Access
          placeholder={t`Integration`}
          read="integrations_read"
          write="integrations_write"
        />
      )}

      {integration.is_partner > 0 && (
        <Access
          placeholder={t`Partner program`}
          read="partners_read"
          write="partners_write"
        />
      )}

      <Access
        placeholder={t`Staff`}
        read="managers_read"
        write="managers_write"
      />

      <Access
        placeholder={t`Templates`}
        read="templates_read"
        write="templates_write"
      />

      <Access
        placeholder={t`Blocked chats`}
        read="blacklists_read"
        write="blacklists_write"
      />

      <Access
        placeholder={t`Widgets`}
        read="widgets_read"
        write="widgets_write"
      />

      <Access
        placeholder={t`Products`}
        read="products_read"
        write="products_write"
      />

      <Settings />
    </Wrapper>
  );
};

export default Accesses;

import React, {useRef, useState} from 'react';
import {Backdrop} from 'components';
import {HexColorPicker} from 'react-colorful';

interface InputColor {
  name: string;
  onChange: (value: string) => void;
  placeholder?: string;
  value: string;
}

const InputColor = ({name, onChange, placeholder, value}: InputColor) => {
  const [isActive, setIsActive] = useState(false);
  const [style, setStyle] = useState<React.CSSProperties>();

  const ref = useRef<HTMLDivElement>(null);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    const {target} = e;
    if (!target.value) return onChange(target.value);

    const normalized = `#${target.value.replaceAll('#', '')}`;
    onChange(normalized);
  };

  const handleClick = () => {
    const rect = ref.current?.getBoundingClientRect();

    setIsActive(true);
    setStyle({left: rect?.left, top: (rect?.bottom || 0) + 8});
  };

  return (
    <div className="input-color" ref={ref}>
      <button
        className="input-color__button"
        onClick={handleClick}
        style={{background: value}}
        type="button"
      />

      <Backdrop isActive={isActive} setIsActive={setIsActive} style={style}>
        <HexColorPicker color={value} onChange={onChange} />
      </Backdrop>

      <input
        className="input-color__field"
        maxLength={7}
        name={name}
        onChange={handleChange}
        placeholder={placeholder}
        value={value}
      />
    </div>
  );
};

export default InputColor;

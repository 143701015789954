import React from 'react';
import cn from 'classnames';

interface MiniButton {
  children: React.ReactNode;
  color?: 'green' | 'white';
  onClick: () => void;
  width?: number;
}

const MiniButton = ({
  children,
  color = 'green',
  onClick,
  width
}: MiniButton) => (
  <button
    className={cn('mini-button', {[`mini-button_${color}`]: color})}
    onClick={() => onClick()}
    style={{width}}
    type="button"
  >
    {children}
  </button>
);

export default MiniButton;

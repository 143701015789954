import React from 'react';
import {useTranslation} from 'react-i18next';
import mime from 'mime';

import {AudioPlayer, Media, Wrapper} from 'components';
import {downloadFile} from 'common/actions';
import {
  MessageDocument,
  MessageText,
  MsgButtonList,
  UserMessage
} from '../../components';
import {useChatMessageContext} from '../chat-message-context';

const File = () => {
  const {message} = useChatMessageContext();
  const {t} = useTranslation();

  const handleClick = () => {
    downloadFile(message.file_url, '');
  };

  const fileName = message.file_url.split('/').at(-1);
  const type = mime.getType(message.file_url);

  const isAudio = type?.startsWith('audio');
  const isMedia = type?.startsWith('image') || type?.startsWith('video');

  return (
    <UserMessage>
      {type ? (
        isAudio ? (
          <AudioPlayer fileName={t`Voice message`} src={message.file_url} />
        ) : isMedia ? (
          <Wrapper direction="column" gap={8}>
            <Media
              src={message.file_url}
              type={type?.startsWith('image') ? 'img' : 'video'}
              controls
            />

            {message.content && <MessageText>{message.content}</MessageText>}
          </Wrapper>
        ) : (
          <MessageDocument
            caption={message.content}
            fileName={fileName}
            onClick={handleClick}
          />
        )
      ) : (
        <MessageText isLight>
          {t`The message is not supported on this version.`}
        </MessageText>
      )}

      {message.buttons && <MsgButtonList buttons={message.buttons} />}
    </UserMessage>
  );
};

export default File;

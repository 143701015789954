import React from 'react';
import {Form, Formik} from 'formik';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';

import {
  Anchor,
  Icons,
  PopupFooter,
  PopupWrapper,
  SaveButton,
  Text
} from 'components';
import {Label} from '../../../../components';
import {useInstanceContext} from '../../../../instance-context';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

import AccessToken from './access-token/access-token';
import ChatId from './chat-id/chat-id';
import Platform from './platform/platform';

interface SecondStep {
  setIsFirstStep: AppEnv.SetState<boolean>;
}

interface Values {
  access_token: string;
  chat_id: string;
  platform: string;
}

const initialValues: Values = {access_token: '', chat_id: '', platform: ''};

const validationSchema = yup.object().shape({
  access_token: yup.string().trim().required(),
  chat_id: yup.string().trim().required(),
  platform: yup.string().trim().required()
});

const SecondStep = ({setIsFirstStep}: SecondStep) => {
  const {instance} = useInstanceContext();

  const {postChatUpdate} = useRequest();
  const {t} = useTranslation();

  const handleSubmit = async (values: Values) => {
    postChatUpdate(instance.chat_key, {...values});
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validationSchema={validationSchema}
    >
      <Form>
        <PopupWrapper>
          <Anchor
            fontWeight={500}
            onClick={() => setIsFirstStep(true)}
            size={14}
            style={{marginBottom: 16}}
          >
            <Icons.ArrowLeft />
            <span>{t`Back`}</span>
          </Anchor>

          <Label style={{marginBottom: 8}} />

          <Text size={16} style={{marginBottom: 8}}>
            {'Введите полученные данные.'}
          </Text>

          <AccessToken />
          <ChatId />
          <Platform />
        </PopupWrapper>

        <PopupFooter>
          <SaveButton>{t`Save`}</SaveButton>
        </PopupFooter>
      </Form>
    </Formik>
  );
};

export default SecondStep;

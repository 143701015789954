import React from 'react';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {Select} from 'components';
import {useAccesses} from '../hooks';

const Settings = () => {
  const [, , helpers] = useField<string>('accesses');
  const {t} = useTranslation();
  const accesses = useAccesses();

  const handleChange = (value: string) => {
    const newAccesses = {...accesses, settings_write: !!value};
    const newValue = JSON.stringify(newAccesses);

    helpers.setValue(newValue);
  };

  const options = [
    {label: t`No access`, value: ''},
    {label: t`Full access`, value: 'write'}
  ];

  const value = accesses.settings_write != false ? 'write' : '';

  return (
    <Select
      onChange={handleChange}
      options={options}
      placeholder={t`Settings`}
      value={value}
    />
  );
};

export default Settings;

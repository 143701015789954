import React from 'react';

import {Text} from 'components';
import {getIsEmoji} from 'common/actions';
import {MessageText, UserMessage} from '../../components';
import {useWhatsAppMessageContext} from '../whatsapp-message-context';
import {MsgButtonList} from '../../components';

const Chat = () => {
  const {message} = useWhatsAppMessageContext();

  const buttons = message.dynamicReplyButtons?.map(button => {
    const {buttonText} = button;
    return {body: buttonText.displayText};
  });

  const text = message.body || message.caption;
  const isEmoji = getIsEmoji(text || '');

  return (
    <UserMessage>
      <MessageText>
        {isEmoji ? <Text size={72}>{text}</Text> : text}
      </MessageText>

      {buttons && <MsgButtonList buttons={buttons} />}
    </UserMessage>
  );
};

export default Chat;

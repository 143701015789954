import React, {useRef} from 'react';
import {Button, Icons} from 'components';

interface TimeInput {
  name: string;
  onChange: (value: string) => void;
  value: string;
}

const TimeInput = ({name, onChange, value}: TimeInput) => {
  const ref = useRef<HTMLInputElement>(null);

  return (
    <div className="time-input">
      <input
        className="time-input__field"
        name={name}
        onChange={e => onChange(e.target.value)}
        ref={ref}
        type="time"
        value={value}
      />

      <Button
        className="time-input__button"
        color="transparent"
        onClick={() => ref.current?.showPicker()}
      >
        <Icons.Clock size={20} />
      </Button>
    </div>
  );
};

export default TimeInput;

import React, {useState, useEffect} from 'react';
import _ from 'lodash';
import cn from 'classnames';

import {Icons} from 'components';
import {useChatContext} from '../../chat-context';

const ScrollDown = () => {
  const {convoRef} = useChatContext();
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    convoRef.current?.addEventListener('scroll', _.debounce(handleScroll));

    return () => {
      convoRef.current?.removeEventListener('scroll', _.debounce(handleScroll));
    };
  }, [convoRef.current]);

  const handleClick = () =>
    convoRef.current?.scrollTo({
      behavior: 'smooth',
      top: convoRef.current.scrollHeight
    });

  const handleScroll = () => {
    if (!convoRef || !convoRef.current) return;

    const {clientHeight, scrollHeight, scrollTop} = convoRef.current;

    setIsActive(() =>
      scrollHeight - (scrollTop + clientHeight) > 52 ? true : false
    );
  };

  return (
    <div className="chat-footer__scroll-down">
      <button
        className={cn('chat-footer__fab', {
          'chat-footer__fab_active': isActive
        })}
        onClick={handleClick}
      >
        <Icons.ArrowDown size={24} />
      </button>
    </div>
  );
};

export default ScrollDown;

import React from 'react';
import {Form, Formik} from 'formik';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';

import {
  Button,
  Popup,
  PopupFooter,
  PopupWrapper,
  Preloader,
  SaveButton,
  Title,
  Wrapper
} from 'components';
import * as AppEnv from 'app-env';

import {Attach} from './template-form-env';
import Attachs from './attachs/attachs';
import Buttons from './buttons/buttons';
import Message from './message/message';
import TitleField from './title/title';

interface TemplateFormValues extends Omit<AppEnv.TemplateShape, 'files'> {
  attachs: Attach[];
}

interface TemplateForm {
  isActive: boolean;
  onSubmit: (values: TemplateFormValues) => Promise<boolean>;
  setIsActive: AppEnv.SetState<boolean>;
  template?: AppEnv.Template;
}

const TemplateForm = ({
  isActive,
  onSubmit,
  setIsActive,
  template
}: TemplateForm) => {
  const {t} = useTranslation();

  const getButtons = (values: TemplateFormValues) => {
    let buttons: AppEnv.Button[] | null = values.buttons
      ? JSON.parse(values.buttons)
      : null;

    if (!buttons) return null;

    buttons = buttons.filter(button => button.body);
    if (!buttons.length) return null;

    return JSON.stringify(buttons);
  };

  const getInitialValues = (): TemplateFormValues | null => {
    if (!template) return {attachs: [], buttons: null, message: '', title: ''};
    else if (!template.files) return null;

    return {
      ...template,
      attachs: template.files?.map(file => ({templateFile: file})) || []
    };
  };

  const handleSubmit = (values: TemplateFormValues) => {
    const buttons = getButtons(values);
    onSubmit({...values, buttons});
  };

  const initialValues = getInitialValues();

  const validationSchema = yup
    .object()
    .shape({title: yup.string().trim().required()});

  return (
    <Popup isActive={isActive} onClick={() => setIsActive(false)} width={408}>
      {initialValues ? (
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validateOnBlur={false}
          validationSchema={validationSchema}
        >
          <Form>
            <PopupWrapper>
              <Wrapper direction="column" gap={16}>
                <Title.H3>{t`Template`}</Title.H3>
                <TitleField />
                <Message />
                <Attachs />

                {process.env.REACT_APP_ENV != 'prod' &&
                  process.env.REACT_APP_ENV != 'rocket' && <Buttons />}
              </Wrapper>
            </PopupWrapper>

            <PopupFooter>
              <Wrapper gap={12}>
                <SaveButton>{t`Save`}</SaveButton>

                <Button color="fade" onClick={() => setIsActive(false)}>
                  {t`Close`}
                </Button>
              </Wrapper>
            </PopupFooter>
          </Form>
        </Formik>
      ) : (
        <>
          <PopupWrapper>
            <Title.H3 style={{marginBottom: 16}}>{t`Template`}</Title.H3>
            <Preloader />
          </PopupWrapper>

          <PopupFooter>
            <Button color="fade" onClick={() => setIsActive(false)}>
              {t`Close`}
            </Button>
          </PopupFooter>
        </>
      )}
    </Popup>
  );
};

export {type TemplateFormValues};
export default TemplateForm;

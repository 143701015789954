import React from 'react';
import 'react-circular-progressbar/dist/styles.css';
import {buildStyles, CircularProgressbar} from 'react-circular-progressbar';

import {DocIcon, Media, ProgressBar, Text, Wrapper} from 'components';
import {useChatFooterContext} from '../../../../../chat-footer-context';
import * as AppEnv from 'app-env';

import FileSize from './file-size/file-size';

export interface AttachPreview {
  file?: File;
  isValid: boolean;
  src: string;
}

interface AttachPreviewParams {
  attach: AppEnv.Attach;
  index: number;
}

const AttachPreview = ({attach, index}: AttachPreviewParams) => {
  const {progress} = useChatFooterContext();
  const fileName = attach.file?.name || attach.title;

  const isMedia =
    attach.type.includes('image') || attach.type.includes('video');

  return (
    <>
      {isMedia ? (
        <Wrapper direction="column" gap={8}>
          <div className="preview">
            <Media
              isAutoPlay
              isPreview
              src={attach.src}
              type={attach.type.includes('video') ? 'video' : undefined}
            />

            {typeof progress[index] == 'number' && (
              <div className="preview__progress-bar">
                <CircularProgressbar
                  styles={buildStyles({
                    pathColor: '#fff',
                    pathTransitionDuration: 0.3,
                    trailColor: 'rgb(255, 255, 255, .4)'
                  })}
                  value={progress[index]}
                />
              </div>
            )}
          </div>

          <FileSize attach={attach} />
        </Wrapper>
      ) : (
        <Wrapper direction="column" gap={12}>
          <Wrapper
            alignItems="center"
            flexWrap="nowrap"
            gap={12}
            style={{width: '100%'}}
          >
            <DocIcon fileName={fileName} />

            <Wrapper direction="column" gap={2} style={{minWidth: 0}}>
              <Text fontWeight={600} isNoWrap size={16}>
                {fileName}
              </Text>

              <FileSize attach={attach} />
            </Wrapper>
          </Wrapper>

          {typeof progress[index] == 'number' && (
            <ProgressBar isAlt percent={progress[index]} width="100%" />
          )}
        </Wrapper>
      )}
    </>
  );
};

export default AttachPreview;

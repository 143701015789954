import React, {useEffect, useRef, useState} from 'react';
import {useScrollClassName} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';
import cn from 'classnames';
import Fuse from 'fuse.js';

import {getDialogId, getIsDialogUnread, sortDialogs} from 'common/actions';
import {Placeholder, Preloader} from 'components';
import {useDispatchContext} from '../../dispatch-context';
import {useGetFolderDialogList, useSlice} from 'common/hooks';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

import Dialog from './dialog/dialog';

interface DialogList {
  isUnreadFilter: boolean;
  labels: number[];
  managers: string[];
  search: string;
}

const getLabelDialogList = (
  dialogList: AppEnv.Dialog[],
  labelList: AppEnv.Label[],
  labels: number[]
) => {
  const ls = labelList.filter(label => labels.includes(label.id));

  const fusedDialogList = dialogList.filter(dialog =>
    ls.some(label => label.chatId == getDialogId(dialog, true)?.toString())
  );

  return fusedDialogList;
};

const getManagerDialogList = (
  dialogList: AppEnv.Dialog[],
  managers: string[]
) => {
  const ns = dialogList.filter(dialog => {
    if (dialog.version != 'chat') return false;

    return dialog.managers?.some(dialogManager =>
      managers.some(manager => manager == dialogManager.email)
    );
  });

  return ns;
};

const DialogList = ({isUnreadFilter, labels, managers, search}: DialogList) => {
  const {labelList, user} = useWorkspaceContext();
  const {dialogList, folder, instance} = useDispatchContext();

  const [fusedDialogList, setFusedDialogList] = useState<AppEnv.Dialog[]>();

  const {t} = useTranslation();
  const getFolderDialogList = useGetFolderDialogList();
  const ref = useRef<HTMLDivElement>(null);
  const [slice, setSlice] = useSlice(fusedDialogList, ref);

  useEffect(() => {
    let newFusedDialogList = getFolderDialogList(dialogList, folder);

    if (labelList && labels.length > 0)
      newFusedDialogList = getLabelDialogList(
        newFusedDialogList,
        labelList,
        labels
      );

    if (managers.length > 0)
      newFusedDialogList = getManagerDialogList(newFusedDialogList, managers);

    if (isUnreadFilter)
      newFusedDialogList = newFusedDialogList.filter(item =>
        getIsDialogUnread(instance, item)
      );

    const fuse = new Fuse(newFusedDialogList, {
      keys: ['id.user', 'id', 'manager.email', 'manager.name', 'name'],
      threshold: 0.25
    });

    if (search) newFusedDialogList = fuse.search(search).map(item => item.item);
    newFusedDialogList.sort(sortDialogs);
    setFusedDialogList(newFusedDialogList);
  }, [
    dialogList,
    folder,
    instance,
    isUnreadFilter,
    labels,
    managers,
    search,
    user
  ]);

  useEffect(() => {
    if (fusedDialogList?.length) ref.current?.scrollTo(0, 0);
  }, [fusedDialogList?.length]);

  useEffect(() => {
    setSlice(16);
  }, [instance.id]);

  const scrollClassName = useScrollClassName();

  const isLoaded = !!(dialogList && fusedDialogList);

  return (
    <div
      ref={ref}
      className={cn('dialogs__list', {[scrollClassName]: scrollClassName})}
    >
      {isLoaded ? (
        fusedDialogList.length ? (
          fusedDialogList
            .slice(0, slice)
            .map(item => (
              <Dialog
                key={item.version == 'whatcrm' ? item.id._serialized : item.id}
                dialog={item}
              />
            ))
        ) : (
          <Placeholder title={t`No chats`} isMaxHeight />
        )
      ) : (
        <Preloader isMaxHeight />
      )}
    </div>
  );
};

export default DialogList;

import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import upd from 'immutability-helper';

import {Confirm} from 'components';
import {getDialogId} from 'common/actions';
import {ProfileListItem} from '../components';
import {useAppContext} from 'app-context';
import {useChatContext} from '../../../chat-context';
import {useDispatchContext} from 'pages/workspace/pages/messenger/dispatch/dispatch-context';
import {useRequest} from 'common/hooks';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';

const Blacklist = () => {
  const {isIframe} = useAppContext();
  const {setUser, user} = useWorkspaceContext();
  const {instance} = useDispatchContext();
  const {dialog} = useChatContext();

  const [isActive, setIsActive] = useState(false);

  const {postBlacklistCreate} = useRequest();
  const {t} = useTranslation();
  const navigate = useNavigate();

  const blacklist = async () => {
    const {integration} = user;

    const id = getDialogId(dialog);
    if (!id) return true;

    const res = await postBlacklistCreate(integration.id, id);
    if (!res) return true;

    navigate(`/messenger/${instance.id}`, {replace: isIframe});
    setUser(prevValue => upd(prevValue, {blacklist: {$push: [res]}}));

    return true;
  };

  const handleClick = (res: boolean) => {
    if (res) return blacklist();
    setIsActive(false);
  };

  const {roles} = user.manager;

  return (
    <>
      {roles.dialogs_blacklist != false && (
        <>
          <div className="profile__list">
            <ProfileListItem
              onClick={() => setIsActive(true)}
              type="block"
              value={t`Block`}
            />
          </div>

          <Confirm
            isActive={isActive}
            onClick={handleClick}
            title={t`Block this user?`}
          />
        </>
      )}
    </>
  );
};

export default Blacklist;

import React, {useContext} from 'react';
import * as AppEnv from 'app-env';

interface StaffContext {
  roleList: AppEnv.Role[] | undefined;
  setRoleList: AppEnv.SetState<AppEnv.Role[] | undefined>;
}

const StaffContext = React.createContext<StaffContext>({} as StaffContext);

export const useStaffContext = () => useContext(StaffContext);
export default StaffContext;

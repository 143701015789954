import React from 'react';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {Input, Text} from 'components';

const Website = () => {
  const [field, , helpers] = useField<string>('website');
  const {t} = useTranslation();

  return (
    <div>
      <Text size={16} style={{marginBottom: 8}}>
        {t`Online chat is only accessible at this address`}
      </Text>

      <Input
        {...field}
        onChange={helpers.setValue}
        placeholder={t`Website address`}
        type="url"
      />
    </div>
  );
};

export default Website;

import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {
  Placeholder,
  Preloader,
  Table,
  TableBody,
  TableData,
  TableHead
} from 'components';
import {useDomainFilter} from '../hooks';
import {useRequest} from 'common/hooks';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

import Subscription from './subscription/subscription';

const Subscriptions = () => {
  const {user} = useWorkspaceContext();

  const [subscriptionList, setSubscriptionList] =
    useState<AppEnv.Subscription[]>();

  const {domainFilter, fusedList, isDomain} = useDomainFilter(subscriptionList);
  const {fetchSubscriptions} = useRequest();
  const {t} = useTranslation();

  const getPaymentList = async () => {
    const {integration} = user;
    const res = await fetchSubscriptions(integration.id);
    setSubscriptionList(res || []);
  };

  useEffect(() => {
    document.title = `${t`Subscriptions`} - Whatcrm Web`;
  }, []);

  useEffect(() => {
    getPaymentList();
  }, []);

  const {roles} = user.manager;

  return (
    <>
      {fusedList ? (
        <>
          {domainFilter}

          {fusedList.length ? (
            <Table>
              <TableHead>
                <TableData>
                  {t`Start date`}
                  {` (UTC+3)`}
                </TableData>

                <TableData>{t`Rate plan`}</TableData>
                <TableData>{t`Period`}</TableData>
                <TableData>{t`Payment gateway`}</TableData>
                <TableData>{t`Number of services`}</TableData>
                {isDomain && <TableData>{t`Domain`}</TableData>}
                {roles.billings_write != false && <TableData />}
              </TableHead>

              <TableBody>
                {fusedList.map((subscription, i) => (
                  <Subscription
                    isDomain={isDomain}
                    key={i}
                    setSubscriptionList={setSubscriptionList}
                    subscription={subscription}
                  />
                ))}
              </TableBody>
            </Table>
          ) : (
            <Placeholder isMaxHeight title={t`No subscriptions`} />
          )}
        </>
      ) : (
        <Preloader isMaxHeight />
      )}
    </>
  );
};

export default Subscriptions;

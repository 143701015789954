import React from 'react';
import {useTranslation} from 'react-i18next';

import {ContextMenuOption, IconsAlt} from 'components';
import {getIsLimited} from 'common/actions';
import {useDispatchContext} from 'pages/workspace/pages/messenger/dispatch/dispatch-context';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

const useForward = (
  msg: AppEnv.Message,
  setIsForwardActive: AppEnv.SetState<boolean>
): ContextMenuOption | null => {
  const {user} = useWorkspaceContext();
  const {instance} = useDispatchContext();

  const {t} = useTranslation();

  const {roles} = user.manager;

  const isLimited = getIsLimited(instance);

  if (!('_data' in msg)) return null;
  const {_data} = msg;

  if (_data.isViewOnce) return null;
  else if (isLimited) return null;
  else if (roles.messages_forward == false) return null;

  const forward: ContextMenuOption = {
    icon: <IconsAlt.Forward />,
    label: t`Forward`,
    onClick: () => setIsForwardActive(true)
  };

  return forward;
};

export default useForward;

import {parsePhoneNumber} from 'libphonenumber-js';

import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

const useGetValidPhone = () => {
  const {fetchGetNumberId} = useRequest();

  const getNumberId = async (
    instance: AppEnv.Instance,
    phoneNumber: string
  ) => {
    const res = await fetchGetNumberId(instance.chat_key, phoneNumber);
    return res?.user || phoneNumber;
  };

  const getValidPhone = async (
    instance: AppEnv.Instance,
    phoneNumber: string
  ) => {
    if (instance.version != 'whatcrm') return phoneNumber;

    try {
      const parsed = parsePhoneNumber(`+${phoneNumber}`);

      if (!['AR', 'BR', 'CO', 'MX'].includes(parsed.country || ''))
        return phoneNumber;
    } catch {}

    return getNumberId(instance, phoneNumber);
  };

  return getValidPhone;
};

export default useGetValidPhone;

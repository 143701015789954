import React from 'react';
import {useTranslation} from 'react-i18next';

import {ContextMenuOption, IconsAlt} from 'components';
import {getIsNewMessageAvailable} from 'common/actions';
import {useChatContext} from 'pages/workspace/pages/messenger/dispatch/chat-provider/chat/chat-context';
import {useDispatchContext} from 'pages/workspace/pages/messenger/dispatch/dispatch-context';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

import {getIsEditAvailable} from './actions';

const useEdit = (msg: AppEnv.Message): ContextMenuOption | null => {
  const {user} = useWorkspaceContext();
  const {instance} = useDispatchContext();
  const {dialog, setQuotedMsg} = useChatContext();

  const {t} = useTranslation();
  const {roles} = user.manager;

  if (roles.messages_edit == false) return null;
  else if (!('sender_id' in msg) && !('senderId' in msg) && !('_data' in msg))
    return null;

  const isEditAvailable = getIsEditAvailable(instance, dialog, msg);
  if (!isEditAvailable) return null;

  const isNewMessageAvailable = getIsNewMessageAvailable(instance, dialog);
  if (!isNewMessageAvailable) return null;

  const edit: ContextMenuOption = {
    icon: <IconsAlt.PencilSimple />,
    label: t`Edit`,
    onClick: () => setQuotedMsg({isEditing: true, message: msg})
  };

  return edit;
};

export default useEdit;

import React from 'react';
import {useTranslation} from 'react-i18next';
import cn from 'classnames';

import {Text, Wrapper} from 'components';

interface Placeholder {
  children?: React.ReactNode;
  isChat?: boolean;
  isDelay?: boolean;
  isMaxHeight?: boolean;
  text?: string;
  title?: string | null;
}

const Placeholder = ({
  children,
  isChat,
  isDelay,
  isMaxHeight,
  text,
  title
}: Placeholder) => {
  const {t} = useTranslation();

  return (
    <div
      className={cn('placeholder', {
        'placeholder_max-height': isMaxHeight,
        placeholder_delay: isDelay
      })}
    >
      <Wrapper
        direction="column"
        gap={16}
        style={{marginBottom: 32, maxWidth: 398}}
      >
        {title !== null && (
          <Text color="secondary" size={24} textAlign="center">
            {title || t`Nothing to show`}
          </Text>
        )}

        {text && (
          <Text color="light" textAlign="center" size={14}>
            {text}
          </Text>
        )}

        {children}
      </Wrapper>

      <div
        className={cn('placeholder__img', {placeholder__img_chat: isChat})}
      />
    </div>
  );
};

export default Placeholder;

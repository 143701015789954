import React from 'react';
import {Form, Formik} from 'formik';
import {formatPhoneNumber} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';

import {
  Button,
  PopupFooter,
  PopupWrapper,
  SaveButton,
  Text,
  Wrapper
} from 'components';
import {Label} from '../../components';
import {useInstanceContext} from '../../instance-context';
import {useRequest} from 'common/hooks';

import {Values} from './wait-code-env';
import Code from './code/code';

const WaitCode = () => {
  const {instance} = useInstanceContext();

  const {fetchCheckAuthenticationCode, fetchRequestQrCodeAuthentication} =
    useRequest();

  const {t} = useTranslation();

  const handleClick = async () =>
    fetchRequestQrCodeAuthentication(instance.chat_key);

  const submitForm = async (values: Values) => {
    await fetchCheckAuthenticationCode(instance.chat_key, values.code);
    return true;
  };

  const {codeInfo} = instance.condition || {};
  const initialValues: Values = {code: ''};

  const validationSchema = yup
    .object()
    .shape({code: yup.string().trim().required()});

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitForm}
      validateOnBlur={false}
      validationSchema={validationSchema}
    >
      <Form>
        <PopupWrapper>
          <Label style={{marginBottom: 8}} />

          <Text size={16} style={{marginBottom: 4}}>
            {t`Enter the login code`}
          </Text>

          <Text size={14} style={{marginBottom: 16}}>
            {t`Telegram has sent the code to your number`}

            {codeInfo?.phoneNumber
              ? ` ${formatPhoneNumber(codeInfo.phoneNumber)}`
              : `.`}
          </Text>

          <Code submitForm={submitForm} />
        </PopupWrapper>

        <PopupFooter>
          <Wrapper gap={12}>
            <SaveButton>{t`Continue`}</SaveButton>

            <Button color="fade" onClick={handleClick}>
              {t`Close`}
            </Button>
          </Wrapper>
        </PopupFooter>
      </Form>
    </Formik>
  );
};

export default WaitCode;

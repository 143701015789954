import React from 'react';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';

import AvitoWs from './avito-ws/avito-ws';
import ChatWs from './chat-ws/chat-ws';
import EventsWs from './events-ws/events-ws';
import WhatcrmWs from './whatcrm-ws/whatcrm-ws';

const WebSocket = () => {
  const {activeInstances} = useWorkspaceContext();

  const isAvito = activeInstances.some(instance => instance.version == 'avito');
  const isChat = activeInstances.some(instance => instance.version == 'chat');

  const isWhatcrm = activeInstances.some(instance =>
    ['telegram', 'whatcrm'].includes(instance.version)
  );

  return (
    <>
      {activeInstances.length > 0 && <EventsWs />}
      {isAvito && <AvitoWs />}
      {isChat && <ChatWs />}
      {isWhatcrm && <WhatcrmWs />}
    </>
  );
};

export default WebSocket;

import React from 'react';
import upd from 'immutability-helper';

import {Button, Icons, InputAlt, Wrapper} from 'components';
import {useChatContext} from '../../../../chat-context';
import * as AppEnv from 'app-env';

interface Social {
  index: number;
  social: AppEnv.ChatWidgetSocial;
}

const icons = {
  facebook: <Icons.Facebook />,
  instagram: <Icons.Instagram size={32} />,
  linkedin: <Icons.LinkedIn />,
  telegram: <Icons.Telegram />,
  twitter: <Icons.Twitter />,
  whatcrm: <Icons.WhatsApp />
};

const placeholders = {
  facebook: 'https://facebook.com/',
  instagram: 'https://instagram.com/',
  linkedin: 'https://linkedin.com/',
  telegram: 'https://t.me/',
  twitter: 'https://x.com/',
  whatcrm: 'https://wa.me/'
};

const Social = ({index, social}: Social) => {
  const {setWidget} = useChatContext();

  const handleChange = (value: string) =>
    setWidget(prevValue =>
      upd(prevValue, {socials: {[index]: {url: {$set: value}}}})
    );

  const handleClick = () =>
    setWidget(prevValue => upd(prevValue, {socials: {$splice: [[index, 1]]}}));

  return (
    <Wrapper alignItems="center" flexWrap="nowrap" gap={8}>
      {icons[social.version]}

      <InputAlt
        name={`socials-${index}`}
        onChange={handleChange}
        placeholder={placeholders[social.version]}
        value={social?.url}
      />

      <Button color="transparent" onClick={handleClick}>
        <Icons.TrashSimple color="light" size={24} />
      </Button>
    </Wrapper>
  );
};

export default Social;

import React from 'react';
import {Form, Formik} from 'formik';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';
import upd from 'immutability-helper';

import {
  Button,
  Popup,
  PopupFooter,
  PopupWrapper,
  SaveButton,
  Title,
  Wrapper
} from 'components';
import {getDialogId} from 'common/actions';
import {useRequest} from 'common/hooks';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

import Color from './color/color';
import Tag from './tag/tag';

interface LabelForm {
  dialog: AppEnv.Dialog;
  isActive: boolean;
  setIsActive: AppEnv.SetState<boolean>;
}

interface Values {
  color: string;
  tag: string;
}

const initialValues: Values = {color: '#65bbe5', tag: ''};

const validationSchema = yup
  .object()
  .shape({tag: yup.string().trim().required()});

const LabelForm = ({dialog, isActive, setIsActive}: LabelForm) => {
  const {setLabelList, user} = useWorkspaceContext();

  const {postLabelsCreate} = useRequest();
  const {t} = useTranslation();

  const handleSubmit = async (values: Values) => {
    const {integration} = user;
    const chatId = getDialogId(dialog, true)?.toString();
    if (!chatId) return;

    const data = {...values, chatId};
    const res = await postLabelsCreate(integration.id, data);
    if (!res) return;

    setIsActive(false);
    setLabelList(prevValue => upd(prevValue, {$push: [res]}));
  };

  return (
    <Popup isActive={isActive} onClick={() => setIsActive(false)} width={408}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validationSchema={validationSchema}
      >
        <Form>
          <PopupWrapper>
            <Title.H3 style={{marginBottom: 16}}>{t`New tag`}</Title.H3>
            <Tag />
            <Color />
          </PopupWrapper>

          <PopupFooter>
            <Wrapper gap={12}>
              <SaveButton>{t`Add`}</SaveButton>

              <Button color="fade" onClick={() => setIsActive(false)}>
                {t`Close`}
              </Button>
            </Wrapper>
          </PopupFooter>
        </Form>
      </Formik>
    </Popup>
  );
};

export default LabelForm;

import React from 'react';
import upd from 'immutability-helper';

import {Checkbox, Wrapper} from 'components';
import {useInstanceOptions} from 'common/hooks';
import {useTariffsContext} from '../../../tariffs-context';
import * as AppEnv from 'app-env';

interface InstanceId {
  instanceId: number[];
  setInstanceId: AppEnv.SetState<number[]>;
}

const InstanceId = ({instanceId, setInstanceId}: InstanceId) => {
  const {instances} = useTariffsContext();
  const options = useInstanceOptions(Object.values(instances || {}));

  const handleChange = (value: boolean, instanceId: number) =>
    setInstanceId(prevValue => {
      if (value) return upd(prevValue, {$push: [instanceId]});

      const index = prevValue.indexOf(instanceId);
      if (index < 0) return prevValue;

      return upd(prevValue, {$splice: [[index, 1]]});
    });

  return (
    <Wrapper direction="column" gap={8}>
      {options.map((option, i) => (
        <Checkbox
          {...option}
          key={option.value}
          name={`instance_id-${i}`}
          onChange={value => handleChange(value, option.value)}
          value={instanceId.includes(option.value)}
        />
      ))}
    </Wrapper>
  );
};

export default InstanceId;

import {isolateNumbers} from 'whatcrm-core';
import upd from 'immutability-helper';

import {getDialogId} from 'common/actions';
import {useChatContext} from '../../../chat-context';
import {useRequest} from 'common/hooks';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

const useUpdateContact = () => {
  const {contactList, setContactList, user} = useWorkspaceContext();
  const {contact, dialog} = useChatContext();

  const {postContactsCreate, postContactsUpdate} = useRequest();

  const postContact = async (values: Partial<AppEnv.ContactShape>) => {
    const {integration} = user;
    const chatId = getDialogId(dialog, true)?.toString();

    const res = await postContactsCreate(integration.id, {...values, chatId});
    if (!res) return false;

    setContactList(prevValue => {
      if (!prevValue) return prevValue;
      return upd(prevValue, {$push: [res]});
    });

    return true;
  };

  const putContact = async (
    id: number,
    values: Partial<AppEnv.ContactShape>
  ) => {
    const {integration} = user;

    const res = await postContactsUpdate(integration.id, id, values);
    if (!res) return false;

    setContactList(prevValue => {
      const index = prevValue?.findIndex(item => item.id == res.id);
      if (index == undefined || index < 0) return prevValue;

      return upd(prevValue, {[index]: {$set: res}});
    });

    return true;
  };

  const updateContact = async (values: Partial<AppEnv.ContactShape>) => {
    const phones = JSON.stringify(
      JSON.parse(values.phones || '[]')
        .map((phone: string) => isolateNumbers(phone))
        .filter((phone: string) => phone)
    );

    const username = values.username?.replaceAll('@', '');
    const newValues = {...values, phones, username};

    if (!contactList) return false;
    else if (!contact) return postContact(newValues);

    return putContact(contact.id, newValues);
  };

  return updateContact;
};

export default useUpdateContact;

import React, {useRef, useState} from 'react';
import {Anchor} from 'components';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {
  getAvitoProductImage,
  getDialogId,
  getDialogProfileImage
} from 'common/actions';
import {Icons, ProfileImage, Text} from 'components';
import {useAppContext} from 'app-context';
import {useChatContext} from '../chat-context';
import {useDispatchContext} from '../../../dispatch-context';
import {useGetDialogName} from 'pages/workspace/hooks';
import {useIsOnline} from '../hooks';

import ContextMenuProvider from './context-menu-provider/context-menu-provider';
import Profile from './profile/profile';

const ChatHeader = () => {
  const {isIframe} = useAppContext();
  const {instance} = useDispatchContext();
  const {dialog, telegramUser} = useChatContext();

  const [isProfileActive, setIsProfileActive] = useState(false);

  const {t} = useTranslation();
  const getDialogName = useGetDialogName();
  const isOnline = useIsOnline();
  const linkRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  const handleClick: React.MouseEventHandler<HTMLDivElement> = e => {
    const target = e.target as HTMLDivElement;

    if (linkRef.current?.contains(target)) return;
    else if (menuRef.current?.contains(target)) return;

    setIsProfileActive(true);
  };

  const name = getDialogName(instance, dialog);

  const src =
    dialog.version == 'avito'
      ? getAvitoProductImage(dialog)
      : getDialogProfileImage(instance, dialog);

  return (
    <>
      <div className="chat-header">
        <div className="chat-header__user" onClick={handleClick}>
          <div className="chat-header__link" ref={linkRef}>
            <Link replace={isIframe} to={`/messenger/${instance.id}`}>
              <Anchor fontWeight={500}>
                <Icons.ArrowLeft />
                {t`Back`}
              </Anchor>
            </Link>
          </div>

          <ProfileImage
            id={getDialogId(dialog)}
            isOnline={isOnline}
            name={name}
            size={36}
            src={src}
          />

          <div>
            <Text className="chat-header__title" fontWeight={500} size={16}>
              {name}
            </Text>

            {isOnline ? (
              <Text color="green" fontWeight={500} size={14}>
                {t`online`}
              </Text>
            ) : (
              telegramUser && (
                <Text color="light" fontWeight={500} size={14}>
                  {telegramUser.status._ == 'userStatusRecently'
                    ? t`last seen recently`
                    : t`offline`}
                </Text>
              )
            )}
          </div>
        </div>

        <ContextMenuProvider
          ref={menuRef}
          setIsProfileActive={setIsProfileActive}
        />
      </div>

      {isProfileActive && <Profile setIsProfileActive={setIsProfileActive} />}
    </>
  );
};

export default ChatHeader;

import React, {RefObject, forwardRef, useState} from 'react';

import {Button, ContextMenu, Icons} from 'components';
import {ContactForm, DeleteDialog, Wallpapers} from '../components';
import * as AppEnv from 'app-env';

import {useOptions} from './hooks';

interface ContextMenuProvider {
  setIsProfileActive: AppEnv.SetState<boolean>;
}

const ContextMenuProvider = forwardRef<HTMLDivElement, ContextMenuProvider>(
  ({setIsProfileActive}, ref) => {
    const [isContactFormActive, setIsContactFormActive] = useState(false);
    const [isContextMenuActive, setIsContextMenuActive] = useState(false);
    const [isDeleteDialogActive, setIsDeleteDialogActive] = useState(false);
    const [isWallpapersActive, setIsWallpapersActive] = useState(false);

    const options = useOptions({
      setIsContactFormActive,
      setIsDeleteDialogActive,
      setIsProfileActive,
      setIsWallpapersActive
    });

    return (
      <div className="chat-header__menu" ref={ref}>
        <Button
          color="transparent"
          onClick={() => setIsContextMenuActive(prevValue => !prevValue)}
        >
          <Icons.Ellipsis />
        </Button>

        <DeleteDialog
          isActive={isDeleteDialogActive}
          setIsActive={setIsDeleteDialogActive}
        />

        <ContactForm
          isActive={isContactFormActive}
          setIsActive={setIsContactFormActive}
        />

        <ContextMenu
          options={options}
          triggerRef={ref as RefObject<HTMLDivElement>}
          isActive={isContextMenuActive}
          isCustomPosition
          setIsActive={setIsContextMenuActive}
        />

        <Wallpapers
          isActive={isWallpapersActive}
          setIsActive={setIsWallpapersActive}
        />
      </div>
    );
  }
);

ContextMenuProvider.displayName = 'ContextMenuProvider';
export default ContextMenuProvider;

import * as AppEnv from 'app-env';

interface UrlParams {
  chat_id?: string;
  code?: string;
  crm?: string;
  domain?: string;
  email?: string;
  error_text?: string;
  instance_id?: string;
  is_admin?: '0' | '1';
  key?: string;
  phone_numbers?: string;
  ref?: string;
  theme?: string;
  user?: string;
  usernames?: string;
  version?: AppEnv.InstanceVersion | 'whatsapp';
}

const getUrlParams = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const urlParams: UrlParams = Object.fromEntries(urlSearchParams.entries());

  return urlParams;
};

export default getUrlParams;

import React from 'react';
import {formatPhoneNumber} from 'whatcrm-core';

import {Icons} from 'components';
import {SocialOption} from '../../socials-env';
import {useChatContext} from 'pages/workspace/pages/widgets/chat/chat-context';
import {useGetInstanceLabel} from 'common/hooks';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';

const useInstanceOptions = () => {
  const {activeInstances} = useWorkspaceContext();
  const {widget} = useChatContext();

  const getInstanceLabel = useGetInstanceLabel();

  const instanceOptions = activeInstances.reduce((acc, instance) => {
    const comment = formatPhoneNumber(instance.phone);

    const isDisabled = widget.socials.some(
      social => social.version == instance.version
    );

    const label = getInstanceLabel(instance);
    const value = instance.id.toString();

    if (instance.version == 'telegram')
      acc.push({
        comment,
        icon: <Icons.Telegram />,
        isDisabled,
        label,
        value,
        version: 'telegram'
      });
    else if (instance.version == 'whatcrm')
      acc.push({
        comment,
        icon: <Icons.WhatsApp />,
        isDisabled,
        label,
        value,
        version: 'whatcrm'
      });

    return acc;
  }, [] as SocialOption[]);

  return instanceOptions;
};

export default useInstanceOptions;

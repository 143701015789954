import React from 'react';
import cn from 'classnames';

import {Text} from 'components';

export interface ChipsOption<T> {
  comment?: string;
  icon: React.ReactNode;
  isDisabled?: boolean;
  label: string;
  value: T;
}

interface Chips<T> {
  onChange: (arg: T) => void;
  options: ChipsOption<T>[];
  value: T | undefined;
}

const Chips = <T extends string>({onChange, options, value}: Chips<T>) => (
  <div className="chips">
    {options.map(option => (
      <button
        className={cn('chips__item', {
          chips__item_active: option.value == value
        })}
        key={option.value}
        type="button"
        disabled={option.isDisabled}
        onClick={() => onChange(option.value)}
      >
        {option.icon}

        {option.comment && (
          <Text color="secondary" size={12}>
            {option.comment}
          </Text>
        )}

        <Text fontWeight={500} size={16}>
          {option.label}
        </Text>
      </button>
    ))}
  </div>
);

export default Chips;

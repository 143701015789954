import React from 'react';
import cn from 'classnames';

import {Button, Icons} from 'components';
import {useAccordionContext} from '../accordion-context';

interface Accordion {
  children: React.ReactNode;
}

const AccordionSummary = ({children}: Accordion) => {
  const {isActive, onDelete, setIsActive} = useAccordionContext();

  return (
    <div
      className={cn('accordion__summary', {
        accordion__summary_active: isActive
      })}
    >
      <div style={{minWidth: 0}}>{children}</div>

      <div className="accordion__button-group">
        {onDelete && (
          <Button color="transparent" onClick={onDelete}>
            <Icons.TrashSimple size={20} />
          </Button>
        )}

        <Button
          color="transparent"
          onClick={() => setIsActive(prevValue => !prevValue)}
        >
          <Icons.CaretDown color={isActive ? 'green' : undefined} size={20} />
        </Button>
      </div>
    </div>
  );
};

export default AccordionSummary;

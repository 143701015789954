import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {useScrollClassName} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';
import cn from 'classnames';

import {Anchor, Icons, Nav, NavOption, Title} from 'components';

import Appearance from './appearance/appearance';
import AsideFooter from './aside-footer/aside-footer';
import General from './general/general';

type Page = 'appearance' | 'general';

const Aside = () => {
  const [page, setPage] = useState<Page>('general');

  const {t} = useTranslation();
  const scrollClassName = useScrollClassName();

  const options: NavOption<Page>[] = [
    {label: t`General`, value: 'general'},
    {label: t`Appearance`, value: 'appearance'}
  ];

  return (
    <div className="aside">
      <div className="aside__header">
        <Link
          style={{display: 'inline-flex', width: 'fit-content'}}
          to="/widgets"
        >
          <Anchor fontWeight={500} style={{marginBottom: 16}}>
            <Icons.ArrowLeft />
            <span>{t`Back to widgets`}</span>
          </Anchor>
        </Link>

        <Title.H3 style={{marginBottom: 16}} isTextAlignCenter>
          {t`Widget settings`}
        </Title.H3>

        <Nav options={options} value={page} onChange={setPage} />
      </div>

      <div className={cn('aside__body', {[scrollClassName]: scrollClassName})}>
        {page == 'appearance' ? (
          <Appearance />
        ) : (
          page == 'general' && <General />
        )}
      </div>

      <AsideFooter />
    </div>
  );
};

export default Aside;

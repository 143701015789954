import React, {useRef, useState} from 'react';
import {useScrollClassName} from 'whatcrm-core';
import cn from 'classnames';
import Fuse from 'fuse.js';

import {Input} from 'components';
import Option, {InputAutocompleteOption} from './option/option';

interface InputAutocomplete {
  autoCapitalize?: 'off';
  autoFocus?: boolean;
  isValid?: boolean;
  name: string;
  onChange: (value: string) => void;
  onClick: (value: number) => void;
  options: InputAutocompleteOption[] | undefined;
  placeholder?: string;
  style?: React.CSSProperties;
  value: string;
}

const InputAutocomplete = ({
  autoCapitalize,
  autoFocus,
  isValid = true,
  name,
  onChange,
  onClick,
  options = [],
  placeholder,
  style,
  value
}: InputAutocomplete) => {
  const [isFocus, setIsFocus] = useState(false);

  const ref = useRef<HTMLDivElement>(null);
  const scrollClassName = useScrollClassName();

  const fuse = new Fuse(options, {keys: ['label'], threshold: 0.25});

  const fusedOptions = value
    ? fuse.search(value).map(item => item.item)
    : options;

  const isActive = isFocus && fusedOptions.length > 0;
  const rect = ref.current?.getBoundingClientRect();

  return (
    <div className="input-autocomplete" ref={ref} style={style}>
      <Input
        autoCapitalize={autoCapitalize}
        autoComplete="off"
        autoFocus={autoFocus}
        isValid={isValid}
        name={name}
        onBlur={() => setIsFocus(false)}
        onChange={onChange}
        onFocus={() => setIsFocus(true)}
        placeholder={placeholder}
        value={value}
      />

      <div
        className={cn('input-autocomplete__dropdown', {
          'input-autocomplete__dropdown_active': isActive,
          [scrollClassName]: scrollClassName
        })}
        style={{width: rect ? rect.width + 8 : undefined}}
      >
        {fusedOptions.map(option => (
          <Option
            key={option.value}
            onChange={onChange}
            onClick={onClick}
            option={option}
          />
        ))}
      </div>
    </div>
  );
};

export {type InputAutocompleteOption} from './option/option';
export default InputAutocomplete;

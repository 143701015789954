import React, {useRef, useState} from 'react';
import cn from 'classnames';

import {SelectDropdown} from '../components';
import {SelectOption} from '../select-env';

import SelectField from './select-field/select-field';

interface Select<T> {
  isValid?: boolean;
  onChange: (value: T) => void;
  options: SelectOption<T>[] | undefined;
  placeholder: string;
  style?: React.CSSProperties;
  value: T;
}

const Select = <T,>({
  isValid = true,
  onChange,
  options,
  placeholder,
  style,
  value
}: Select<T>) => {
  const [isActive, setIsActive] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const rect = ref.current?.getBoundingClientRect();
  const selected = options?.find(option => option.value == value);

  return (
    <div
      className={cn('select', {select_error: !isValid})}
      ref={ref}
      style={style}
    >
      <SelectField
        icon={selected?.icon}
        label={selected?.label}
        onClick={() => setIsActive(true)}
        placeholder={placeholder}
      />

      {rect && (
        <SelectDropdown
          isActive={isActive}
          left={rect.left - 4}
          onChange={onChange}
          options={options}
          setIsActive={setIsActive}
          top={rect.bottom + 8}
          value={value}
          width={rect.width + 8}
        />
      )}
    </div>
  );
};

export default Select;

import {useEffect} from 'react';

import {getIsDialogMuted, getIsDialogUnread} from 'common/actions';
import * as AppEnv from 'app-env';

const useFavicon = (user: AppEnv.User, dialogs: AppEnv.Dialogs | undefined) => {
  useEffect(() => {
    if (!dialogs) return;

    const isBadge = Object.entries(dialogs).some(([instanceId, dialogList]) =>
      dialogList.some((dialog: AppEnv.Dialog) => {
        const {instances} = user;
        const instance = instances[+instanceId];

        return !getIsDialogMuted(dialog) && getIsDialogUnread(instance, dialog);
      })
    );

    const favicon = document.querySelector(
      '#favicon'
    ) as HTMLLinkElement | null;

    if (!favicon) return;
    favicon.href = isBadge ? '/favicon_badge.ico' : '/favicon.ico';
  }, [dialogs, user]);
};

export default useFavicon;

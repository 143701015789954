import React from 'react';
import {Form, Formik} from 'formik';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';
import upd from 'immutability-helper';

import {
  Button,
  Popup,
  PopupFooter,
  PopupWrapper,
  SaveButton,
  Title,
  Wrapper
} from 'components';
import {useAppContext} from 'app-context';
import {useRequest} from 'common/hooks';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

import Crm from './crm/crm';
import Domain from './domain/domain';

interface PartnerAdd {
  isPartnerAddActive: boolean;
  setIsPartnerActive: AppEnv.SetState<boolean>;
  setPartnerList: AppEnv.SetState<AppEnv.Partner[] | undefined>;
}

interface Values {
  crm: AppEnv.Crm | undefined;
  domain: string;
}

const validationSchema = yup.object().shape({
  crm: yup.string().trim().required(),
  domain: yup.string().trim().required().min(6)
});

const PartnerAdd = ({
  isPartnerAddActive,
  setIsPartnerActive,
  setPartnerList
}: PartnerAdd) => {
  const {setNotification} = useAppContext();
  const {user} = useWorkspaceContext();

  const {postPartnersAdd} = useRequest();
  const {t} = useTranslation();

  const handleSubmit = async (values: Values) => {
    if (!values.crm) return true;
    const {integration} = user;

    const res = await postPartnersAdd(integration.id, {
      ...values,
      crm: values.crm
    });

    if (!res) return true;
    const {client} = res;

    setIsPartnerActive(false);
    setNotification({text: t`Client has been added.`, title: client.domain});

    setPartnerList(prevValue => {
      if (prevValue) return upd(prevValue, {$unshift: [client]});
      return [client];
    });

    return true;
  };

  return (
    <Popup
      isActive={isPartnerAddActive}
      onClick={() => setIsPartnerActive(false)}
      width={408}
    >
      <Formik
        initialValues={{crm: undefined, domain: ''}}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validationSchema={validationSchema}
      >
        <Form>
          <PopupWrapper>
            <Title.H3 style={{marginBottom: 16}}>{t`Add client`}</Title.H3>
            <Crm />
            <Domain />
          </PopupWrapper>

          <PopupFooter>
            <Wrapper gap={12}>
              <SaveButton>{t`Add`}</SaveButton>

              <Button color="fade" onClick={() => setIsPartnerActive(false)}>
                {t`Close`}
              </Button>
            </Wrapper>
          </PopupFooter>
        </Form>
      </Formik>
    </Popup>
  );
};

export default PartnerAdd;

import {useTranslation} from 'react-i18next';
import {useCopyToClipboard} from 'whatcrm-core';

import {getChatUser} from 'common/actions';
import {ProfileListItemParams} from '../../../../components';
import {useAppContext} from 'app-context';
import {useChatContext} from '../../../../../../chat-context';
import {useDispatchContext} from 'pages/workspace/pages/messenger/dispatch/dispatch-context';

const useEmail = (): ProfileListItemParams | null => {
  const {setNotification} = useAppContext();
  const {instance} = useDispatchContext();
  const {dialog} = useChatContext();

  const {t} = useTranslation();
  const copyToClipboard = useCopyToClipboard();

  const handleClick = async (str: string) => {
    const res = await copyToClipboard(str);
    setNotification({title: res.message});
  };

  if (dialog.version != 'chat') return null;

  const user = getChatUser(instance, dialog);
  if (!user?.email) return null;

  const email: ProfileListItemParams = {
    label: t`email`,
    onClick: () => handleClick(user.email || ''),
    type: 'copy',
    value: user.email
  };

  return email;
};

export default useEmail;

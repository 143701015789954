import React from 'react';

import {getDialogId} from 'common/actions';
import {TagList} from 'components';
import {useDialogContext} from '../dialog-context';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';

const Labels = () => {
  const {labelList} = useWorkspaceContext();
  const {dialog} = useDialogContext();

  const labels =
    labelList?.filter(
      label => label.chatId == getDialogId(dialog, true)?.toString()
    ) || [];

  return (
    <>
      {labels.length > 0 && (
        <div className="dialog__labels">
          <TagList labels={labels} />
        </div>
      )}
    </>
  );
};

export default Labels;

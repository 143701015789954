import React from 'react';
import {useTranslation} from 'react-i18next';
import upd from 'immutability-helper';

import {ChipsAlt, ChipsAltOption, Title} from 'components';
import {useChatContext} from '../../../chat-context';
import * as AppEnv from 'app-env';

const Theme = () => {
  const {setWidget, widget} = useChatContext();
  const {t} = useTranslation();

  const handleChange = (value: AppEnv.ChatWidgetTheme) =>
    setWidget(prevValue => upd(prevValue, {theme: {$set: value}}));

  const options: ChipsAltOption<AppEnv.ChatWidgetTheme>[] = [
    {
      img: '/static/widget/theme/light.svg',
      label: t`Light`,
      value: 'light'
    },
    {
      img: '/static/widget/theme/dark.svg',
      label: t`Dark`,
      value: 'dark'
    }
  ];

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Theme`}</Title.H3>

      <ChipsAlt
        onChange={handleChange}
        options={options}
        value={widget.theme}
      />
    </div>
  );
};

export default Theme;

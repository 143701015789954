import React from 'react';
import {useTranslation} from 'react-i18next';
import upd from 'immutability-helper';

import {Switch} from 'components';
import {useChatContext} from '../../../chat-context';

const IsPulse = () => {
  const {setWidget, widget} = useChatContext();
  const {t} = useTranslation();

  const handleChange = (value: boolean) =>
    setWidget(prevValue => upd(prevValue, {is_pulse: {$set: value}}));

  return (
    <Switch
      label={t`Button pulsation`}
      onChange={handleChange}
      value={widget.is_pulse}
    />
  );
};

export default IsPulse;

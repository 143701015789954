import {useDispatchContext} from 'pages/workspace/pages/messenger/dispatch/dispatch-context';
import * as AppEnv from 'app-env';

const useDialog = (participant: AppEnv.WhatsAppParticipant) => {
  const {dialogList} = useDispatchContext();

  const dialog = dialogList.find(dialog => {
    const dialogId = dialog.id;
    const participantId = participant.id;

    if (typeof dialogId != 'object') return false;
    return dialogId._serialized == participantId._serialized;
  });

  return dialog;
};

export default useDialog;

import React from 'react';
import {useCopyToClipboard} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {
  Button,
  Icons,
  PopupFooter,
  PopupWrapper,
  Prop,
  PropList,
  Text,
  Wrapper
} from 'components';
import {Label} from '../../../../components';
import {useAppContext} from 'app-context';
import {useInstanceContext} from '../../../../instance-context';
import * as AppEnv from 'app-env';

interface FirstStep {
  setIsFirstStep: AppEnv.SetState<boolean>;
}

const FirstStep = ({setIsFirstStep}: FirstStep) => {
  const {setNotification} = useAppContext();
  const {instance} = useInstanceContext();

  const {t} = useTranslation();
  const copyToClipboard = useCopyToClipboard();

  const handleClick = async (str: string) => {
    const res = await copyToClipboard(str);
    setNotification({title: res.message});
  };

  return (
    <>
      <PopupWrapper>
        <Label style={{marginBottom: 8}} />

        <Text size={16} style={{marginBottom: 16}}>
          {
            'Скопируйте ключ и адрес для приема вебхуков и следуйте инструкциям.'
          }
        </Text>

        <PropList>
          <Prop>
            <Wrapper
              alignItems="flex-end"
              flexWrap="nowrap"
              gap={16}
              justifyContent="space-between"
            >
              <div style={{minWidth: 0}}>
                <Text size={12}>{t`Key`}</Text>

                <Text fontWeight={600} isNoWrap size={16}>
                  {instance.chat_key}
                </Text>
              </div>

              <Button
                color="transparent"
                onClick={() => handleClick(instance.chat_key)}
                tooltip={t`Copy`}
              >
                <Icons.Files size={20} />
              </Button>
            </Wrapper>
          </Prop>

          <Prop>
            <Wrapper
              alignItems="flex-end"
              flexWrap="nowrap"
              gap={16}
              justifyContent="space-between"
            >
              <div style={{minWidth: 0}}>
                <Text size={12}>{t`Webhook endpoint`}</Text>

                <Text fontWeight={600} isNoWrap size={16}>
                  {'https://dev.whatcrm.net/whatsapp/cloud'}
                </Text>
              </div>

              <Button
                color="transparent"
                onClick={() =>
                  handleClick('https://dev.whatcrm.net/whatsapp/cloud')
                }
                tooltip={t`Copy`}
              >
                <Icons.Files size={20} />
              </Button>
            </Wrapper>
          </Prop>
        </PropList>
      </PopupWrapper>

      <PopupFooter>
        <Button onClick={() => setIsFirstStep(false)}>{t`Continue`}</Button>
      </PopupFooter>
    </>
  );
};

export default FirstStep;

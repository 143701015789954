import React from 'react';
import {useTranslation} from 'react-i18next';
import upd from 'immutability-helper';

import {ChipsAlt, ChipsAltOption, Title} from 'components';
import {useChatContext} from '../../../../chat-context';
import * as AppEnv from 'app-env';

const ChatType = () => {
  const {setWidget, widget} = useChatContext();
  const {t} = useTranslation();

  const handleChange = (value: AppEnv.ChatWidgetChatType) =>
    setWidget(prevValue => upd(prevValue, {chat_type: {$set: value}}));

  const options: ChipsAltOption<AppEnv.ChatWidgetChatType>[] = [
    {img: '/static/widget/chat-type/max.svg', value: 'max'},
    {img: '/static/widget/chat-type/over.svg', value: 'over'},
    {img: '/static/widget/chat-type/min.svg', value: 'min'}
  ];

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Chat appearance`}</Title.H3>

      <ChipsAlt
        onChange={handleChange}
        options={options}
        value={widget.chat_type}
      />
    </div>
  );
};

export default ChatType;

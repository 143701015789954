import * as AppEnv from 'app-env';
import useGetProfileName from '../use-get-profile-name/use-get-profile-name';

const useProfileName = (
  instance: AppEnv.Instance,
  dialog: AppEnv.Dialog,
  isOriginal = false
) => {
  const getProfileName = useGetProfileName();

  const profileName = getProfileName(instance, dialog, isOriginal);
  return profileName;
};

export default useProfileName;

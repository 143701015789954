import upd from 'immutability-helper';

import {getDialogPath} from 'common/actions';
import {MessageEventData} from '../use-avito-ws-env';
import {useNotification} from 'pages/workspace/hooks';
import {useWebSocketProviderContext} from 'pages/workspace/web-socket-provider/web-socket-context';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

const useHandleNewMessage = () => {
  const {activeInstances} = useWorkspaceContext();
  const {dialogs, setDialogs} = useWebSocketProviderContext();

  const notification = useNotification();

  const pushMessage = (
    instanceId: number,
    dialogIndex: number,
    message: AppEnv.AvitoMessage
  ) =>
    setDialogs(prevValue =>
      upd(prevValue, {
        [instanceId]: {
          [dialogIndex]: {
            messages: {
              $apply: (messages: AppEnv.AvitoMessage[] | undefined) =>
                messages ? [...messages, message] : [message]
            }
          }
        }
      })
    );

  const updateLastMessage = (
    instanceId: number,
    dialogIndex: number,
    message: AppEnv.AvitoMessage
  ) =>
    setDialogs(prevValue =>
      upd(prevValue, {
        [instanceId]: {[dialogIndex]: {last_message: {$set: message}}}
      })
    );

  const handleNewMessage = (messageEventData: MessageEventData) => {
    const {chat, data} = messageEventData;

    const {dialogIndex, instanceId} = getDialogPath(dialogs, chat.chat_id);

    if (dialogIndex == undefined || instanceId == undefined) return;

    const payloadValue = data.payload || data.value;
    if (!payloadValue) return;

    const message: AppEnv.AvitoMessage = {
      ...payloadValue,
      direction: chat.from_me ? 'out' : 'in'
    };

    const dialog = dialogs[instanceId]?.[dialogIndex];
    if (!dialog) return;

    if (dialog.messages) pushMessage(instanceId, dialogIndex, message);
    updateLastMessage(instanceId, dialogIndex, message);

    const instance = activeInstances.find(
      instance => instance.id == instanceId
    );

    if (instance) notification(instance, dialog, message);
  };

  return handleNewMessage;
};

export {useHandleNewMessage};

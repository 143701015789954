import React, {useEffect, useRef, useState} from 'react';
import cn from 'classnames';

import {Button, Icons, Text} from 'components';

type InputMode =
  | 'decimal'
  | 'email'
  | 'none'
  | 'numeric'
  | 'search'
  | 'tel'
  | 'text'
  | 'url';

interface Input {
  autoCapitalize?: 'off';
  autoComplete?: 'off';
  autoFocus?: boolean;
  inputMode?: InputMode;
  isValid?: boolean;
  name: string;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onChange: (value: string) => void;
  onFocus?: () => void;
  placeholder?: string;
  style?: React.CSSProperties;
  type?: React.HTMLInputTypeAttribute;
  value: string | undefined;
}

const Input = ({
  autoCapitalize,
  autoComplete,
  autoFocus,
  inputMode,
  isValid = true,
  name,
  onBlur,
  onChange,
  onFocus,
  placeholder,
  style,
  type,
  value = ''
}: Input) => {
  const [htmlType, setHtmlType] = useState<
    React.HTMLInputTypeAttribute | undefined
  >(type);

  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!autoFocus) return;
    setTimeout(() => ref.current?.focus(), 150);
  }, []);

  const handleClick = () => {
    setHtmlType(prevValue => (prevValue == 'password' ? 'text' : 'password'));
  };

  return (
    <div className={cn('input', {input_error: !isValid})} style={style}>
      <input
        autoCapitalize={autoCapitalize}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        className="input__field"
        inputMode={inputMode}
        name={name}
        onBlur={onBlur}
        onChange={event => onChange(event.target.value)}
        onFocus={onFocus}
        placeholder=""
        ref={ref}
        style={type == 'password' ? {paddingRight: 48} : undefined}
        type={htmlType}
        value={value}
      />

      {placeholder && (
        <Text className="input__placeholder" fontWeight={500} size={16}>
          {placeholder}
        </Text>
      )}

      {type == 'password' && (
        <Button
          className="input__button"
          color="transparent"
          onClick={handleClick}
        >
          {htmlType == 'password' ? (
            <Icons.Eye size={24} />
          ) : (
            <Icons.EyeClosed size={24} />
          )}
        </Button>
      )}
    </div>
  );
};

export default Input;

import {useTranslation} from 'react-i18next';
import * as AppEnv from 'app-env';

interface InstanceBilling {
  date?: number;
  label: string;
  tariff_plane?: AppEnv.InstanceTariffPlane;
  value: 'paid' | 'premium' | 'trial' | 'unpaid';
}

const useInstanceBilling = (instance: AppEnv.Instance): InstanceBilling => {
  const {t} = useTranslation();

  if (instance.is_premium) return {label: t`Premium`, value: 'premium'};
  else if ((instance.date_subscription || 0) * 1000 > Date.now())
    return {
      date: instance.date_subscription,
      label: t`Paid`,
      tariff_plane: instance.tariff_plane,
      value: 'paid'
    };
  else if ((instance.date_trial || 0) * 1000 > Date.now())
    return {date: instance.date_subscription, label: t`Trial`, value: 'trial'};

  return {label: t`Unpaid`, value: 'unpaid'};
};

export default useInstanceBilling;

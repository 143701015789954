import React from 'react';
import {useTranslation} from 'react-i18next';

import {AccessMulti} from '../components';
import {SelectOption} from 'components';
import * as AppEnv from 'app-env';

const Dialogs = () => {
  const {t} = useTranslation();

  const options: SelectOption<keyof AppEnv.RoleAccesses>[] = [
    {label: t`Assign responsible`, value: 'dialogs_manager'},
    {label: t`Pin`, value: 'dialogs_pin'},
    {label: t`Mark as unread`, value: 'dialogs_unread'},
    {label: t`Add tag`, value: 'dialogs_labels'},
    {label: t`Edit contact`, value: 'dialogs_contacts'},
    {label: t`Delete`, value: 'dialogs_delete'},
    {label: t`Block`, value: 'dialogs_blacklist'}
  ];

  return <AccessMulti options={options} placeholder={t`Chats`} />;
};

export default Dialogs;

import React from 'react';
import {useFormikContext} from 'formik';
import {useNavigate} from 'react-router-dom';
import {useOnKeyDown} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {Button, Popup, PopupWrapper, Text} from 'components';
import {useChatContext} from '../../../../chat-context';
import * as AppEnv from 'app-env';

interface InstanceConfirm {
  isActive: boolean;
  setIsActive: AppEnv.SetState<boolean>;
}

const InstanceConfirm = ({isActive, setIsActive}: InstanceConfirm) => {
  const {submitForm} = useChatContext();

  const {isSubmitting, setSubmitting, values} =
    useFormikContext<AppEnv.ChatPut>();

  const {t} = useTranslation();
  const navigate = useNavigate();

  const handleClick = async () => {
    setSubmitting(true);
    const res = await submitForm(values);

    setSubmitting(true);
    if (!res) return;

    navigate(
      `/connections/plans?instance_id=${values.instance_id}&version=chat`
    );
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key == 'Enter') handleClick();
    else if (e.key == 'Escape') setIsActive(false);
  };

  useOnKeyDown(handleKeyDown, [isActive]);

  return (
    <Popup isActive={isActive} isConfirm onClick={() => setIsActive(false)}>
      <PopupWrapper>
        <Text
          fontWeight={500}
          size={16}
          style={{marginBottom: 8}}
          textAlign="center"
        >
          {t`Go to the rate plans?`}
        </Text>

        <Text color="secondary" style={{marginBottom: 16}} textAlign="center">
          {t`Widget settings will be saved.`}
        </Text>

        <div className="confirm">
          <Button
            onClick={handleClick}
            state={isSubmitting ? 'submitting' : undefined}
          >
            {t`Yes`}
          </Button>

          <Button color="fade" onClick={() => setIsActive(false)}>
            {t`No`}
          </Button>
        </div>
      </PopupWrapper>
    </Popup>
  );
};

export default InstanceConfirm;

import React from 'react';
import {useTranslation} from 'react-i18next';

import {ContextMenuOption, IconsAlt} from 'components';
import * as AppEnv from 'app-env';

import useClear from './use-clear/use-clear';
import useUpdate from './use-update/use-update';

interface UseOptions {
  setIsContactFormActive: AppEnv.SetState<boolean>;
  setIsDeleteDialogActive: AppEnv.SetState<boolean>;
  setIsProfileActive: AppEnv.SetState<boolean>;
  setIsWallpapersActive: AppEnv.SetState<boolean>;
}

const useOptions = ({
  setIsContactFormActive,
  setIsDeleteDialogActive,
  setIsProfileActive,
  setIsWallpapersActive
}: UseOptions): ContextMenuOption[] => {
  const {t} = useTranslation();
  const clear = useClear(setIsDeleteDialogActive);
  const update = useUpdate(setIsContactFormActive);

  const info = {
    icon: <IconsAlt.Question />,
    label: t`Information`,
    onClick: () => setIsProfileActive(true)
  };

  const wallpaper = {
    icon: <IconsAlt.PaintBrush />,
    label: t`Change the wallpaper`,
    onClick: () => setIsWallpapersActive(true)
  };

  const options = [info, wallpaper, update, clear].filter(
    option => option != null
  );

  return options as ContextMenuOption[];
};

export default useOptions;

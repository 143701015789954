import React, {useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import ReactTextareaAutosize from 'react-textarea-autosize';

import {Anchor, Text} from 'components';
import {useChatContext} from '../../../chat-context';
import {useUpdateContact} from '../../hooks';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';

const About = () => {
  const {user} = useWorkspaceContext();
  const {contact} = useChatContext();

  const [about, setAbout] = useState<string | undefined>(
    contact?.about || undefined
  );

  const {t} = useTranslation();
  const ref = useRef<HTMLTextAreaElement>(null);
  const updateContact = useUpdateContact();

  const handleBlur = () => {
    if (!about) setAbout(undefined);

    if (about == contact?.about) return;
    else if (!about && !contact?.about) return;

    updateContact({about});
  };

  const handleClick = () => {
    setAbout('');
    setTimeout(() => ref.current?.focus());
  };

  const {roles} = user.manager;

  return (
    <>
      {about != undefined ? (
        roles.dialogs_contacts != false ? (
          <ReactTextareaAutosize
            className="profile__about"
            name="about"
            onBlur={handleBlur}
            onChange={e => setAbout(e.target.value)}
            placeholder={t`Comment`}
            ref={ref}
            style={{marginTop: 8}}
            value={about}
          />
        ) : (
          <div className="profile__about">
            <Text size={16}>{about}</Text>
          </div>
        )
      ) : (
        roles.dialogs_contacts != false && (
          <Anchor color="gold" onClick={handleClick} size={16}>
            {t`Add a comment`}
          </Anchor>
        )
      )}
    </>
  );
};

export default About;

import React, {useState, useRef} from 'react';
import {Form, Formik, FormikHelpers} from 'formik';
import cn from 'classnames';

import {getIsNewMessageAvailable} from 'common/actions';
import {useChatContext} from '../chat-context';
import {useDispatchContext} from 'pages/workspace/pages/messenger/dispatch/dispatch-context';
import * as AppEnv from 'app-env';

import {useSendMsg} from './hooks';
import {Values} from './chat-footer-env';
import ChatFooterContext from './chat-footer-context';
import ChatFooterWrapper from './chat-footer-wrapper/chat-footer-wrapper';
import Reply from './reply/reply';
import ScrollDown from './scroll-down/scroll-down';

const initialValues: Values = {
  allowMultipleAnswers: false,
  attachs: [],
  body: '',
  buttons: [{body: ''}, {body: ''}],
  voice: undefined
};

const ChatFooter = () => {
  const {instance} = useDispatchContext();
  const {dialog, quotedMsg, setQuotedMsg} = useChatContext();

  const [isAttachsFormActive, setIsAttachsFormActive] = useState(false);
  const [isButtonsFormActive, setIsButtonsFormActive] = useState(false);
  const [isVoiceRecording, setIsVoiceRecording] = useState(false);
  const [progress, setProgress] = useState<number[]>([]);

  const [templateState, setTemplateState] = useState<AppEnv.TemplateState>({
    isActive: false
  });

  const sendMsg = useSendMsg(setIsVoiceRecording, setProgress);
  const textRef = useRef<HTMLTextAreaElement>(null);

  const handleSubmit = async (
    values: Values,
    helpers: FormikHelpers<Values>
  ) => {
    if (!values.attachs.length && !values.body && !values.voice) return;

    const res = await sendMsg(values);
    if (!res) return;

    setIsAttachsFormActive(false);
    setIsButtonsFormActive(false);

    await new Promise(resolve => setTimeout(resolve, 150));

    helpers.resetForm();
    setQuotedMsg(undefined);
  };

  const isNewMessageAvailable = getIsNewMessageAvailable(instance, dialog);

  return (
    <ChatFooterContext.Provider
      value={{
        isAttachsFormActive,
        isButtonsFormActive,
        isVoiceRecording,
        progress,
        setIsAttachsFormActive,
        setIsButtonsFormActive,
        setIsVoiceRecording,
        setTemplateState,
        templateState
      }}
    >
      <div
        className={cn('chat-footer', {
          'chat-footer_alt': !isNewMessageAvailable
        })}
      >
        <ScrollDown />

        {isNewMessageAvailable && (
          <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            <Form>
              {quotedMsg && <Reply />}
              <ChatFooterWrapper ref={textRef} textRef={textRef} />
            </Form>
          </Formik>
        )}
      </div>
    </ChatFooterContext.Provider>
  );
};

export default ChatFooter;

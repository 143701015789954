import React from 'react';
import {useFormikContext} from 'formik';

import {Values} from '../../../../chat-footer-env';
import {Wrapper} from 'components';

import AttachPreview from './attach-preview/attach-preview';

const AttachsPreview = () => {
  const {values} = useFormikContext<Values>();

  return (
    <>
      {values.attachs.length > 0 && (
        <Wrapper direction="column" gap={16}>
          {values.attachs.map((attach, i) => (
            <AttachPreview attach={attach} index={i} key={i} />
          ))}
        </Wrapper>
      )}
    </>
  );
};

export default AttachsPreview;

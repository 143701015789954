import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

import {Anchor, Icons, Wrapper} from 'components';
import {useChatContext} from '../../../chat-context';

import {useInstanceOptions, useOptions} from './hooks';
import Social from './social/social';
import SocialForm from './social-form/social-form';

const Socials = () => {
  const {widget} = useChatContext();
  const [isSocialAdderActive, setIsSocialAdderActive] = useState(false);

  const {t} = useTranslation();
  const instanceOptions = useInstanceOptions();
  const options = useOptions();

  const isDisabled = ![...instanceOptions, ...options].some(
    option => !option.isDisabled
  );

  return (
    <div>
      {widget.socials.length > 0 && (
        <Wrapper direction="column" gap={8} style={{marginBottom: 16}}>
          {widget.socials.map((social, i) => (
            <Social index={i} key={i} social={social} />
          ))}
        </Wrapper>
      )}

      <Wrapper justifyContent="center">
        <Anchor
          fontWeight={500}
          isDisabled={isDisabled}
          onClick={() => setIsSocialAdderActive(true)}
          size={16}
          textAlign="center"
        >
          {t`Add a social network`}
          <Icons.Plus />
        </Anchor>
      </Wrapper>

      <SocialForm
        instanceOptions={instanceOptions}
        isSocialAdderActive={isSocialAdderActive}
        options={options}
        setIsSocialAdderActive={setIsSocialAdderActive}
      />
    </div>
  );
};

export default Socials;

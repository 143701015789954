import React from 'react';
import cn from 'classnames';

import {Text} from 'components';

interface Option<T> {
  label: React.ReactNode;
  value: T;
}

interface Toggle<T> {
  onChange: (value: T) => void;
  options: Option<T>[];
  style?: React.CSSProperties;
  value: T;
}

const Toggle = <T,>({onChange, options, style, value}: Toggle<T>) => (
  <div className="toggle" style={style}>
    {options.map((option, i) => (
      <button
        className={cn('toggle__button', {
          toggle__button_active: option.value == value
        })}
        key={i}
        onClick={() => onChange(option.value)}
      >
        <Text color={option.value == value ? 'green' : undefined}>
          {option.label}
        </Text>
      </button>
    ))}
  </div>
);

export default Toggle;

import React, {useEffect, useRef} from 'react';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {Textarea} from 'components';
import {useHandleEnterDown} from '../../../../hooks';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';

const Body = () => {
  const {user} = useWorkspaceContext();

  const [field, , helpers] = useField<string>('body');
  const {t} = useTranslation();
  const handleEnterDown = useHandleEnterDown();
  const ref = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    setTimeout(() => {
      if (!ref.current) return;

      ref.current.focus();
      ref.current.selectionStart = field.value.length;
    }, 100);
  }, []);

  const {roles} = user.manager;

  return (
    <Textarea
      {...field}
      isAlt
      isDisabled={roles.messages_text == false}
      onChange={helpers.setValue}
      onKeyDown={handleEnterDown}
      placeholder={`${t`Caption`}...`}
      ref={ref}
    />
  );
};

export default Body;

import React, {useEffect, useState} from 'react';
import {Route, Routes} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {Body, Nav, Page, PageBody, PageMain, Preloader} from 'components';
import {Firewall} from 'pages/workspace/components';
import {getUrlParams} from 'common/actions';
import {useRequest} from 'common/hooks';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

import Roles from './roles/roles';
import StaffContext from './staff-context';
import StaffMain from './staff-main/staff-main';

const Staff = () => {
  const {getManagerList, user} = useWorkspaceContext();

  const [managerList, setManagerList] = useState<AppEnv.Manager[]>();
  const [roleList, setRoleList] = useState<AppEnv.Role[]>();

  const {fetchRolesList} = useRequest();
  const {t} = useTranslation();

  const getRoleList = async () => {
    const {integration} = user;
    const res = await fetchRolesList(integration.id);

    setRoleList(res || []);
  };

  const updateManagerList = async () => {
    const res = await getManagerList();
    setManagerList(res);
  };

  useEffect(() => {
    getRoleList();
    updateManagerList();
  }, []);

  const {is_admin} = getUrlParams();
  const {roles} = user.manager;

  const isAccess = is_admin != '0' && roles.managers_write != false;
  const options = [{label: t`Staff`, value: '/staff'}];

  if (isAccess) options.push({label: t`Roles`, value: '/staff/roles'});

  return (
    <StaffContext.Provider value={{roleList, setRoleList}}>
      <Body>
        <Page>
          <Nav options={options} value={location.pathname} />

          <PageBody>
            <PageMain>
              {managerList ? (
                <Routes>
                  <Route
                    path="/"
                    element={
                      <StaffMain
                        managerList={managerList}
                        setManagerList={setManagerList}
                        updateManagerList={updateManagerList}
                      />
                    }
                  />

                  <Route
                    path="/roles"
                    element={
                      <Firewall keyof="managers_write">
                        <Roles />
                      </Firewall>
                    }
                  />
                </Routes>
              ) : (
                <Preloader isMaxHeight />
              )}
            </PageMain>
          </PageBody>
        </Page>
      </Body>
    </StaffContext.Provider>
  );
};

export default Staff;

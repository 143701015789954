import React from 'react';
import {useChatContext} from '../../../chat-context';

import ChatType from './chat-type/chat-type';
import ChatMessageType from './chat-message-type/chat-message-type';

const Chat = () => {
  const {widget} = useChatContext();

  return (
    <>
      {widget.is_chat ? (
        <>
          <ChatType />
          <ChatMessageType />
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default Chat;

import React from 'react';

import {getSizeLimit} from 'common/actions';
import {Text} from 'components';
import {useConvertBytes} from 'common/hooks';
import {useDispatchContext} from 'pages/workspace/pages/messenger/dispatch/dispatch-context';
import * as AppEnv from 'app-env';

interface FileSize {
  attach: AppEnv.Attach;
}

const FileSize = ({attach}: FileSize) => {
  const {instance} = useDispatchContext();
  const convertBytes = useConvertBytes();

  const limit = attach.file ? getSizeLimit(instance, attach.file) : undefined;
  const convertedLimit = limit ? convertBytes(limit) : undefined;

  const convertedSize = attach.file
    ? convertBytes(attach.file.size)
    : undefined;

  return (
    <>
      {attach.file && (
        <Text size={14}>
          <span style={{color: attach.isValid ? '#06c755' : '#ff334b'}}>
            {convertedSize}
          </span>

          {` / ${convertedLimit}`}
        </Text>
      )}
    </>
  );
};

export default FileSize;

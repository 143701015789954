import React, {useEffect, useState} from 'react';
import {useInstanceContext} from '../../instance-context';

import Cloud from './cloud/cloud';
import Code from './code/code';
import PhoneNumber from './phone-number/phone-number';
import QrCode from './qr-code/qr-code';

const Setup = () => {
  const {instance} = useInstanceContext();

  const [code, setCode] = useState<string>();
  const [isQrCodeMethod, setIsQrCodeMethod] = useState(true);

  useEffect(() => {
    if (['init', 'PENDING'].includes(instance.status)) setIsQrCodeMethod(true);
  }, [instance.status]);

  const isSignInByPhoneNumberShown =
    instance.version == 'telegram' || instance.version == 'whatcrm';

  return (
    <>
      {instance.version == 'cloud' ? (
        <Cloud />
      ) : code ? (
        <Code
          code={code}
          setCode={setCode}
          setIsQrCodeMethod={setIsQrCodeMethod}
        />
      ) : isQrCodeMethod ? (
        <QrCode
          isSignInByPhoneNumberShown={isSignInByPhoneNumberShown}
          setIsQrCodeMethod={setIsQrCodeMethod}
        />
      ) : (
        <PhoneNumber setCode={setCode} setIsQrCodeMethod={setIsQrCodeMethod} />
      )}
    </>
  );
};

export default Setup;

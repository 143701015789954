import React from 'react';
import {useFormikContext} from 'formik';

import {Button, Icons} from 'components';
import {Values} from '../../chat-footer-env';

const Clear = () => {
  const {setFieldValue} = useFormikContext<Values>();

  return (
    <Button color="transparent" onClick={() => setFieldValue('body', '')}>
      <Icons.CircleClose />
    </Button>
  );
};

export default Clear;

import React from 'react';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {Select} from 'components';
import {useInstanceOptions} from 'common/hooks';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';

const InstanceId = () => {
  const {activeInstances} = useWorkspaceContext();

  const [field, meta, helpers] = useField<number | undefined>('instance_id');
  const {t} = useTranslation();

  const chatInstances = activeInstances.filter(
    instance => instance.version == 'chat'
  );

  const options = useInstanceOptions(chatInstances);
  const isValid = !(meta.error && meta.touched);

  return (
    <Select
      {...field}
      isValid={isValid}
      onChange={helpers.setValue}
      options={options}
      placeholder={t`Connection`}
    />
  );
};

export default InstanceId;

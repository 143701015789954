import React, {forwardRef} from 'react';
import {useScrollClassName} from 'whatcrm-core';
import cn from 'classnames';

import {Button, Icons} from 'components';

export interface FuncPopupProps {
  children: React.ReactNode;
  isActive: boolean;
  isConfirm?: boolean;
  isMaxHeight?: boolean;
  onClick: () => void;
  width?: 408;
}

const FuncPopup = forwardRef<HTMLDivElement, FuncPopupProps>(
  ({children, isActive, isConfirm, isMaxHeight, onClick, width}, ref) => {
    const scrollClassName = useScrollClassName();

    return (
      <div
        className={cn('popup', {
          [scrollClassName]: scrollClassName,
          popup_active: isActive
        })}
      >
        <div
          className={cn('popup__container', {
            popup__container_confirm: isConfirm,
            'popup__container_max-height': isMaxHeight,
            [`popup__container_${width}`]: width
          })}
          ref={ref}
        >
          <Button
            className="popup__button"
            color="transparent"
            onClick={onClick}
          >
            <Icons.Close />
          </Button>

          {children}
        </div>
      </div>
    );
  }
);

FuncPopup.displayName = 'FuncPopup';
export default FuncPopup;

import React from 'react';
import {useTranslation} from 'react-i18next';
import upd from 'immutability-helper';

import {Checkbox} from 'components';
import {useChatContext} from '../../../../chat-context';

const IsName = () => {
  const {setWidget, widget} = useChatContext();
  const {t} = useTranslation();

  const handleChange = (value: boolean) =>
    setWidget(prevValue => upd(prevValue, {is_name: {$set: value}}));

  return (
    <Checkbox
      label={t`Name required`}
      name="is_name"
      onChange={handleChange}
      style={{marginBottom: 8}}
      value={widget.is_name}
    />
  );
};

export default IsName;

import React from 'react';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {Icons, Select, SelectOption} from 'components';
import {useStaffContext} from 'pages/workspace/pages/staff/staff-context';
import * as AppEnv from 'app-env';

interface RoleId {
  setIsRoleFormActive: AppEnv.SetState<boolean>;
}

const RoleId = ({setIsRoleFormActive}: RoleId) => {
  const {roleList} = useStaffContext();

  const [field, , helpers] = useField('role_id');
  const {t} = useTranslation();

  const handleChange = (value: number) => {
    if (value < 0) return setIsRoleFormActive(true);
    helpers.setValue(value);
  };

  const options: SelectOption<number>[] | undefined = roleList?.map(item => ({
    label: item.title,
    value: item.id
  }));

  options?.unshift({
    label: t`Add role`,
    plus: <Icons.Plus color="green" size={20} />,
    value: -1
  });

  return (
    <>
      <Select
        {...field}
        onChange={handleChange}
        options={options}
        placeholder={t`Role`}
        style={{marginBottom: 16}}
      />
    </>
  );
};

export default RoleId;

import React from 'react';

import {FeatListItem} from '../components';
import * as AppEnv from 'app-env';

import {useFeatListChat} from './hooks';

interface FeatListChat {
  tariffPlan: AppEnv.TariffPlan;
}

const FeatListChat = ({tariffPlan}: FeatListChat) => {
  const featListChat = useFeatListChat(tariffPlan);

  return (
    <ul className="feature-list">
      {featListChat.map((feat, i) => (
        <FeatListItem {...feat} key={i} tariffPlan={tariffPlan} />
      ))}
    </ul>
  );
};

export default FeatListChat;

import React from 'react';
import {useField} from 'formik';

import {Text} from 'components';
import {insertSubstr} from 'common/actions';

interface Vars {
  messageRef: React.RefObject<HTMLTextAreaElement>;
}

const vars = [
  'CLIENT_NAME',
  'CLIENT_NUMBER',
  'COMPANY_NAME',
  'COMPANY_NUMBER',
  'TODAY',
  'TOMORROW'
];

const Vars = ({messageRef}: Vars) => {
  const [field, , helpers] = useField<string>('message');

  const handleClick = (variable: string) => {
    const value = insertSubstr(messageRef, field.value, `%${variable}%`);
    helpers.setValue(value);
  };

  return (
    <div className="vars" style={{marginLeft: 6}}>
      {vars.map((variable, i) => (
        <button
          className="vars__button"
          key={i}
          onClick={() => handleClick(variable)}
          type="button"
        >
          <Text color="blue" size={14}>
            {variable}
          </Text>
        </button>
      ))}
    </div>
  );
};

export default Vars;

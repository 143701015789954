import React from 'react';
import {useTranslation} from 'react-i18next';

import {ContextMenuOption, IconsAlt} from 'components';
import {getIsDialogPinned} from 'common/actions';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

import useTogglePin from './use-toggle-pin/use-toggle-pin';

const usePin = (dialog: AppEnv.Dialog): ContextMenuOption | null => {
  const {user} = useWorkspaceContext();

  const {t} = useTranslation();
  const togglePin = useTogglePin(dialog);

  const {roles} = user.manager;

  if (!['chat', 'telegram', 'whatcrm'].includes(dialog.version)) return null;
  else if (roles.dialogs_pin == false) return null;

  const isPinned = getIsDialogPinned(dialog);

  const pin = {
    icon: <IconsAlt.PushPinSimple />,
    label: t`Pin`,
    onClick: () => togglePin(true)
  };

  const unpin = {
    icon: <IconsAlt.PushPinSimpleSlash />,
    label: t`Unpin`,
    onClick: () => togglePin(false)
  };

  if (isPinned) return unpin;
  return pin;
};

export default usePin;

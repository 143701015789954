import React, {useState} from 'react';
import {FormikContext, useFormik} from 'formik';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';
import upd from 'immutability-helper';

import {
  Button,
  Popup,
  PopupFooter,
  PopupWrapper,
  SaveButton,
  Title,
  Wrapper
} from 'components';
import {RoleForm} from '../../../components';
import {useRequest} from 'common/hooks';
import {useStaffContext} from '../../../staff-context';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

import AccessInstances from './access-instances/access-instances';
import Email from './email/email';
import Fullname from './fullname/fullname';
import Phone from './phone/phone';
import RoleId from './role-id/role-id';

interface ManagerForm {
  isActive: boolean;
  manager?: AppEnv.Manager;
  onSubmit: (values: Partial<AppEnv.Manager>) => Promise<boolean>;
  setIsActive: AppEnv.SetState<boolean>;
}

const initialValues = {
  access_instances: '',
  email: '',
  fullname: '',
  phone: '',
  role_id: undefined
};

const validationSchema = yup.object().shape({
  email: yup.string().trim().required().min(6),
  fullname: yup.string().trim().required()
});

const ManagerForm = ({
  isActive,
  manager,
  onSubmit,
  setIsActive
}: ManagerForm) => {
  const {user} = useWorkspaceContext();
  const {setRoleList} = useStaffContext();

  const [isRoleFormActive, setIsRoleFormActive] = useState(false);

  const {postRolesCreate} = useRequest();
  const {t} = useTranslation();

  const formik = useFormik({
    initialValues: manager || initialValues,
    onSubmit: onSubmit,
    validateOnMount: false,
    validationSchema: validationSchema
  });

  const handleSubmit = async (
    values: Pick<AppEnv.Role, 'accesses' | 'title'>
  ) => {
    const {integration} = user;

    const res = await postRolesCreate(integration.id, values);
    if (!res) return true;

    formik.setFieldValue('role_id', res.id);
    setIsRoleFormActive(false);
    setRoleList(prevValue => upd(prevValue, {$push: [res]}));

    return true;
  };

  const {integration} = user;

  return (
    <>
      <Popup isActive={isActive} onClick={() => setIsActive(false)} width={408}>
        <FormikContext.Provider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <PopupWrapper>
              <Title.H3 style={{marginBottom: 16}}>
                {manager ? t`Edit staff member` : t`Add staff member`}
              </Title.H3>

              {integration.type == 'LK' && (
                <>
                  <Fullname />
                  <Email />
                  <Phone />
                </>
              )}

              {integration.domain != manager?.email && (
                <RoleId setIsRoleFormActive={setIsRoleFormActive} />
              )}

              <AccessInstances />
            </PopupWrapper>

            <PopupFooter>
              <Wrapper gap={12}>
                <SaveButton>{t`Save`}</SaveButton>

                <Button color="fade" onClick={() => setIsActive(false)}>
                  {t`Close`}
                </Button>
              </Wrapper>
            </PopupFooter>
          </form>
        </FormikContext.Provider>
      </Popup>

      <RoleForm
        isActive={isRoleFormActive}
        onSubmit={handleSubmit}
        setIsActive={setIsRoleFormActive}
      />
    </>
  );
};

export default ManagerForm;

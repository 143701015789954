import React from 'react';

import {Label} from '../components';
import {CardBody, Wrapper} from 'components';

import AccessToken from './access-token/access-token';
import ChatId from './chat-id/chat-id';
import DatePay from './date-pay/date-pay';
import Id from './id/id';
import Name from './name/name';
import Phone from './phone/phone';
import Place from './place/place';

const InstanceBody = () => (
  <CardBody>
    <Wrapper gap={12} direction="column">
      <Label />

      <Wrapper direction="column" gap={4}>
        <DatePay />
        <Name />
        <Phone />
        <Place />
        <ChatId />
        <AccessToken />
        <Id />
      </Wrapper>
    </Wrapper>
  </CardBody>
);

export default InstanceBody;

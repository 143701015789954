import React from 'react';
import {useField, useFormikContext} from 'formik';
import {useOnKeyDown} from 'whatcrm-core';

import * as AppEnv from 'app-env';

import Preview from './preview/preview';
import Recorder from './recorder/recorder';

interface Voice {
  isAlt?: boolean;
  isDisabled?: boolean;
  isVoiceRecording: boolean;
  setIsVoiceRecording: AppEnv.SetState<boolean>;
  version: AppEnv.InstanceVersion | 'whatsapp';
}

const Voice = ({
  isAlt,
  isDisabled,
  isVoiceRecording,
  setIsVoiceRecording,
  version
}: Voice) => {
  const {isSubmitting, submitForm} = useFormikContext();
  const [field] = useField<AppEnv.Voice | undefined>('voice');

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key != 'Enter') return;
    submitForm();
  };

  useOnKeyDown(handleKeyDown, [field.value, !isSubmitting]);

  return (
    <>
      {navigator.mediaDevices && (
        <>
          {field.value ? (
            <Preview setIsVoiceRecording={setIsVoiceRecording} />
          ) : (
            <Recorder
              isAlt={isAlt}
              isDisabled={isDisabled}
              isVoiceRecording={isVoiceRecording}
              setIsVoiceRecording={setIsVoiceRecording}
              version={version}
            />
          )}
        </>
      )}
    </>
  );
};

export default Voice;

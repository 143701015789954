import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useField, useFormikContext} from 'formik';

import {AttachsPreview} from '../../components';
import {
  Button,
  Popup,
  PopupFooter,
  PopupWrapper,
  SaveButton,
  Title,
  Wrapper
} from 'components';
import {useChatFooterContext} from '../../../../chat-footer-context';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import {Values} from '../../../../chat-footer-env';
import * as AppEnv from 'app-env';

import Body from './body/body';

const AttachsForm = () => {
  const {user} = useWorkspaceContext();
  const {isAttachsFormActive, setIsAttachsFormActive} = useChatFooterContext();
  const {values} = useFormikContext<Values>();

  const [field, , helpers] = useField<AppEnv.Attach[]>('attachs');
  const {t} = useTranslation();

  const handleClick = () => {
    setIsAttachsFormActive(false);
    setTimeout(() => helpers.setValue([]), 150);
  };

  const {roles} = user.manager;

  const filesLength = field.value.length.toLocaleString();
  const isBody = roles.messages_text != false || values.body.length > 0;
  const isValid = !field.value.some(item => !item.isValid);

  return (
    <Popup width={408} isActive={isAttachsFormActive} onClick={handleClick}>
      <PopupWrapper>
        <Wrapper alignItems="stretch" direction="column" gap={16}>
          <Title.H3>
            <Trans count={field.value.length}>
              {'Send '}
              {{filesLength}}
              {' file'}
            </Trans>
          </Title.H3>

          <AttachsPreview />
          {isBody && <Body />}
        </Wrapper>
      </PopupWrapper>

      <PopupFooter>
        <Wrapper gap={12}>
          <SaveButton state={!isValid ? 'disabled' : undefined}>
            {t`Send`}
          </SaveButton>

          <Button color="fade" onClick={handleClick}>
            {t`Close`}
          </Button>
        </Wrapper>
      </PopupFooter>
    </Popup>
  );
};

export default AttachsForm;

import React from 'react';
import {useTranslation} from 'react-i18next';
import upd from 'immutability-helper';

import {ChipsAlt, ChipsAltOption, Title} from 'components';
import {useChatContext} from '../../../../chat-context';
import * as AppEnv from 'app-env';

const ChatMessageType = () => {
  const {setWidget, widget} = useChatContext();
  const {t} = useTranslation();

  const handleChange = (value: AppEnv.ChatWidgetMessageType) =>
    setWidget(prevValue => upd(prevValue, {chat_message_type: {$set: value}}));

  const options: ChipsAltOption<AppEnv.ChatWidgetMessageType>[] = [
    {
      img: '/static/widget/chat-message-type/minimalism.svg',
      value: 'minimalism'
    },
    {img: '/static/widget/chat-message-type/retro.svg', value: 'retro'},
    {
      img: '/static/widget/chat-message-type/classic.svg',
      value: 'classic'
    }
  ];

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Message appearance`}</Title.H3>

      <ChipsAlt
        onChange={handleChange}
        options={options}
        value={widget.chat_message_type}
      />
    </div>
  );
};

export default ChatMessageType;

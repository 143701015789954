import React, {useState} from 'react';

import FirstStep from './first-step/first-step';
import SecondStep from './second-step/second-step';

const Cloud = () => {
  const [isFirstStep, setIsFirstStep] = useState(true);

  return (
    <>
      {isFirstStep ? (
        <FirstStep setIsFirstStep={setIsFirstStep} />
      ) : (
        <SecondStep setIsFirstStep={setIsFirstStep} />
      )}
    </>
  );
};

export default Cloud;

import React from 'react';
import {Route, Routes} from 'react-router-dom';

import {Body} from 'components';
import {Firewall} from 'pages/workspace/components';

import Chat from './chat/chat';
import WidgetsMain from './widgets-main/widgets-main';

const Widgets = () => (
  <Body>
    <Routes>
      <Route path="/" element={<WidgetsMain />} />

      <Route
        path=":uid"
        element={
          <Firewall keyof="widgets_write">
            <Chat />
          </Firewall>
        }
      />
    </Routes>
  </Body>
);

export default Widgets;

import React from 'react';
import {useTranslation} from 'react-i18next';

import {Text} from 'components';
import {useInstanceBilling} from 'common/hooks';
import {useInstanceContext} from '../../instance-context';

const Place = () => {
  const {instance} = useInstanceContext();

  const {t} = useTranslation();
  const billing = useInstanceBilling(instance);

  const isVisible =
    billing.tariff_plane == 'Enterprise' && instance.version != 'chat';

  const places = {
    Finland: `🇫🇮 ${t`Finland`}`,
    Germany: `🇩🇪 ${t`Germany`}`,
    Russian: `🇷🇺 ${t`Russia`}`
  };

  return (
    <>
      {instance.place && isVisible && (
        <Text>
          <Text color="wild" isSpan>
            {t`Server location`}
          </Text>
          {': '}
          {places[instance.place] || instance.place}
        </Text>
      )}
    </>
  );
};

export default Place;

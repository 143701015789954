import React, {useState} from 'react';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {Button, Icons} from 'components';
import {InputMulti} from 'components';
import * as AppEnv from 'app-env';

const Buttons = () => {
  const [autoFocus, setAutoFocus] = useState(false);

  const [field, , helpers] = useField<string | null>('buttons');
  const {t} = useTranslation();

  const handleChange = (value: AppEnv.Button[]) => {
    const newValue = JSON.stringify(value);
    helpers.setValue(newValue);
  };

  const handleClick = () => {
    helpers.setValue(JSON.stringify([{body: ''}, {body: ''}]));
    setAutoFocus(true);
  };

  return (
    <>
      {field.value ? (
        <InputMulti
          {...field}
          autoFocus={autoFocus}
          onChange={handleChange}
          onClear={() => helpers.setValue(null)}
          placeholder={t`Type a reply`}
          title={t`Reply options`}
          value={JSON.parse(field.value)}
        />
      ) : (
        <Button color="blue-dash" onClick={handleClick}>
          <span>{t`Add reply options`}</span>
          <Icons.Plus color="blue" size={20} />
        </Button>
      )}
    </>
  );
};

export default Buttons;

import React from 'react';
import {useField} from 'formik';

import {SelectMulti, SelectOption} from 'components';
import {useAccesses} from '../../hooks';
import * as AppEnv from 'app-env';

interface AccessMulti {
  options: SelectOption<keyof AppEnv.RoleAccesses>[];
  placeholder: string;
}

const AccessMulti = ({options, placeholder}: AccessMulti) => {
  const [, , helpers] = useField<string>('accesses');
  const accesses = useAccesses();

  const handleChange = (value: (keyof AppEnv.RoleAccesses)[]) => {
    const newMessages = options.reduce((acc, option) => {
      acc[option.value] = value.includes(option.value);
      return acc;
    }, {} as Partial<AppEnv.RoleAccesses>);

    const newAccesses = {...accesses, ...newMessages};
    const newValue = JSON.stringify(newAccesses);

    helpers.setValue(newValue);
  };

  const value = options.reduce(
    (acc, option) => {
      if (accesses[option.value] ?? true) acc.push(option.value);
      return acc;
    },
    [] as (keyof AppEnv.RoleAccesses)[]
  );

  return (
    <SelectMulti
      onChange={handleChange}
      options={options}
      placeholder={placeholder}
      value={value}
    />
  );
};

export default AccessMulti;

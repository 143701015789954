import React, {useEffect, useRef} from 'react';
import cn from 'classnames';

import {Button, Icons} from 'components';

interface InputAlt {
  autoCapitalize?: 'off';
  autoComplete?: 'off';
  autoFocus?: boolean;
  isLight?: boolean;
  isValid?: boolean;
  maxWidth?: '100%';
  name: string;
  onChange: (value: string) => void;
  onClear?: () => void;
  placeholder?: string;
  style?: React.CSSProperties;
  type?: React.HTMLInputTypeAttribute;
  value: string | number;
  width?: 88;
}

const InputAlt = ({
  autoCapitalize,
  autoComplete,
  autoFocus,
  isLight,
  isValid = true,
  maxWidth,
  name,
  onChange,
  onClear,
  placeholder,
  style,
  type,
  value,
  width
}: InputAlt) => {
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!autoFocus) return;
    setTimeout(() => ref.current?.focus(), 150);
  }, []);

  const handleClick = () => {
    onChange('');

    if (!onClear) return;
    onClear();
  };

  const isClear = !!onClear || type == 'search';

  return (
    <div
      className={cn('input-alt', {
        'input-alt_clear': onClear,
        'input-alt_error': !isValid,
        'input-alt_light': isLight
      })}
      style={{...style, maxWidth, width}}
    >
      <input
        autoCapitalize={autoCapitalize}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        className="input-alt__field"
        name={name}
        onChange={event => onChange(event.target.value)}
        placeholder={placeholder}
        ref={ref}
        type={type}
        value={value}
      />

      {isClear && (
        <Button
          className={cn('input-alt__button', {
            'input-alt__button_active': value.toString().length > 0
          })}
          color="transparent"
          onClick={handleClick}
        >
          {type == 'search' ? (
            <Icons.CloseCircle color="light" />
          ) : (
            <Icons.Close color="blue" size={24} />
          )}
        </Button>
      )}

      {type == 'search' && <Icons.Search />}
    </div>
  );
};

export default InputAlt;

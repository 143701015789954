import React from 'react';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {Attach as IAttach} from '../template-form-env';
import {DropzoneAlt, Wrapper} from 'components';

import Attach from './attach/attach';

const Attachs = () => {
  const [field, , helpers] = useField<IAttach[]>('attachs');
  const {t} = useTranslation();

  const handleChange = (files: File[]) => {
    const newValue = field.value.concat(files.map(file => ({file})));
    helpers.setValue(newValue);
  };

  const attachs = field.value.filter(attach => !attach.isDelete);

  return (
    <Wrapper direction="column" gap={8}>
      <DropzoneAlt
        multiple
        onChange={handleChange}
        placeholder={t`Attach files`}
      />

      {attachs.length > 0 && (
        <div>
          {field.value.map((attach, i) => (
            <Attach attach={attach} index={i} key={i} />
          ))}
        </div>
      )}
    </Wrapper>
  );
};

export default Attachs;

import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {
  Placeholder,
  Preloader,
  Table,
  TableBody,
  TableData,
  TableHead
} from 'components';
import {useDomainFilter} from '../hooks';
import {useRequest} from 'common/hooks';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

import Payment from './payment/payment';

const Payments = () => {
  const {user} = useWorkspaceContext();
  const [paymentList, setPaymentList] = useState<AppEnv.Payment[]>();

  const {domainFilter, fusedList, isDomain} = useDomainFilter(paymentList);
  const {fetchPayments} = useRequest();
  const {t} = useTranslation();

  useEffect(() => {
    document.title = `${t`Payment history`} - Whatcrm Web`;
  }, []);

  const getPaymentList = async () => {
    const {integration} = user;
    const res = await fetchPayments(integration.id);
    setPaymentList(res || []);
  };

  useEffect(() => {
    getPaymentList();
  }, []);

  const isDeleted = paymentList?.some(payment => payment.is_deleted) || false;

  return (
    <>
      {fusedList ? (
        <>
          {domainFilter}

          {fusedList.length ? (
            <Table>
              <TableHead>
                <TableData>
                  {t`Date`}
                  {` (UTC+3)`}
                </TableData>

                <TableData>{t`Rate plan`}</TableData>
                <TableData>{t`Period`}</TableData>
                <TableData>{t`Amount`}</TableData>
                {isDomain && <TableData>{t`Domain`}</TableData>}
                {isDeleted && <TableData />}
              </TableHead>

              <TableBody>
                {fusedList.map((payment, i) => (
                  <Payment
                    isDeleted={isDeleted}
                    isDomain={isDomain}
                    key={i}
                    payment={payment}
                  />
                ))}
              </TableBody>
            </Table>
          ) : (
            <Placeholder isMaxHeight title={t`No payment history`} />
          )}
        </>
      ) : (
        <Preloader isMaxHeight />
      )}
    </>
  );
};

export default Payments;

import * as AppEnv from 'app-env';

const getManager = (instance: AppEnv.Instance, dialog: AppEnv.ChatDialog) => {
  const {user_id} = instance.access_manager || {};
  const manager = dialog.managers?.find(manager => manager.id != user_id);

  return manager || null;
};

const getChatUser = (instance: AppEnv.Instance, dialog: AppEnv.ChatDialog) => {
  if (dialog.type == 'staff_chat') return getManager(instance, dialog);
  return dialog.user || null;
};

export default getChatUser;

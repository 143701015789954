import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

import {Button, Icons, PageMain} from 'components';
import WidgetForm from './widget-form/widget-form';

const WidgetCreate = () => {
  const [isActive, setIsActive] = useState(false);
  const {t} = useTranslation();

  return (
    <>
      <PageMain flexGrow={0}>
        <Button color="blue-dash" onClick={() => setIsActive(true)}>
          {t`Add widget`}
          <Icons.Plus color="blue" size={20} />
        </Button>
      </PageMain>

      <WidgetForm isActive={isActive} setIsActive={setIsActive} />
    </>
  );
};

export default WidgetCreate;

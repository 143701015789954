import React from 'react';
import {useTranslation} from 'react-i18next';
import upd from 'immutability-helper';

import {InputAlt, Text, Wrapper} from 'components';
import {useChatContext} from '../../../../chat-context';

const BottomMobile = () => {
  const {setWidget, widget} = useChatContext();
  const {t} = useTranslation();

  const handleChange = (value: string) =>
    setWidget(prevValue =>
      upd(prevValue, {bottom_mobile: {$set: value ? parseInt(value) : null}})
    );

  return (
    <div>
      <Text color="optional" size={12} style={{marginBottom: 4}}>
        {t`Margin bottom`}
      </Text>

      <Wrapper alignItems="center" flexWrap="nowrap" gap={8}>
        <InputAlt
          name="bottom_mobile"
          onChange={handleChange}
          type="number"
          value={
            typeof widget.bottom_mobile == 'number' ? widget.bottom_mobile : ''
          }
          width={88}
        />

        <Text size={14}>{`px`}</Text>
      </Wrapper>
    </div>
  );
};

export default BottomMobile;

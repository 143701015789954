import React from 'react';

interface ButtonColor {
  background: string;
  onClick: () => void;
}

const ButtonColor = ({background, onClick}: ButtonColor) => (
  <button
    className="button-color"
    onClick={onClick}
    style={{background}}
    type="button"
  />
);

export default ButtonColor;

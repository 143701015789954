import React, {useEffect, useRef, useState} from 'react';
import {useOnClickOutside, useScrollClassName} from 'whatcrm-core';
import cn from 'classnames';

import {getIsLimited} from 'common/actions';
import {useDispatchContext} from '../../../../dispatch-context';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import {Wrapper} from 'components';
import * as AppEnv from 'app-env';

import About from './about/about';
import Account from './account/account';
import Blacklist from './blacklist/blacklist';
import Closer from './closer/closer';
import Contact from './contact/contact';
import Labels from './labels/labels';
import Participants from './participants/participants';
import ProfileButtonGroup from './profile-button-group/profile-button-group';
import ProfileList from './profile-list/profile-list';
import Utm from './utm/utm';

interface Profile {
  setIsProfileActive: AppEnv.SetState<boolean>;
}

const Profile = ({setIsProfileActive}: Profile) => {
  const {user} = useWorkspaceContext();
  const {instance} = useDispatchContext();

  const [isActive, setIsActive] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const handleClose = () => {
    setIsActive(false);
    setTimeout(() => setIsProfileActive(false), 150);
  };

  useOnClickOutside(ref, {onClick: handleClose});

  useEffect(() => {
    setIsActive(true);
  }, []);

  const {integration} = user;
  const isLimited = getIsLimited(instance);
  const scrollClassName = useScrollClassName();

  return (
    <div
      className={cn('profile', {
        [scrollClassName]: scrollClassName,
        profile_active: isActive
      })}
      ref={ref}
    >
      <Closer onClick={handleClose} />

      <Wrapper direction="column" gap={8}>
        <Account />
        <ProfileButtonGroup />

        <Wrapper direction="column" gap={16}>
          {!isLimited &&
            ((process.env.REACT_APP_ENV != 'prod' &&
              process.env.REACT_APP_ENV != 'rocket') ||
              [
                'mfdanilovv@yandex.ru',
                'whatcrm.bitrix24.by',
                'whatcrmproject.amocrm.ru'
              ].includes(integration.domain)) && (
              <Wrapper direction="column" gap={8}>
                <Labels />
                <About />
              </Wrapper>
            )}

          <Contact />
          <ProfileList />
          <Utm />
          <Blacklist />
          <Participants />
        </Wrapper>
      </Wrapper>
    </div>
  );
};

export default Profile;

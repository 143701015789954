import React, {useEffect, useRef, useState} from 'react';
import {formatPhoneNumber} from 'whatcrm-core';

import {MessageText} from '../../../components';
import {ProfileImage, Wrapper} from 'components';
import {useDispatchContext} from 'pages/workspace/pages/messenger/dispatch/dispatch-context';
import {useIsVisible, useRequest} from 'common/hooks';

interface Vcard {
  name: string | undefined;
  phoneNumber: string | undefined;
}

interface Contact {
  vcard: Vcard;
}

const Contact = ({vcard}: Contact) => {
  const {instance} = useDispatchContext();
  const [profileImage, setProfileImage] = useState<string | null>();

  const {fetchProfileImage} = useRequest();
  const ref = useRef<HTMLDivElement>(null);
  const isVisible = useIsVisible(ref);

  const getVcardProfileImage = async () => {
    if (!vcard.phoneNumber) return;
    const res = await fetchProfileImage(instance.chat_key, vcard.phoneNumber);
    setProfileImage(res);
  };

  useEffect(() => {
    if (!isVisible && profileImage == undefined) getVcardProfileImage();
  }, [isVisible]);

  return (
    <Wrapper alignItems="center" gap={8} ref={ref}>
      <ProfileImage
        id={vcard.phoneNumber}
        name={vcard.name}
        src={profileImage != null ? profileImage : undefined}
      />

      <div>
        <MessageText>{vcard.name}</MessageText>

        {vcard.phoneNumber && (
          <MessageText isLight isSmall>
            {formatPhoneNumber(vcard.phoneNumber)}
          </MessageText>
        )}
      </div>
    </Wrapper>
  );
};

export default Contact;

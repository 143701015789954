import React from 'react';
import {formatPhoneNumber, useCopyToClipboard} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {ProfileListItem} from '../../components';
import {useAppContext} from 'app-context';
import {useDialogName} from 'pages/workspace/hooks';
import {useDispatchContext} from 'pages/workspace/pages/messenger/dispatch/dispatch-context';
import * as AppEnv from 'app-env';

import {useDialog} from './hooks';

interface Participant {
  participant: AppEnv.WhatsAppParticipant;
}

const Participant = ({participant}: Participant) => {
  const {setNotification} = useAppContext();
  const {instance} = useDispatchContext();

  const {t} = useTranslation();
  const copyToClipboard = useCopyToClipboard();
  const dialog = useDialog(participant);
  const name = useDialogName(instance, dialog);

  const handleClick = async () => {
    const res = await copyToClipboard(participant.id.user);
    setNotification({title: res.message});
  };

  const label: string[] = [];
  const isName = name && !name?.startsWith('+');
  const phoneNumber = formatPhoneNumber(participant.id.user);

  if (isName) label.push(phoneNumber);

  if (participant.isSuperAdmin) label.push(t`super admin`);
  else if (participant.isAdmin) label.push(t`admin`);

  return (
    <ProfileListItem
      key={participant.id._serialized}
      label={label.join(' • ')}
      type="copy"
      value={isName ? name : phoneNumber}
      onClick={handleClick}
    />
  );
};

export default Participant;

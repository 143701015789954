import {formatPhoneNumber} from 'whatcrm-core';

import {getDialogId, getIsLimited} from 'common/actions';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

import {getProfileNameAvito, getProfileNameChat} from './actions';

const useGetProfileName = () => {
  const {contactList} = useWorkspaceContext();

  const getContact = (
    instance: AppEnv.Instance,
    dialog: AppEnv.Dialog,
    isOriginal: boolean
  ) => {
    const isLimited = getIsLimited(instance);

    if (isLimited) return undefined;
    else if (isOriginal) return undefined;

    const contact = contactList?.find(
      contact => contact.chatId == getDialogId(dialog, true)
    );

    return contact;
  };

  const getProfileName = (
    instance: AppEnv.Instance,
    dialog: AppEnv.Dialog,
    isOriginal = false
  ) => {
    const contact = getContact(instance, dialog, isOriginal);

    if (dialog.version == 'avito')
      return getProfileNameAvito(instance, dialog, contact);
    else if (contact?.fullname) return contact.fullname;
    else if (dialog.version == 'chat')
      return getProfileNameChat(instance, dialog);
    else if (dialog.version == 'telegram') return dialog.name;
    else if (dialog.version == 'whatcrm') return formatPhoneNumber(dialog.name);

    return null;
  };

  return getProfileName;
};

export default useGetProfileName;

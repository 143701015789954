import React from 'react';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {Bullet, InputAutocomplete, InputAutocompleteOption} from 'components';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';

interface Option extends InputAutocompleteOption {
  color: string;
}

const Tag = () => {
  const {labelList} = useWorkspaceContext();

  const [, , colorHelpers] = useField<string>('color');
  const [field, meta, helpers] = useField<string>('tag');
  const {t} = useTranslation();

  const handleClick = (value: number) => {
    const label = labelList?.find(label => label.id == value);
    if (!label) return;

    colorHelpers.setValue(label.color);
  };

  const isValid = !(meta.error && meta.touched);

  const options = labelList?.reduce((acc, label) => {
    if (acc.some(item => item.color == label.color && item.label == label.tag))
      return acc;

    acc.push({
      color: label.color,
      icon: <Bullet background={label.color} />,
      label: label.tag,
      value: label.id
    });

    return acc;
  }, [] as Option[]);

  return (
    <InputAutocomplete
      {...field}
      autoCapitalize="off"
      autoFocus
      isValid={isValid}
      onChange={helpers.setValue}
      onClick={handleClick}
      options={options}
      placeholder={t('Name', {context: 'connection'})}
      style={{marginBottom: 16}}
    />
  );
};

export default Tag;

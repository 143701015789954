import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {getFromLocalStorage} from 'whatcrm-core';
import cn from 'classnames';

import {Placeholder} from 'components';
import {getDialogId} from 'common/actions';
import * as AppEnv from 'app-env';

import Chat from './chat/chat';

interface ChatProvider {
  dialog: AppEnv.Dialog | undefined;
}

const ChatProvider = ({dialog}: ChatProvider) => {
  const [wallpaperId, setWallpaperId] = useState<string>();
  const {t} = useTranslation();

  const updateWallpaperId = () => {
    const wallpaper = getFromLocalStorage('wallpaper');
    if (wallpaper) setWallpaperId(wallpaper);
  };

  useEffect(() => {
    updateWallpaperId();
  }, []);

  const style = wallpaperId
    ? {backgroundImage: `url('/static/img/wallpapers/${wallpaperId}.jpg')`}
    : undefined;

  return (
    <div
      className={cn('chat', {chat_placeholder: !dialog})}
      style={dialog ? style : undefined}
    >
      {dialog ? (
        <Chat
          dialog={dialog}
          key={getDialogId(dialog)}
          wallpaperId={wallpaperId}
          setWallpaperId={setWallpaperId}
        />
      ) : (
        <Placeholder
          isChat
          isMaxHeight
          text={`${t`Choose a chat to start messaging.`} ${t`You can add new connections in the widget.`}`}
          title="Whatcrm Web"
        />
      )}
    </div>
  );
};

export default ChatProvider;

import React from 'react';
import {useScrollClassName} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';
import cn from 'classnames';

import {getIsLimited} from 'common/actions';
import {useAppContext} from 'app-context';
import {useDispatchContext} from '../../dispatch-context';

import {FolderOption} from './folders-env';
import Folder from './folder/folder';

const Folders = () => {
  const {isIframe} = useAppContext();
  const {instance} = useDispatchContext();
  const {t} = useTranslation();

  const options: FolderOption[] = [{label: t`All`, value: null}];
  const isLimited = getIsLimited(instance);

  if (instance.version == 'telegram' || instance.version == 'whatcrm') {
    if (isIframe || !isLimited) {
      options.push({label: t`Direct`, value: 'direct'});
      options.push({label: t`Groups and channels`, value: 'groups'});
    }
  }

  if (instance.version == 'whatcrm') {
    options.push({label: t`Archive`, value: 'archive'});
  }

  const scrollClassName = useScrollClassName();

  return (
    <div className={cn('folders', {[scrollClassName]: scrollClassName})}>
      {options.map((option, i) => (
        <Folder folderOption={option} key={i} />
      ))}
    </div>
  );
};

export default Folders;

import {useTariffsContext} from '../../../tariffs-context';
import {useUrlParams} from 'common/hooks';

const useInitialInstanceId = () => {
  const {instances} = useTariffsContext();
  const urlParams = useUrlParams();

  const numberList = urlParams.instance_id?.split(',').map(Number);

  const fusedNumberList = numberList?.filter(item =>
    Object.values(instances || {}).some(instance => instance.id == item)
  );

  return fusedNumberList || [];
};

export default useInitialInstanceId;

import React, {useState} from 'react';
import cn from 'classnames';

import {getDialogId} from 'common/actions';
import {Text} from 'components';
import {useChatContext} from 'pages/workspace/pages/messenger/dispatch/chat-provider/chat/chat-context';
import {useDispatchContext} from 'pages/workspace/pages/messenger/dispatch/dispatch-context';
import {useMessageContext} from '../../../../message-context';
import {useRequest} from 'common/hooks';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';

export interface ReactionShape {
  count: number;
  emoji: string;
  isActive: boolean;
}

interface ReactionProps {
  reaction: ReactionShape;
}

const Reaction = ({reaction}: ReactionProps) => {
  const {user} = useWorkspaceContext();
  const {instance} = useDispatchContext();
  const {dialog} = useChatContext();
  const {message} = useMessageContext();

  const [isFetching, setIsFetching] = useState(false);
  const {postReaction} = useRequest();

  const getIsDisabled = () => {
    const {roles} = user.manager;

    if (dialog.version != 'whatcrm' && reaction.isActive) return true;
    else if (isFetching) return true;
    else if (roles.messages_reaction == false) return true;

    return false;
  };

  const handleClick = async (emoji: string) => {
    setIsFetching(true);

    const dialogId = getDialogId(dialog, true);
    if (!dialogId) return;

    await postReaction(instance.chat_key, dialogId, message.id, emoji);
    setIsFetching(false);
  };

  const isDisabled = getIsDisabled();

  return (
    <button
      className={cn('message__reactions-item', {
        'message__reactions-item_active': reaction.isActive
      })}
      disabled={isDisabled}
      onClick={() => handleClick(reaction.isActive ? '' : reaction.emoji)}
    >
      <Text size={16} fontWeight={600}>
        {reaction.emoji}
      </Text>

      <Text size={16} fontWeight={600}>
        {reaction.count}
      </Text>
    </button>
  );
};

export default Reaction;

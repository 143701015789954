import {jwtDecode} from 'jwt-decode';
import upd from 'immutability-helper';

import {useDispatchContext} from 'pages/workspace/pages/messenger/dispatch/dispatch-context';
import {useRequest} from 'common/hooks';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

const useGetChatManager = () => {
  const {getManagerList, responsibleList, setResponsibleList, user} =
    useWorkspaceContext();

  const {instance} = useDispatchContext();
  const {fetchWebAuth} = useRequest();

  const getChatManager = async (
    managerId: number
  ): Promise<AppEnv.ChatManager | null> => {
    const responsible = responsibleList.find(
      responsible =>
        responsible.instance_id == instance.id &&
        responsible.manager_id == managerId
    );

    if (responsible) responsible.manager;

    const managerList = await getManagerList();
    const manager = managerList.find(manager => manager.id == managerId);

    if (!manager) return null;

    const auth = await fetchWebAuth(manager.email, {code: '1111'});
    if (!auth) return null;

    const managerInstance = Object.values(auth.instances).find(
      item => item.id == instance.id
    );

    if (!managerInstance) return null;

    const {access_manager} = managerInstance;
    if (!access_manager) return null;

    const chatUser = access_manager.access_token
      ? jwtDecode<AppEnv.ChatUser>(access_manager.access_token)
      : null;

    if (!chatUser) return null;

    const {user_id} = instance.access_manager || {};
    if (!user_id) return null;

    const {integration} = user;

    const chatManager: AppEnv.ChatManager = {
      domain: integration.domain,
      email: manager.email,
      id: user_id,
      name: manager.fullname,
      status: 'offline',
      utm: {
        utm_campaign: '',
        utm_content: '',
        utm_medium: '',
        utm_source: '',
        utm_term: ''
      }
    };

    const newResponsible: AppEnv.Responsible = {
      manager: chatManager,
      instance_id: instance.id,
      manager_id: manager.id
    };

    setResponsibleList(prevValue => upd(prevValue, {$push: [newResponsible]}));
    return chatManager;
  };

  return getChatManager;
};

export default useGetChatManager;

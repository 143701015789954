import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import de from '../locales/de.json';
import en from '../locales/en.json';
import es from '../locales/es.json';
import id from '../locales/id.json';
import pt from '../locales/pt.json';
import ru from '../locales/ru.json';
import tr from '../locales/tr.json';
import uk from '../locales/uk.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    detection: {
      lookupLocalStorage: 'lang',
      lookupQuerystring: 'lang',
      order: ['querystring', 'localStorage', 'navigator']
    },
    fallbackLng: {
      br: ['pt'],
      by: ['ru'],
      la: ['es'],
      ua: ['uk'],
      default: ['en']
    },
    interpolation: {
      escapeValue: false
    },
    resources: {
      de: {translation: de},
      en: {translation: en},
      es: {translation: es},
      id: {translation: id},
      pt: {translation: pt},
      ru: {translation: ru},
      tr: {translation: tr},
      uk: {translation: uk}
    },
    supportedLngs: [
      'br',
      'by',
      'de',
      'en',
      'es',
      'id',
      'la',
      'pt',
      'ru',
      'tr',
      'uk'
    ]
  });

export default i18n;

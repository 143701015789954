import {useTranslation} from 'react-i18next';
import upd from 'immutability-helper';

import {getDialogId} from 'common/actions';
import {useAppContext} from 'app-context';
import {useDispatchContext} from 'pages/workspace/pages/messenger/dispatch/dispatch-context';
import {useMessengerContext} from 'pages/workspace/pages/messenger/messenger-context';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

const useToggleRead = (dialog: AppEnv.Dialog) => {
  const {setNotification} = useAppContext();
  const {setDialogs} = useMessengerContext();
  const {dialogList, instance} = useDispatchContext();

  const {fetchChatRead, fetchReadChat, fetchUnreadChat} = useRequest();
  const {t} = useTranslation();

  const request = (value: boolean) => {
    const id = getDialogId(dialog, true);
    if (!id) return null;

    if (!value) return fetchUnreadChat(instance.chat_key, id);
    else if (dialog.version == 'avito')
      return fetchChatRead(instance.chat_key, instance.instanceId, dialog.id);

    return fetchReadChat(instance.chat_key, id);
  };

  const toggleRead = async (value: boolean) => {
    const id = getDialogId(dialog, true);
    if (!id) return throwError(value);

    await request(value);

    const index = dialogList.findIndex(item => getDialogId(item, true) == id);
    if (index < 0) return throwError(value);

    setDialogs(prevValue =>
      upd(prevValue, {
        [instance.id]: {
          [index]:
            dialog.version == 'avito'
              ? {last_message: {read: {$set: 1}}}
              : dialog.version == 'chat'
                ? {last_message: {status: {$set: value ? 'read' : 'delivered'}}}
                : dialog.version == 'telegram'
                  ? value
                    ? {isMarkedAsUnread: {$set: false}, unreadCount: {$set: 0}}
                    : {isMarkedAsUnread: {$set: true}}
                  : dialog.version == 'whatcrm'
                    ? {unreadCount: {$set: value ? 0 : -1}}
                    : {}
        }
      })
    );
  };

  const throwError = (value: boolean) =>
    setNotification({
      title: value
        ? t`Failed to mark the chat as read`
        : t`Failed to mark the chat as unread`
    });

  return toggleRead;
};

export default useToggleRead;

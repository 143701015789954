import React, {useState} from 'react';
import {useFormikContext} from 'formik';

import {InstanceBilling} from 'components';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

import InstanceConfirm from './instance-confirm/instance-confirm';
import InstanceId from './instance-id/instance-id';

const Instance = () => {
  const {user} = useWorkspaceContext();
  const {values} = useFormikContext<AppEnv.Chat>();

  const [isActive, setIsActive] = useState(false);
  const {instances} = user;

  const instance = Object.values(instances).find(
    (instance: AppEnv.Instance) => instance.id == values.instance_id
  );

  return (
    <>
      <InstanceId />

      {instance && (
        <InstanceBilling
          instance={instance}
          onClick={() => setIsActive(true)}
        />
      )}

      <InstanceConfirm isActive={isActive} setIsActive={setIsActive} />
    </>
  );
};

export default Instance;

import React from 'react';
import {useTranslation} from 'react-i18next';
import cn from 'classnames';

import {BulletList, IconParams, Icons, Tooltip} from 'components';
import {Feat} from '../../tariff-env';
import {useTariffListContext} from '../../../tariff-list-context';
import * as AppEnv from 'app-env';

const iconParams: IconParams = {color: 'blue', size: 20};

interface FeatListItem extends Feat {
  tariffPlan: AppEnv.TariffPlan;
}

const FeatListItem = ({
  isChecked,
  isLimits,
  isSoon,
  name,
  tariffPlan,
  tooltip
}: FeatListItem) => {
  const {isChat} = useTariffListContext();
  const {t} = useTranslation();

  const limits = {
    other: {Enterprise: 200, Pro: 100, Start: 50},
    photo: {Enterprise: 64, Pro: isChat ? 16 : 32, Start: isChat ? 5 : 16},
    video: {Enterprise: 128, Pro: 64, Start: isChat ? 20 : 16}
  };

  const {other, photo, video} = limits;

  return (
    <li
      className={cn('feature-list__item', {
        'feature-list__item_inactive': !isChecked,
        'feature-list__item_tooltip': (isChecked && tooltip) || isLimits
      })}
    >
      {isChecked ? (
        <Icons.CheckAlt {...iconParams} />
      ) : (
        <Icons.CloseAlt {...iconParams} />
      )}

      <span>
        {name}

        {isSoon && (
          <>
            {' '}
            <div className="feature-list__item-badge">
              <span>{t`soon`}</span>
            </div>
          </>
        )}
      </span>

      {isChecked && tooltip && <Tooltip>{tooltip}</Tooltip>}

      {isLimits && (
        <Tooltip>
          <BulletList
            options={[
              t('Photos up to {{photo}} MB', {photo: photo[tariffPlan]}),
              t('Videos up to {{video}} MB after processing', {
                video: video[tariffPlan]
              }),
              t('Other files up to {{other}} MB', {
                other: other[tariffPlan]
              })
            ]}
          />
        </Tooltip>
      )}
    </li>
  );
};

export default FeatListItem;

import React from 'react';
import cn from 'classnames';

import {Icons, SelectOption, Text, Wrapper} from 'components';
import * as AppEnv from 'app-env';

interface SelectDropdownOption<T> {
  onChange: (value: T) => void;
  option: SelectOption<T>;
  setIsActive: AppEnv.SetState<boolean>;
  value: T | undefined;
}

const SelectDropdownOption = <T,>({
  onChange,
  option,
  setIsActive,
  value
}: SelectDropdownOption<T>) => {
  const handleClick = (value: T) => {
    onChange(value);
    setIsActive(false);
  };

  return (
    <button
      className={cn('select-dropdown__option', {
        'select-dropdown__option_active': option.value == value,
        'select-dropdown__option_plus': option.plus
      })}
      onClick={() => handleClick(option.value)}
      type="button"
    >
      <Wrapper
        alignItems="center"
        flexWrap="nowrap"
        gap={4}
        style={{minWidth: 0}}
      >
        <Wrapper className="select-dropdown__label" flexWrap="nowrap" gap={4}>
          {option.icon}

          <div style={{minWidth: 0}}>
            <Text isNoWrap size={16}>
              {option.label}
            </Text>

            {option.comment && (
              <Text color="secondary" isNoWrap size={12}>
                {option.comment}
              </Text>
            )}
          </div>
        </Wrapper>

        {option.plus}
      </Wrapper>

      {option.value == value && <Icons.Check size={20} />}
    </button>
  );
};

export default SelectDropdownOption;

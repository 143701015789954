import React, {useState} from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import cn from 'classnames';

import {useAppContext} from 'app-context';
import * as AppEnv from 'app-env';

import {Firewall} from './components';
import {
  useActiveInstances,
  useContactList,
  useDialogs,
  useFavicon,
  useGetManagerList,
  useIsSidebarActive,
  useLabels,
  useUpdateMessageBody
} from './hooks';
import Billing from './pages/billing/billing';
import Blocked from './pages/blocked/blocked';
import Connections from './pages/сonnections/connections';
import Header from './header/header';
import Integration from './pages/integration/integration';
import Messenger from './pages/messenger/messenger';
import PartnerProgram from './pages/partner-program/partner-program';
import Products from './pages/products/products';
import Settings from './pages/settings/settings';
import ShortUrls from './pages/short-urls/short-urls';
import Sidebar from './sidebar/sidebar';
import Staff from './pages/staff/staff';
import Templates from './pages/templates/templates';
import WebSocketProvider from './web-socket-provider/web-socket-provider';
import Widgets from './pages/widgets/widgets';
import WorkspaceContext from './workspace-context';

interface Workspace {
  setUser: AppEnv.SetState<AppEnv.User | null | undefined>;
  user: AppEnv.User;
}

const Workspace = ({setUser, user}: Workspace) => {
  const {isIframe} = useAppContext();

  const [connectorId, setConnectorId] = useState<number>();
  const [instanceReferer, setInstanceReferer] = useState<string>();

  const [responsibleList, setResponsibleList] = useState<AppEnv.Responsible[]>(
    []
  );

  const [contactList, setContactList] = useContactList(user);
  const activeInstances = useActiveInstances(user);
  const [dialogs, setDialogs] = useDialogs(user, activeInstances);
  const [isSidebarActive, setIsSidebarActive] = useIsSidebarActive();
  const [labelList, setLabelList] = useLabels(user);

  const {updateMessageBody, updateQuotedMsgBody} = useUpdateMessageBody(
    dialogs,
    setDialogs
  );

  const getManagerList = useGetManagerList(user);
  useFavicon(user, dialogs);

  const isWebSocket = !!dialogs && process.env.REACT_APP_TYPE == 'web';

  const to =
    process.env.REACT_APP_TYPE == 'app' ? '/connections' : '/messenger';

  return (
    <WorkspaceContext.Provider
      value={{
        activeInstances,
        connectorId,
        contactList,
        getManagerList,
        instanceReferer,
        labelList,
        responsibleList,
        setConnectorId,
        setContactList,
        setInstanceReferer,
        setLabelList,
        setResponsibleList,
        setUser,
        updateMessageBody,
        updateQuotedMsgBody,
        user
      }}
    >
      <div className={cn('workspace', {workspace_sidebar: isSidebarActive})}>
        <Header setIsSidebarActive={setIsSidebarActive} />
        <Sidebar dialogs={dialogs} setIsSidebarActive={setIsSidebarActive} />

        <Routes>
          <Route
            path="/billing/*"
            element={
              <Firewall keyof="billings_read">
                <Billing />
              </Firewall>
            }
          />

          <Route
            path="/blocked"
            element={
              <Firewall keyof="blacklists_read">
                <Blocked />
              </Firewall>
            }
          />

          <Route
            path="/connections/*"
            element={
              <Firewall keyof="instances_read">
                <Connections setDialogs={setDialogs} />
              </Firewall>
            }
          />

          <Route
            path="/integration/*"
            element={
              <Firewall keyof="integrations_read">
                <Integration />
              </Firewall>
            }
          />

          <Route
            path="/messenger/:instanceId?/:dialogId?"
            element={<Messenger dialogs={dialogs} setDialogs={setDialogs} />}
          />

          <Route
            path="/partner-program"
            element={
              <Firewall keyof="partners_read">
                <PartnerProgram />
              </Firewall>
            }
          />

          <Route
            path="/products/*"
            element={
              <Firewall keyof="products_read">
                <Products />
              </Firewall>
            }
          />

          <Route path="/settings/*" element={<Settings />} />
          <Route path="/short-urls" element={<ShortUrls />} />

          <Route
            path="/staff/*"
            element={
              <Firewall keyof="managers_read">
                <Staff />
              </Firewall>
            }
          />

          <Route
            path="/templates"
            element={
              <Firewall keyof="templates_read">
                <Templates />
              </Firewall>
            }
          />

          <Route
            path="/widgets/*"
            element={
              <Firewall keyof="widgets_read">
                <Widgets />
              </Firewall>
            }
          />

          <Route path="*" element={<Navigate replace={isIframe} to={to} />} />
        </Routes>
      </div>

      {isWebSocket && (
        <WebSocketProvider dialogs={dialogs} setDialogs={setDialogs} />
      )}
    </WorkspaceContext.Provider>
  );
};

export default Workspace;

import {useInstancesContext} from '../instances-context';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

interface ChatSpareData {
  label?: string;
}

const useChatSpare = () => {
  const {integration, onChange} = useInstancesContext();
  const {postChatSpare, postChatUpdate} = useRequest();

  const chatSpare = async (
    version: AppEnv.InstanceVersion,
    data?: ChatSpareData
  ) => {
    const res = await postChatSpare(
      integration.type,
      integration.domain,
      version,
      data?.label
    );

    if (!res) return null;
    else if (res.version == 'chat' || res.version == 'cloud')
      await postChatUpdate(res.chat_key, {phone: '671'});

    const instance: AppEnv.Instance = {
      ...res,
      instanceId: res.chat_id
    };

    onChange(instance);
    return instance;
  };

  return chatSpare;
};

export {useChatSpare};

import React from 'react';

import {Text} from 'components';
import {useDialogContext} from '../dialog-context';
import {useDialogName} from 'pages/workspace/hooks';
import {useDispatchContext} from 'pages/workspace/pages/messenger/dispatch/dispatch-context';

const Name = () => {
  const {instance} = useDispatchContext();
  const {dialog} = useDialogContext();

  const dialogName = useDialogName(instance, dialog);

  return (
    <Text
      className="dialog__name"
      color="black"
      fontWeight={600}
      isNoWrap
      size={14}
    >
      {dialogName}
    </Text>
  );
};

export default Name;

import React from 'react';
import {useTranslation} from 'react-i18next';

import {Backdrop, Preloader, SelectOption, Text} from 'components';
import * as AppEnv from 'app-env';

import SelectDropdownOption from './select-dropdown-option/select-dropdown-option';

interface SelectDropdown<T> {
  isActive: boolean;
  left: number;
  minWidth?: number;
  onChange: (value: T) => void;
  options: SelectOption<T>[] | undefined;
  setIsActive: AppEnv.SetState<boolean>;
  top: number;
  value: T | undefined;
  width?: number;
}

const SelectDropdown = <T,>({
  isActive,
  left,
  minWidth,
  onChange,
  options,
  setIsActive,
  top,
  value,
  width
}: SelectDropdown<T>) => {
  const {t} = useTranslation();

  return (
    <Backdrop
      isActive={isActive}
      setIsActive={setIsActive}
      style={{left, minWidth, top, width}}
    >
      <div className="select-dropdown">
        {options ? (
          options.length ? (
            options.map((option, i) => (
              <SelectDropdownOption
                key={i}
                onChange={onChange}
                option={option}
                setIsActive={setIsActive}
                value={value}
              />
            ))
          ) : (
            <Text color="secondary" textAlign="center">{t`No options`}</Text>
          )
        ) : (
          <Preloader />
        )}
      </div>
    </Backdrop>
  );
};

export default SelectDropdown;

import {useEffect, useRef, useState} from 'react';
import {IMessageEvent, w3cwebsocket} from 'websocket';

import {getBaseUrl, getMessageEventData} from 'common/actions';
import {useReconnectWs} from '../../hooks';
import {useWebSocketProviderContext} from 'pages/workspace/web-socket-provider/web-socket-context';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

import {
  Ack,
  Call,
  File,
  Reaction,
  UpdateMessageContent
} from './use-whatcrm-ws-env';
import useHandleAcks from './use-handle-acks';
import useHandleCalls from './use-handle-calls';
import useHandleFiles from './use-handle-files';
import useHandleMsgs from './use-handle-msgs/use-handle-msgs';
import useHandleReactions from './use-handle-reactions';

export interface Data {
  acks?: Ack[];
  calls?: Call[];
  chat_key: string;
  files?: File[];
  messages?: AppEnv.Message[] | UpdateMessageContent[];
  reactions?: Reaction[];
}

const useWhatcrmWs = () => {
  const {activeInstances} = useWorkspaceContext();
  const {dialogs} = useWebSocketProviderContext();

  const [channels, setChannels] = useState<string[]>([]);

  const handleAcks = useHandleAcks();
  const handleCalls = useHandleCalls();
  const handleFiles = useHandleFiles();
  const handleMsgs = useHandleMsgs();
  const handleReactions = useHandleReactions();
  const webSocket = useRef<w3cwebsocket | null>(null);

  const handleMessage = (messageEvent: IMessageEvent) => {
    const data = getMessageEventData<Data>(messageEvent);
    if (!data) return;

    const instance = activeInstances.find(
      instance => instance.chat_key == data.chat_key
    );

    if (!instance) return;
    else if (data.acks) handleAcks(instance, data.acks);
    else if (data.calls) handleCalls(instance, data.calls);
    else if (data.files) handleFiles(instance, data.files);
    else if (data.messages) handleMsgs(instance, data.messages);
    else if (data.reactions) handleReactions(instance, data.reactions);
  };

  const updateChannels = () => {
    const add: string[] = [];
    const chat_key = [...channels];

    activeInstances.forEach(instance => {
      if (chat_key.find(channel => channel == instance.chat_key)) return;
      else if (!['chat', 'telegram', 'whatcrm'].includes(instance.version))
        return;

      add.push(instance.chat_key);
      chat_key.push(instance.chat_key);
    });

    setChannels(chat_key);

    if (!add.length) return;
    webSocket.current?.send(JSON.stringify({chat_key: add, type: 'add'}));
  };

  const connectWs = () => {
    const {messagesUrl} = getBaseUrl();

    try {
      webSocket.current = new w3cwebsocket(messagesUrl);
      webSocket.current.binaryType = 'arraybuffer';
      webSocket.current.onclose = reconnectWs;
      webSocket.current.onmessage = handleMessage;
      webSocket.current.onopen = updateChannels;
    } catch {
      //
    }
  };

  useEffect(() => {
    if (webSocket.current) updateChannels();
  }, [activeInstances.length]);

  useEffect(() => {
    if (webSocket.current) webSocket.current.onmessage = handleMessage;
  }, [dialogs]);

  useEffect(() => {
    if (webSocket.current) webSocket.current.onopen = updateChannels;
  }, [channels]);

  useEffect(() => {
    connectWs();
  }, []);

  const reconnectWs = useReconnectWs(connectWs);
};

export default useWhatcrmWs;

import {getIsMessageOutgoing} from '..';
import * as AppEnv from 'app-env';

const getIsDialogUnread = (
  instance: AppEnv.Instance,
  dialog: AppEnv.Dialog
) => {
  if (dialog.version == 'avito')
    return dialog.last_message.direction == 'in' && !dialog.last_message.read;
  else if (dialog.version == 'chat') {
    const {last_message} = dialog;

    const isOutgoing = getIsMessageOutgoing(instance, dialog, last_message);
    if (isOutgoing) return false;

    return last_message.status != 'read';
  } else if (dialog.version == 'telegram')
    return !!dialog.unreadCount || dialog.isMarkedAsUnread;
  else if (dialog.version == 'whatcrm') return !!dialog.unreadCount;

  return false;
};

export default getIsDialogUnread;

import React, {useEffect, useState} from 'react';
import {formatPhoneNumber, useOnKeyDown} from 'whatcrm-core';
import {useField, useFormikContext} from 'formik';
import {useTranslation} from 'react-i18next';
import Fuse from 'fuse.js';
import moment from 'moment';

import {
  Button,
  Icons,
  InputAlt,
  Placeholder,
  Popup,
  PopupFooter,
  PopupWrapper,
  RadioAlt,
  Text,
  Title,
  Wrapper
} from 'components';
import {getDialogPhoneNumber, getIsLimited} from 'common/actions';
import {useChatContext} from '../../../../chat-context';
import {useChatFooterContext} from '../../../chat-footer-context';
import {useDispatchContext} from 'pages/workspace/pages/messenger/dispatch/dispatch-context';
import {useGetDialogName} from 'pages/workspace/hooks';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import {Values} from '../../../chat-footer-env';
import * as AppEnv from 'app-env';

const Template = () => {
  const {user} = useWorkspaceContext();
  const {instance} = useDispatchContext();
  const {dialog, telegramUser} = useChatContext();

  const {setIsButtonsFormActive, setTemplateState, templateState} =
    useChatFooterContext();

  const {setFieldValue} = useFormikContext<Values>();

  const [search, setSearch] = useState('');
  const [templateId, setTemplateId] = useState<number>();
  const [templateList, setTemplateList] = useState(user.templates);

  const [, , attachsHelpers] = useField<AppEnv.Attach[]>('attachs');
  const [, , bodyHelpers] = useField<string>('body');
  const [, , buttonsHelpers] = useField<AppEnv.Button[] | null>('buttons');
  const {t} = useTranslation();
  const getDialogName = useGetDialogName();

  const formatMessage = (message: string) => {
    const phoneNumber = getDialogPhoneNumber(dialog, telegramUser);

    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);

    return message
      .replaceAll('%CLIENT_NAME%', getDialogName(instance, dialog) || '')
      .replaceAll(
        '%CLIENT_NUMBER%',
        phoneNumber ? formatPhoneNumber(phoneNumber) : ''
      )
      .replaceAll('%COMPANY_NAME%', instance.name)
      .replaceAll('%COMPANY_NUMBER%', formatPhoneNumber(instance.phone))
      .replaceAll('%TODAY%', moment(today).format('L'))
      .replaceAll('%TOMORROW%', moment(tomorrow).format('L'));
  };

  useEffect(() => {
    if (!search) return setTemplateList(user.templates);

    const fuse = new Fuse(user.templates, {
      keys: ['message', 'title'],
      threshold: 0.25
    });

    const fusedTemplateList = fuse.search(search).map(item => item.item);
    setTemplateList(fusedTemplateList);
  }, [search]);

  useEffect(() => {
    if (!templateState.isActive) setTemplateId(undefined);
  }, [templateState.isActive]);

  const selectTemplate = () => {
    const isLimited = getIsLimited(instance);
    const template = user.templates.find(item => item.id == templateId);

    if (!template) return;
    else if (dialog.version == 'chat' && !isLimited && template.buttons) {
      if (template.files) {
        attachsHelpers.setValue(
          template.files.map(item => ({
            isValid: true,
            src: item.link,
            title: item.title,
            type: item.type || ''
          }))
        );
      }

      bodyHelpers.setValue(template.message);
      buttonsHelpers.setValue(JSON.parse(template.buttons));
      setIsButtonsFormActive(true);
    } else if (template.files) {
      attachsHelpers.setValue(
        template.files.map(item => ({
          isValid: true,
          src: item.link,
          title: item.title,
          type: item.type || ''
        }))
      );
    }

    setFieldValue('body', formatMessage(template.message));
    setTemplateState({isActive: false});
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key != 'Enter') return;
    selectTemplate();
  };

  useOnKeyDown(handleKeyDown, [templateId, templateState.isActive]);

  const handleClick = () => {
    setTemplateState({isActive: false});
    if (templateState.isFocus) setFieldValue('body', '/');
  };

  const options = templateList.map(item => ({
    label: (
      <Wrapper flexWrap="nowrap" justifyContent="space-between">
        <Wrapper
          alignItems="center"
          flexWrap="nowrap"
          gap={8}
          style={{overflow: 'hidden'}}
        >
          <Icons.Note />

          <div style={{overflow: 'hidden'}}>
            <Text size={16} fontWeight={600}>
              {item.title}
            </Text>

            <Text color="light" fontWeight={600} isNoWrap size={14}>
              {formatMessage(item.message)}
            </Text>
          </div>
        </Wrapper>

        <Wrapper flexWrap="nowrap" gap={8}>
          {(item.file_count || 0) > 0 && (
            <Wrapper
              alignItems="center"
              alignSelf="flex-start"
              flexWrap="nowrap"
            >
              <Icons.PaperClip color="green" size={16} />

              <Text color="green" size={14}>
                {item.file_count}
              </Text>
            </Wrapper>
          )}

          {item.buttons && JSON.parse(item.buttons).length > 0 && (
            <Wrapper
              alignItems="center"
              alignSelf="flex-start"
              flexWrap="nowrap"
            >
              <Icons.ChartBarHorizontal color="green" size={16} />

              <Text color="green" size={14}>
                {JSON.parse(item.buttons).length}
              </Text>
            </Wrapper>
          )}
        </Wrapper>
      </Wrapper>
    ),
    value: item.id
  }));

  return (
    <Popup width={408} isActive={templateState.isActive} onClick={handleClick}>
      <PopupWrapper>
        <Title.H3 style={{marginBottom: 16}}>{t`Select a template`}</Title.H3>

        {user.templates.length ? (
          <Wrapper direction="column" gap={16}>
            <InputAlt
              autoCapitalize="off"
              autoFocus
              name="new-search"
              onChange={setSearch}
              placeholder={t`Search`}
              type="search"
              value={search}
            />

            {options.length > 0 && (
              <RadioAlt
                options={options}
                value={templateId}
                isFocus={templateState.isFocus}
                onChange={setTemplateId}
                onSubmit={selectTemplate}
              />
            )}
          </Wrapper>
        ) : (
          <Placeholder title={null} />
        )}
      </PopupWrapper>

      <PopupFooter>
        <Wrapper gap={12}>
          <Button
            onClick={selectTemplate}
            state={typeof templateId == 'undefined' ? 'disabled' : undefined}
          >
            {t`Select`}
          </Button>

          <Button color="fade" onClick={handleClick}>
            {t`Close`}
          </Button>
        </Wrapper>
      </PopupFooter>
    </Popup>
  );
};

export default Template;

import React from 'react';
import {allTimezones, useTimezoneSelect} from 'react-timezone-select';
import {useTranslation} from 'react-i18next';
import upd from 'immutability-helper';

import {Select} from 'components';
import {useChatContext} from '../../../../chat-context';

const Timezone = () => {
  const {setWidget, widget} = useChatContext();
  const {t} = useTranslation();

  const handleChange = (value: string) =>
    setWidget(prevValue => upd(prevValue, {timezone: {$set: value}}));

  const {options} = useTimezoneSelect({
    currentDatetime: new Date(),
    displayValue: 'UTC',
    timezones: allTimezones
  });

  return (
    <Select
      onChange={handleChange}
      options={options}
      placeholder={t`Time zone`}
      value={widget.timezone}
    />
  );
};

export default Timezone;

import {useTranslation} from 'react-i18next';
import {formatPhoneNumber, getCountryName} from 'whatcrm-core';
import mime from 'mime';
import moment from 'moment';
import parsePhoneNumber from 'libphonenumber-js';

import {getIsVoice, getTime} from 'common/actions';
import * as AppEnv from 'app-env';

const useGetMessagePreview = () => {
  const {i18n, t} = useTranslation();
  const plug = t`The message is not supported on this version.`;

  const getMessagePreview = (message: AppEnv.AnyMessage | undefined) => {
    if (!message) return undefined;

    if ('sender_id' in message) {
      if (message.type == '') return '';
      else if (message.type == 'file') {
        const type = mime.getType(message.file_url);

        if (type?.startsWith('audio')) return `🎤 ${t`Voice message`}`;
        else if (type?.startsWith('image')) return `🖼 ${t`Photo`}`;
        else if (type?.startsWith('video')) return `📼 ${t`Video`}`;

        return `📎 ${t`File`}`;
      } else if (message.type == 'message') return message.content;
    } else if ('author_id' in message || 'fromUid' in message) {
      const {text} = message.content;
      const isVoice = getIsVoice(text || '');

      switch (message.type) {
        case 'appCall':
          return '☎️ Пропущенный звонок\nПозвонить';
        case 'call':
          return `☎️ ${
            message.content.call?.status == 'missed'
              ? 'Пропущенный звонок'
              : 'Звонок'
          }`;
        case 'deleted':
          return text;
        case 'image':
          return `🖼 ${t`Photo`}`;
        case 'link':
          return message.content.link?.text || plug;
        case 'location':
          return `📍 ${message.content.location?.title || t`Location`}`;
        case 'system':
          return text?.replaceAll('[Системное сообщение] ', '');
        case 'text':
          if (isVoice) return `🎤 ${t`Voice message`}`;
          return text;
        case 'video':
          return `📼 ${t`Video`}`;
        case 'voice':
          return `🎤 ${t`Voice message`}`;
      }
    } else if ('senderId' in message) {
      if (message._ == 'story') return `📘 ${t`Story`}`;

      switch (message.type) {
        case 'animatedEmoji':
          if (message.content.emoji) {
            return message.content.emoji;
          }

          break;
        case 'animation':
          return `👾 ${message.content.caption?.text || t`Animation`}`;
        case 'audio':
          return `🎶 ${message.content.caption?.text || t`Audio`}`;
        case 'basicGroupChatCreate':
          return `🏘️ ${t`The group has been created`}`;
        case 'call':
          return `☎️ ${
            message.content.isVideo
              ? message.content.duration
                ? `${t`Video call`} (${getTime(message.content.duration)})`
                : t`Canceled video call`
              : message.content.duration
                ? `${t`Voice call`} (${getTime(message.content.duration)})`
                : t`Canceled voice call`
          }`;
        case 'chatAddMembers':
          return `➕ ${t`A new member joined the group via invitation`}`;
        case 'chatChangePhoto':
          return `🌆 ${t`The group photo has been changed`}`;
        case 'chatChangeTitle':
          return `🏘️ ${t('The group has been renamed to "{{title}}"', {
            title: message.content.title
          })}`;
        case 'chatDeleteMember':
          return `➖ ${t`A member left the group`}`;
        case 'chatJoinByLink':
          return `➕ ${t`A new member joined the group via invite link`}`;
        case 'chatUpgradeFrom':
          return `🏘️ ${message.content.title}`;
        case 'contact':
          return `👤 ${t`Contact`}`;
        case 'contactRegistered':
          return `✈️ ${t`The contact joined the Telegram`}`;
        case 'document':
          if ('content' in message) {
            return `📎 ${
              message.content.caption?.text ||
              message.content.document?.fileName
            }`;
          }

          break;
        case 'forumTopicCreated':
          return `🧵 ${t('New topic "{{name}}"', {
            name: message.content.name
          })}`;
        case 'forumTopicEdited':
          return `🧵 ${t('The topic has been renamed to "{{name}}"', {
            name: message.content.name
          })}`;
        case 'invoice':
          return `🧾 ${message.content.title}`;
        case 'location':
          return `📍 ${t`Location`}`;
        case 'paymentSuccessful':
          return `${message.content.isRecurring ? t`Subscription` : t`Payment`} ${message.content.totalAmount ? (message.content.totalAmount / 100).toLocaleString(i18n.resolvedLanguage, {currency: message.content.currency, style: 'currency'}) : (0).toLocaleString(i18n.resolvedLanguage, {currency: message.content.currency, style: 'currency'})}`;
        case 'photo':
          return `🖼 ${message.content.caption?.text || t`Photo`}`;
        case 'poll':
          return `📊 ${message.content.poll?.question}`;
        case 'premiumGiveaway':
          return t`🎁 Telegram Premium giveaway`;
        case 'premiumGiveawayCreated':
          return t`🎁 The giveaway of Telegram Premium subscriptions has just started for its followers`;
        case 'sticker':
          return `${message.content.sticker?.emoji} ${t`Sticker`}`;
        case 'supergroupChatCreate':
          return `📣 ${t`The channel has been created`}`;
        case 'text':
          if (message.content.text) {
            return message.content.text?.text;
          }

          break;
        case 'venue':
          return `📍 ${t`Location`}`;
        case 'video':
          if ('content' in message) {
            return `📼 ${message.content.caption?.text || t`Video`}`;
          }

          break;
        case 'videoChatEnded':
          return `💬 ${t`Video chat has ended`} (${getTime(
            message.content.duration || 0
          )})`;
        case 'videoChatStarted':
          return `💬 ${t`Video chat has started`}`;
        case 'videoNote':
          return `📹 ${t`Video message`}`;
        case 'voiceNote':
          return `🎤 ${message.content.caption?.text || t`Voice message`}`;
      }
    } else if ('from' in message || 'pollOptions' in message) {
      switch (message.type) {
        case 'audio':
          return `🎤 ${t`Voice message`}`;
        case 'buttons_response':
          return message.body;
        case 'call_log':
          if (message._data.callOutcome == 'Missed')
            return message._data.isVideoCall
              ? `☎️ ${t`Missed video call`}`
              : `☎️ ${t`Missed voice call`}`;

          return message._data.isVideoCall
            ? `☎️ ${t`Video call`}`
            : `☎️ ${t`Voice call`}`;
        case 'calls':
          return `☎️ ${message.isVideo ? t`Video call` : t`Voice call`}`;

        case 'chat':
          return message.body || message.caption;
        case 'ciphertext':
          return `⏳ ${t`Waiting for the message. This may take a while.`}`;
        case 'document':
          return `📎 ${message.caption || message._data.filename}`;
        case 'e2e_notification':
          return `🔒 ${t`Messages and calls are end-to-end encrypted. No one outside of this chat, not even WhatsApp, can read or listen to them.`}`;
        case 'gp2':
          if ('_data' in message && message._data) {
            switch (message._data.subtype) {
              case 'add':
                if (message._data.id.participant) {
                  const subject = message._data.author
                    ? formatPhoneNumber(message._data.author.user)
                    : undefined;

                  const object = formatPhoneNumber(
                    message._data.id.participant.user
                  );

                  if (subject)
                    return `➕ ${t('{{subject}} added {{object}}', {
                      subject,
                      object
                    })}`;

                  return `➕ ${t('{{object}} has been added to the group', {
                    object
                  })}`;
                }

                break;
              case 'auto_add':
                return `➕ ${t`... joined the community`}`;
              case 'community_create':
                return `🏘️ ${t`The community has been created`}`;
              case 'community_participant_add_rich':
                return `🏘️ ${t`Welcome to the community!`}`;
              case 'create':
                if (message._data.author) {
                  const subject = formatPhoneNumber(message._data.author?.user);
                  return `🏘️ ${t(`{{subject}} created the group`, {subject})}`;
                }

                break;
              case 'invite':
                if (message._data.author) {
                  const subject = formatPhoneNumber(message._data.author?.user);

                  return `➕ ${t(
                    `{{subject}} joined the group via invite link`,
                    {
                      subject
                    }
                  )}`;
                }

                break;
              case 'leave':
                return `➖ ${t`You are no longer a member of this group`}`;
              case 'membership_approval_mode':
                return `☑️ ${t`New members must receive confirmation from the admin to join this group.`}`;
              case 'picture':
                return `🌆 ${t('{{subject}} changed the group’s icon', {
                  subject: formatPhoneNumber(message._data.author?.user || '')
                })}`;
              case 'promote':
                return `💼 ${t`Now you are an admin`}`;
              case 'remove':
                if (message._data.author && message._data.id.participant) {
                  const subject = formatPhoneNumber(message._data.author?.user);

                  const object = formatPhoneNumber(
                    message._data.id.participant.user
                  );

                  return `➖ ${t(`{{subject}} removed {{object}}`, {
                    subject,
                    object
                  })}`;
                }

                break;
              case 'subject':
                return `🏘️ ${t(`The group has been renamed to {{subject}}`, {
                  subject: message._data.body
                })}`;
            }
          }

          break;
        case 'image':
          return `🖼 ${message.caption || t`Photo`}`;
        case 'list':
          return message._data.list?.description;
        case 'location':
          return `📍 ${t`Location`}`;
        case 'multi_vcard':
          return `👥 ${t`Contacts`}`;
        case 'notification_template':
          if (message._data.subtype == 'biz_privacy_mode_init_fb') {
            return `🔵 ${t`This business uses a secure service from Meta to manage this chat`}${
              i18n.resolvedLanguage == 'ru'
                ? '. *Организация Meta признана экстремистской и запрещена на территории Российской Федерации.'
                : ''
            }`;
          } else if (message._data.subtype == 'biz_privacy_mode_to_bsp') {
            return `🖇️ ${t`This business works with other companies to manage this chat.`}`;
          } else if (message._data.subtype == 'biz_privacy_mode_to_fb') {
            return `🔵 ${t`This business uses a secure service from Meta to manage this chat`}${
              i18n.resolvedLanguage == 'ru'
                ? '. *Организация Meta признана экстремистской и запрещена на территории Российской Федерации.'
                : ''
            }`;
          } else if (message._data.subtype == 'block_contact') {
            const templateParam = message._data.templateParams?.[0];
            if (typeof templateParam != 'string') break;

            return templateParam == 'true'
              ? `🔒 ${t`You have blocked this contact`}`
              : `🔓 ${t`You have unblocked this contact`}`;
          } else if (message._data.subtype == 'change_number') {
            const [templateParam] = message._data.templateParams || [];

            if (typeof templateParam != 'object')
              return `📲 ${t`User has changed his phone number`}`;

            const phoneNumber = formatPhoneNumber(templateParam.user);

            return `📲 ${t(
              'User has changed his phone number to {{phoneNumber}}',
              {phoneNumber}
            )}`;
          } else if (message._data.subtype == 'contact_info_card') {
            const templateParam = message._data.templateParams?.[0];
            if (typeof templateParam != 'object') break;

            const res = [formatPhoneNumber(templateParam.user)];
            if ('chatName' in message) res.push(message.chatName);

            const countryCode = parsePhoneNumber(
              `+${templateParam.user}`
            )?.country;

            if (countryCode) {
              const country = getCountryName(
                countryCode,
                i18n.resolvedLanguage
              );
              if (country) res.push(country);
            }

            return `📇 ${res.join(' • ')}`;
          } else if (message._data.subtype == 'disappearing_mode_update') {
            return `⏱️ ${t`Disappearing messages mode has been updated`}`;
          } else if (message._data.subtype == 'disappearing_mode') {
            const date =
              typeof message._data.templateParams?.[0] == 'string'
                ? parseInt(message._data.templateParams?.[0])
                : undefined;

            const sentence = date
              ? t(
                  'New unsaved messages will disappear {{from}} after they are sent.',
                  {from: moment.unix(date).from(0)}
                )
              : undefined;

            return `⏱️ ${t`An automatic timer for disappearing messages has been enabled.`}${
              sentence ? ` ${sentence}` : ''
            }`;
          }

          break;
        case 'order':
          return `🛒 ${t`Order`}`;
        case 'poll_creation':
          return `📊 ${message.body}`;
        case 'product':
          return `🛒 ${message.title}`;
        case 'protocol':
          if (message._data.subtype == 'ephemeral_setting') {
            const subject = formatPhoneNumber(message.from);

            return message._data.ephemeralDuration
              ? `📭 ${t('{{subject}} turned on the disappearing messages', {
                  subject
                })}`
              : `📬 ${t('{{subject}} turned off the disappearing messages', {
                  subject
                })}`;
          }

          break;
        case 'ptt':
          return `🎤 ${t`Voice message`}`;
        case 'ptv':
          return `📹 ${t`Video message`}`;
        case 'revoked':
          return `🚫 ${t`Deleted message`}`;
        case 'sticker':
          return `📑 ${t`Sticker`}`;
        case 'unknown':
          if (message._data.futureproofType == 'album')
            return `📁 ${t`Media files`}`;

          break;
        case 'vcard':
          return `👤 ${t`Contact`}`;
        case 'video':
          return `📼 ${message.caption || t`Video`}`;
      }
    }

    return plug;
  };

  return getMessagePreview;
};

export default useGetMessagePreview;

import * as AppEnv from 'app-env';

const getProfileNameAvito = (
  instance: AppEnv.Instance,
  dialog: AppEnv.AvitoDialog,
  contact: AppEnv.Contact | undefined
) => {
  const {me} = instance;
  const user = dialog.users.find(user => user.id != me?.id);
  const name = contact?.fullname || user?.name;

  return name || null;
};

export default getProfileNameAvito;

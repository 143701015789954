import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

import {Button, Icons, Wrapper} from 'components';
import {ContactForm, DeleteDialog, Wallpapers} from '../../components';
import {getIsLimited} from 'common/actions';
import {useChatContext} from '../../../chat-context';
import {useDispatchContext} from 'pages/workspace/pages/messenger/dispatch/dispatch-context';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';

const ProfileButtonGroup = () => {
  const {contactList, user} = useWorkspaceContext();
  const {instance} = useDispatchContext();
  const {dialog} = useChatContext();

  const [isContactFormActive, setIsContactFormActive] = useState(false);
  const [isDeleteDialogActive, setIsDeleteDialogActive] = useState(false);
  const [isWallpapersActive, setIsWallpapersActive] = useState(false);

  const {t} = useTranslation();

  const {integration} = user;
  const {roles} = user.manager;
  const isLimited = getIsLimited(instance);

  const isEdit =
    contactList != undefined &&
    !isLimited &&
    roles.dialogs_contacts != false &&
    ((process.env.REACT_APP_ENV != 'prod' &&
      process.env.REACT_APP_ENV != 'rocket') ||
      [
        'mfdanilovv@yandex.ru',
        'whatcrm.bitrix24.by',
        'whatcrmproject.amocrm.ru'
      ].includes(integration.domain));

  return (
    <>
      <Wrapper justifyContent="center">
        <Button
          color="transparent"
          onClick={() => setIsWallpapersActive(true)}
          tooltip={t`Change the wallpaper`}
        >
          <Icons.PaintBrush color="light" size={20} />
        </Button>

        {isEdit && (
          <Button
            color="transparent"
            onClick={() => setIsContactFormActive(true)}
            tooltip={t`Edit`}
          >
            <Icons.PencilSimple color="light" size={20} />
          </Button>
        )}

        <Button
          color="transparent"
          onClick={() => setIsDeleteDialogActive(true)}
          tooltip={
            dialog.version == 'telegram' ? t`Delete chat` : t`Clear chat`
          }
        >
          <Icons.TrashSimple color="light" size={20} />
        </Button>
      </Wrapper>

      <ContactForm
        isActive={isContactFormActive}
        setIsActive={setIsContactFormActive}
      />

      <Wallpapers
        isActive={isWallpapersActive}
        setIsActive={setIsWallpapersActive}
      />

      <DeleteDialog
        isActive={isDeleteDialogActive}
        setIsActive={setIsDeleteDialogActive}
      />
    </>
  );
};

export default ProfileButtonGroup;

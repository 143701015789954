import {useTranslation} from 'react-i18next';
import {useCopyToClipboard} from 'whatcrm-core';

import {ProfileListItemParams} from '../../../../components';
import {useAppContext} from 'app-context';
import {useChatContext} from '../../../../../../chat-context';

const useLocation = (): ProfileListItemParams | null => {
  const {setNotification} = useAppContext();
  const {dialog} = useChatContext();

  const {t} = useTranslation();
  const copyToClipboard = useCopyToClipboard();

  const handleClick = async (str: string) => {
    const res = await copyToClipboard(str);
    setNotification({title: res.message});
  };

  if (dialog.version != 'avito') return null;

  const {value} = dialog.context;
  if (!value.location) return null;

  const {location} = value;

  const locationItem: ProfileListItemParams = {
    label: t`location`,
    onClick: () => handleClick(location.title),
    type: 'copy',
    value: location?.title
  };

  return locationItem;
};

export default useLocation;

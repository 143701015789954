import React from 'react';
import {Form, Formik} from 'formik';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';
import upd from 'immutability-helper';

import {
  Button,
  Popup,
  PopupFooter,
  PopupWrapper,
  SaveButton,
  Text,
  Title,
  Wrapper
} from 'components';
import {useRequest} from 'common/hooks';
import {useStaffContext} from '../../../staff-context';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

import NewId from './new-id/new-id';

interface RoleDelete {
  isDeleteActive: boolean;
  role: AppEnv.Role;
  setIsDeleteActive: AppEnv.SetState<boolean>;
}

interface Values {
  new_id: number | undefined;
}

const validationSchema = yup.object().shape({new_id: yup.number().required()});

const RoleDelete = ({isDeleteActive, role, setIsDeleteActive}: RoleDelete) => {
  const {user} = useWorkspaceContext();
  const {roleList, setRoleList} = useStaffContext();

  const {fetchRolesDelete} = useRequest();
  const {t} = useTranslation();

  const handleSubmit = async (values: Values) => {
    if (!values.new_id) return;

    const {integration} = user;
    const res = await fetchRolesDelete(integration.id, role.id, values.new_id);
    if (!res) return;

    setIsDeleteActive(false);
    await new Promise(resolve => setTimeout(resolve, 150));

    setRoleList(prevValue => {
      const index = prevValue?.findIndex(item => item.id == role.id);
      if (index == undefined || index < 0) return prevValue;

      return upd(prevValue, {$splice: [[index, 1]]});
    });
  };

  const initialValues = {
    new_id: roleList?.length == 1 ? roleList[0].id : undefined
  };

  return (
    <Popup
      isActive={isDeleteActive}
      onClick={() => setIsDeleteActive(false)}
      width={408}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validationSchema={validationSchema}
      >
        <Form>
          <PopupWrapper>
            <Title.H3 style={{marginBottom: 16}}>
              {t`Delete this role?`}
            </Title.H3>

            <Text color="secondary" style={{marginBottom: 8}}>
              {t`Select a new role for staff members`}
            </Text>

            <NewId role={role} />
          </PopupWrapper>

          <PopupFooter>
            <Wrapper gap={12}>
              <SaveButton>{t`Delete`}</SaveButton>

              <Button color="fade" onClick={() => setIsDeleteActive(false)}>
                {t`Close`}
              </Button>
            </Wrapper>
          </PopupFooter>
        </Form>
      </Formik>
    </Popup>
  );
};

export default RoleDelete;

import React from 'react';

import {Wrapper} from 'components';
import * as AppEnv from 'app-env';

import ReplyButton from './reply-button/reply-button';

interface ReplyMarkup {
  msg: AppEnv.TelegramMessage;
}

const ReplyMarkup = ({msg}: ReplyMarkup) => {
  const {replyMarkup} = msg;

  return (
    <Wrapper direction="column" gap={8}>
      {replyMarkup?.rows?.map((buttons, i) => (
        <div
          className="reply-markup"
          key={i}
          style={{gridTemplateColumns: `repeat(${buttons.length}, 1fr)`}}
        >
          {buttons.map((button, j) => (
            <ReplyButton button={button} key={j} />
          ))}
        </div>
      ))}
    </Wrapper>
  );
};

export default ReplyMarkup;

import React from 'react';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {Switch} from 'components';

const AllowMultipleAnswers = () => {
  const [field, , helpers] = useField<boolean>('allowMultipleAnswers');
  const {t} = useTranslation();

  return (
    <Switch
      {...field}
      label={t`Allow multiple answers`}
      isAlt
      onChange={helpers.setValue}
    />
  );
};

export default AllowMultipleAnswers;

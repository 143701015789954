import React from 'react';
import cn from 'classnames';
import Color from 'color';

import {Text} from 'components';

interface Tag {
  background?: string;
  children?: React.ReactNode;
  isCount?: boolean;
  order?: number;
  size?: 12 | 16;
  tag: string;
  visibility?: 'hidden';
}

const Tag = ({
  background = '#34aadf',
  children,
  isCount,
  order,
  size = 12,
  tag,
  visibility
}: Tag) => {
  const color = new Color(background);
  const isDark = color.darken(0.28).isDark();

  return (
    <div
      className={cn('tag', {tag_count: isCount, tag_dark: isDark})}
      style={{background, order, visibility}}
    >
      <Text isNoWrap size={size}>
        {tag}
      </Text>

      {children}
    </div>
  );
};

export default Tag;

import React from 'react';

import {FeatListItem} from '../components';
import {useTariffsContext} from '../../../tariffs-context';
import * as AppEnv from 'app-env';

import {useFeatList} from './hooks';

interface FeatureList {
  tariffPlan: AppEnv.TariffPlan;
  tariffs: AppEnv.Tariffs;
}

const FeatList = ({tariffPlan, tariffs}: FeatureList) => {
  const {integration} = useTariffsContext();

  const featList = useFeatList(
    integration.type,
    tariffPlan,
    'Enterprise' in tariffs
  );

  return (
    <ul className="feature-list">
      {featList.map((feat, i) => (
        <FeatListItem {...feat} key={i} tariffPlan={tariffPlan} />
      ))}
    </ul>
  );
};

export default FeatList;

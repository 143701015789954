import React from 'react';
import {useTranslation} from 'react-i18next';
import cn from 'classnames';
import moment from 'moment';

import {Button, Text} from 'components';
import {useInstanceBilling} from 'common/hooks';
import * as AppEnv from 'app-env';

interface InstanceBilling {
  instance: AppEnv.Instance;
  onClick: () => void;
}

const InstanceBilling = ({instance, onClick}: InstanceBilling) => {
  const {t} = useTranslation();
  const billing = useInstanceBilling(instance);

  return (
    <div
      className={cn('instance-billing', {
        'instance-billing_unpaid': billing.value == 'unpaid'
      })}
    >
      <Text fontWeight={500} size={16}>
        {billing.tariff_plane || billing.label}

        {typeof billing.date == 'number' && (
          <>
            {' '}
            {t`until`} {moment.unix(billing.date).format('L')}
          </>
        )}
      </Text>

      {billing.value != 'premium' && (
        <Button color="green-border" minHeight={40} onClick={onClick}>
          {billing.value == 'paid' ? t`Change` : t`Pay`}
        </Button>
      )}
    </div>
  );
};

export default InstanceBilling;

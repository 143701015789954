import React from 'react';
import {useTranslation} from 'react-i18next';

import {
  Button,
  OneTimePassWord,
  PopupFooter,
  PopupWrapper,
  Text
} from 'components';
import {Label} from '../../../components';
import {useConnectorContext} from '../../connector-context';
import * as AppEnv from 'app-env';

interface Code {
  code: string;
  setCode: AppEnv.SetState<string | undefined>;
  setIsQrCodeMethod: AppEnv.SetState<boolean>;
}

const Code = ({code, setCode, setIsQrCodeMethod}: Code) => {
  const {getStatus} = useConnectorContext();
  const {t} = useTranslation();

  const handleClick = async () => {
    getStatus(1);
    setCode(undefined);
    setIsQrCodeMethod(true);
  };

  return (
    <>
      <PopupWrapper>
        <Label style={{marginBottom: 8}} />

        <Text size={16} style={{marginBottom: 16}}>
          {t`Enter this code in WhatsApp`}
        </Text>

        <OneTimePassWord>
          <span>{code.slice(0, 4)}</span>
          <span>{code.slice(4)}</span>
        </OneTimePassWord>
      </PopupWrapper>

      <PopupFooter>
        <Button color="fade" onClick={handleClick}>
          {t`Close`}
        </Button>
      </PopupFooter>
    </>
  );
};

export default Code;

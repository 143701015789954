import React, {useState} from 'react';
import * as AppEnv from 'app-env';
import DomainFilter from './domain-filter/domain-filter';

const useDomainFilter = <T extends AppEnv.Payment[] | AppEnv.Subscription[]>(
  list: T | undefined
) => {
  const [domain, setDomain] = useState('');

  const domains = [...(list || [])]?.reduce(
    (acc: string[], item: AppEnv.Payment | AppEnv.Subscription) => {
      if (acc.includes(item.domain)) return acc;
      acc.push(item.domain);

      return acc;
    },
    []
  );

  const domainFilter = (
    <DomainFilter domain={domain} domains={domains} setDomain={setDomain} />
  );

  const fusedList = (
    domain && list
      ? [...list]?.filter(
          (item: AppEnv.Payment | AppEnv.Subscription) => item.domain == domain
        )
      : list
  ) as T | undefined;

  const isDomain = !!domains && domains.length > 1;
  return {domainFilter, fusedList, isDomain};
};

export default useDomainFilter;

import React from 'react';
import {getFromLocalStorage} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {Advice, Button, Icons, InputAlt, Link, Text, Wrapper} from 'components';
import {getIsLimited} from 'common/actions';
import {useDispatchContext} from '../../dispatch-context';
import * as AppEnv from 'app-env';

import Labels from './labels/labels';
import ManagerFilter from './manager-filter/manager-filter';

interface Header {
  isUnreadFilter: boolean;
  labels: number[];
  managers: string[];
  search: string;
  setIsUnreadFilter: AppEnv.SetState<boolean>;
  setLabels: AppEnv.SetState<number[]>;
  setManagers: AppEnv.SetState<string[]>;
  setSearch: AppEnv.SetState<string>;
}

const Header = ({
  isUnreadFilter,
  labels,
  managers,
  search,
  setIsUnreadFilter,
  setLabels,
  setManagers,
  setSearch
}: Header) => {
  const {instance} = useDispatchContext();
  const {i18n, t} = useTranslation();

  const isLimited = getIsLimited(instance);

  return (
    <div className="dialogs__header">
      {!getFromLocalStorage('feedback-2025-01') && (
        <div className="dialogs__advice">
          <Advice
            iconSize={24}
            id="feedback-2025-01"
            style={{padding: '4px 8px'}}
          >
            <Text size={14}>
              <Link
                href={
                  i18n.resolvedLanguage == 'ru'
                    ? 'https://forms.gle/qFAzMMaxyXQgHiEd9'
                    : 'https://forms.gle/q3hiYRViq7FYJv2a9'
                }
                isTargetBlank
              >
                {`🚀 ${t`Help us be better`}`}
              </Link>
            </Text>
          </Advice>
        </div>
      )}

      <div className="dialogs__header-wrapper">
        <div style={{width: 64}}>
          <Wrapper>
            {instance.version == 'chat' && (
              <ManagerFilter managers={managers} setManagers={setManagers} />
            )}

            {!isLimited && <Labels labels={labels} setLabels={setLabels} />}
          </Wrapper>
        </div>

        <Text size={16} textAlign="center">{t`Chats`}</Text>

        <Wrapper justifyContent="flex-end" style={{width: 64}}>
          <Button
            color="transparent"
            tooltip={isUnreadFilter ? t`Show all` : t`Show unread`}
            onClick={() => setIsUnreadFilter(prevValue => !prevValue)}
          >
            <Icons.FunnelSimple
              color={isUnreadFilter ? 'green' : undefined}
              size={20}
            />
          </Button>
        </Wrapper>
      </div>

      <div className="dialogs__search">
        <InputAlt
          autoCapitalize="off"
          isLight
          maxWidth="100%"
          name="new-search"
          onChange={setSearch}
          type="search"
          value={search}
        />
      </div>
    </div>
  );
};

export default Header;

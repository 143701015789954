import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {
  Button,
  Checkbox,
  PageBody,
  PageFooter,
  PageMain,
  Toggle,
  Wrapper
} from 'components';
import {getUrlParams} from 'common/actions';
import {useTariffsContext} from '../tariffs-context';
import * as AppEnv from 'app-env';

import {PaymentGateway as IPaymentGateway, Stage} from './tariff-list-env';
import {useInitialInstanceId, useSumma} from './hooks';
import ConnectionSelect from './connection-select/connection-select';
import LicenseAgreement from './license-agreement/license-agreement';
import PaymentGateway from './payment-gateway/payment-gateway';
import Tariff from './tariff/tariff';
import TariffListContext from './tariff-list-context';

interface TariffList {
  tariffs: AppEnv.Tariffs;
}

const urlParams = getUrlParams();

const TariffList = ({tariffs}: TariffList) => {
  const {instances, isWrite} = useTariffsContext();

  const initialInstanceId = useInitialInstanceId();
  const [instanceId, setInstanceId] = useState(initialInstanceId);

  const [isChat, setIsChat] = useState(urlParams.version == 'chat');
  const [isMount, setIsMount] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(true);
  const [paymentGateway, setPaymentGateway] = useState<IPaymentGateway>();
  const [stage, setStage] = useState<Stage>();

  const {t} = useTranslation();

  useEffect(() => {
    if (!isMount) return;
    else if (stage) return;

    setInstanceId([]);
    setPaymentGateway(undefined);
  }, [stage]);

  useEffect(() => {
    setIsMount(true);
  }, []);

  const tariffOptions: AppEnv.Tariff[] = Object.values(
    tariffs.Pro || tariffs.Start
  );

  const initialSelectedTariff = tariffOptions.find(
    tariff => tariff.quantity == 3
  );

  const [selectedTariff, setSelectedTariff] = useState(initialSelectedTariff);
  const summa = useSumma(selectedTariff);

  const isToggle = Object.values(instances || {}).some(
    instance => instance.version == 'chat'
  );

  const options = [
    {label: `WhatsApp, Telegram ${t`and`} ${t`Avito`}`, value: false},
    {label: t`Online chat`, value: true}
  ];

  const total = parseFloat(summa || '0') * instanceId.length;

  return (
    <TariffListContext.Provider
      value={{isChat, selectedTariff, setSelectedTariff, total}}
    >
      <PageBody>
        <PageMain>
          {isToggle && (
            <Toggle
              onChange={setIsChat}
              options={options}
              style={{marginBottom: 16}}
              value={isChat}
            />
          )}

          <div className="tariffs">
            <Tariff
              tariffOptions={Object.values(tariffs.Start)}
              tariffPlan="Start"
              tariffs={tariffs}
            />

            {tariffs.Pro && (
              <Tariff
                tariffOptions={Object.values(tariffs.Pro)}
                tariffPlan="Pro"
                tariffs={tariffs}
              />
            )}

            {tariffs.Enterprise && (
              <Tariff
                tariffOptions={Object.values(tariffs.Enterprise)}
                tariffPlan="Enterprise"
                tariffs={tariffs}
              />
            )}
          </div>
        </PageMain>
      </PageBody>

      {isWrite && (
        <PageFooter>
          <Wrapper alignItems="center" gap={16}>
            <Button
              onClick={() => setStage('connection-select')}
              state={selectedTariff ? undefined : 'disabled'}
            >
              {t`Continue`}
            </Button>

            <Checkbox
              label={t`Subscribe`}
              name="is_subscribed"
              value={isSubscribed}
              onChange={setIsSubscribed}
            />
          </Wrapper>
        </PageFooter>
      )}

      <ConnectionSelect
        instanceId={instanceId}
        stage={stage}
        setInstanceId={setInstanceId}
        setStage={setStage}
      />

      {paymentGateway && (
        <LicenseAgreement
          instanceId={instanceId}
          isSubscribed={isSubscribed}
          paymentGateway={paymentGateway}
          setStage={setStage}
          stage={stage}
        />
      )}

      <PaymentGateway
        paymentGateway={paymentGateway}
        stage={stage}
        setPaymentGateway={setPaymentGateway}
        setStage={setStage}
      />
    </TariffListContext.Provider>
  );
};

export default TariffList;

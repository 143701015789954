import React from 'react';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {Select} from 'components';
import {useStaffContext} from 'pages/workspace/pages/staff/staff-context';
import * as AppEnv from 'app-env';

interface NewId {
  role: AppEnv.Role;
}

const NewId = ({role}: NewId) => {
  const {roleList} = useStaffContext();

  const [field, meta, helpers] = useField<number | undefined>('new_id');
  const {t} = useTranslation();

  const isValid = !(meta.error && meta.touched);

  const options = roleList
    ?.filter(item => item.id != role.id)
    .map(item => ({label: item.title, value: item.id}));

  return (
    <Select
      {...field}
      isValid={isValid}
      onChange={helpers.setValue}
      options={options}
      placeholder={t`Role`}
    />
  );
};

export default NewId;

import React from 'react';
import {Form, Formik} from 'formik';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';

import {
  Button,
  Popup,
  PopupFooter,
  PopupWrapper,
  SaveButton,
  Title as CoreTitle,
  Wrapper
} from 'components';
import * as AppEnv from 'app-env';

import Accesses from './accesses/accesses';
import Title from './title/title';

interface RoleForm {
  initialValues?: Pick<AppEnv.Role, 'accesses' | 'title'>;
  isActive: boolean;
  isUpd?: boolean;
  onSubmit: (
    values: Pick<AppEnv.Role, 'accesses' | 'title'>
  ) => Promise<boolean>;
  setIsActive: AppEnv.SetState<boolean>;
}

const validationSchema = yup
  .object()
  .shape({title: yup.string().trim().required()});

const RoleForm = ({
  initialValues = {accesses: JSON.stringify({}), title: ''},
  isActive,
  isUpd,
  onSubmit,
  setIsActive
}: RoleForm) => {
  const {t} = useTranslation();

  const getAccesses = (values: Pick<AppEnv.Role, 'accesses' | 'title'>) => {
    const accesses: AppEnv.RoleAccesses = JSON.parse(values.accesses);

    const isTrue = !Object.values(accesses).includes(false);
    if (!isTrue) return values.accesses;

    return JSON.stringify({});
  };

  const handleSubmit = (values: Pick<AppEnv.Role, 'accesses' | 'title'>) => {
    const accesses = getAccesses(values);
    return onSubmit({...values, accesses});
  };

  return (
    <Popup isActive={isActive} onClick={() => setIsActive(false)} width={408}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validationSchema={validationSchema}
      >
        <Form>
          <PopupWrapper>
            <CoreTitle.H3 style={{marginBottom: 16}}>
              {isUpd ? t`Edit the role` : t`New role`}
            </CoreTitle.H3>

            <Title />
            <Accesses />
          </PopupWrapper>

          <PopupFooter>
            <Wrapper gap={12}>
              <SaveButton>{t`Save`}</SaveButton>

              <Button color="fade" onClick={() => setIsActive(false)}>
                {t`Close`}
              </Button>
            </Wrapper>
          </PopupFooter>
        </Form>
      </Formik>
    </Popup>
  );
};

export default RoleForm;

import {useTranslation} from 'react-i18next';

import {Feat} from '../../../tariff-env';
import * as AppEnv from 'app-env';

const useFeatListChat = (tariffPlan: AppEnv.TariffPlan): Feat[] => {
  const {t} = useTranslation();

  return [
    {
      isChecked: true,
      name: `${t`File storage`} ${tariffPlan == 'Enterprise' ? t`6 months` : tariffPlan == 'Pro' ? t`3 months` : t`1 month`}`
    },
    {isChecked: true, isLimits: true, name: t`Sending and receiving files`},
    {isChecked: true, name: t`Unlimited message history`},
    {
      isChecked: tariffPlan != 'Start',
      isSoon: true,
      name: t`Automatic distribution`
    },
    {isChecked: tariffPlan != 'Start', name: t`Deleting a message`},
    {isChecked: tariffPlan != 'Start', name: t`Editing a message`},
    {isChecked: tariffPlan != 'Start', name: t`Quick reply buttons`},
    {isChecked: tariffPlan != 'Start', name: t`Reactions`},
    {isChecked: tariffPlan != 'Start', isSoon: true, name: t`Typing indicator`},
    {isChecked: tariffPlan != 'Start', name: t`Voice messages`}
  ];
};

export default useFeatListChat;

import axios from 'axios';

import {getUrlParams} from 'common/actions';
import * as AppEnv from 'app-env';

const getIsTelegram = (integration: AppEnv.Integration | undefined) => {
  const {version} = getUrlParams();
  return integration?.type == 'TELPHGRAM' || version == 'telegram';
};

const getIsWhatcrm = (integration: AppEnv.Integration | undefined) => {
  const {version} = getUrlParams();

  return (
    integration?.type == 'B24' || version == 'whatcrm' || version == 'whatsapp'
  );
};

const getToken = (integration: AppEnv.Integration | undefined) => {
  const isTelegram = getIsTelegram(integration);

  if (isTelegram) return 'xNFyD6k1vixbQuQZIBe4QMySkRlkfH6s';
  return 'b3e18c7fcaa2ff6a4ec2b6f55dd29399';
};

const getBaseURL = (integration: AppEnv.Integration | undefined) => {
  const {ref} = getUrlParams();

  const isTelegram = getIsTelegram(integration);
  const isWhatcrm = getIsWhatcrm(integration);

  if (process.env.REACT_APP_ENV == 'rocket') {
    if (isTelegram) return 'https://tg.rocket.whatcrm.net';
    else if (isWhatcrm) 'https://wa.rocket.whatcrm.net';
  }

  if (ref) return `https://${ref}`;
  else if (isTelegram) return 'https://telphgram.whatcrm.net';
  else if (isWhatcrm) return 'https://bitrix24.whatcrm.net';
};

const useInstance = (integration: AppEnv.Integration | undefined) => {
  const baseURL = getBaseURL(integration);
  if (!baseURL) return null;

  const token = getToken(integration);

  const instance = axios.create({
    baseURL,
    headers: {'Content-Type': 'application/json', 'X-Api-Token': token}
  });

  return instance;
};

export default useInstance;

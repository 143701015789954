import {FolderOption} from '../../../folders-env';
import {getIsDialogUnread} from 'common/actions';
import {useDispatchContext} from 'pages/workspace/pages/messenger/dispatch/dispatch-context';

import useFolderDialogList from './use-folder-dialog-list/use-folder-dialog-list';

const useUnreadDialogList = (folderOption: FolderOption) => {
  const {instance} = useDispatchContext();

  const folderDialogList = useFolderDialogList(folderOption);
  if (folderOption.value == 'archive') return [];

  const unreadDialogList = folderDialogList.filter(dialog =>
    getIsDialogUnread(instance, dialog)
  );

  return unreadDialogList;
};

export default useUnreadDialogList;

import upd from 'immutability-helper';

import {useRequest} from 'common/hooks';
import {getDialogId, getDialogProfileImage} from 'common/actions';
import * as AppEnv from 'app-env';

const useRequestDialogProfileImage = (
  instance: AppEnv.Instance,
  setDialogs: AppEnv.SetState<AppEnv.Dialogs | undefined>
) => {
  const {fetchProfileImage} = useRequest();

  const requestDialogProfileImage = async (dialog: AppEnv.Dialog) => {
    if (dialog.version == 'chat') return;

    const id = getDialogId(dialog, true);
    if (!id) return;

    const dialogProfileImage = getDialogProfileImage(instance, dialog);

    const isDialogProfileImage =
      !!dialogProfileImage &&
      !dialogProfileImage.includes('data:image/png;base64,');

    if (isDialogProfileImage) return;

    const res = await fetchProfileImage(instance.chat_key, id);
    if (!res) return;

    setDialogs(prevValue => {
      const index = prevValue?.[instance.id]?.findIndex(
        item => item.id == dialog.id
      );

      if (typeof index == 'undefined' || index < 0) return prevValue;

      return upd(prevValue, {
        [instance.id]: {[index]: {profileImage: {$set: res}}}
      });
    });
  };

  return requestDialogProfileImage;
};

export default useRequestDialogProfileImage;

import React from 'react';
import {useTranslation} from 'react-i18next';

import {Icons} from 'components';
import {SocialOption} from '../../socials-env';
import {useChatContext} from 'pages/workspace/pages/widgets/chat/chat-context';

const useOptions = () => {
  const {widget} = useChatContext();
  const {i18n} = useTranslation();

  const options: SocialOption[] = [
    {
      icon: <Icons.Telegram />,
      label: 'Telegram',
      value: 'telegram',
      version: 'telegram'
    },
    {
      icon: <Icons.WhatsApp />,
      label: 'WhatsApp',
      value: 'whatsapp',
      version: 'whatcrm'
    },
    {
      icon: <Icons.Facebook />,
      label: `Facebook${i18n.resolvedLanguage == 'ru' && '*'}`,
      value: 'facebook',
      version: 'facebook'
    },
    {
      icon: <Icons.Instagram />,
      label: `Instagram${i18n.resolvedLanguage == 'ru' && '*'}`,
      value: 'instagram',
      version: 'instagram'
    },
    {
      icon: <Icons.LinkedIn />,
      label: 'LinkedIn',
      value: 'linkedin',
      version: 'linkedin'
    },
    {
      icon: <Icons.Twitter />,
      label: 'X',
      value: 'twitter',
      version: 'twitter'
    }
  ];

  const fusedOptions: SocialOption[] = options.map(option => ({
    ...option,
    isDisabled: widget.socials.some(social => social.version == option.version)
  }));

  return fusedOptions;
};

export default useOptions;

import React from 'react';
import ReactAvatar from 'react-avatar';
import cn from 'classnames';

interface ProfileImage {
  id?: number | string | null;
  isOnline?: boolean;
  name?: string | null;
  size?: 36 | 116; // +4px from border
  src?: string | null;
  visibility?: 'hidden';
}

const colors = [
  '#c2d2ff',
  '#abdcff',
  '#edc8f7',
  '#ffcfbf',
  '#93edbf',
  '#c2d2ff',
  '#abdcff',
  '#edc8f7',
  '#ffcfbf',
  '#93edbf'
];

const getColor = (str: number | string) => {
  let normalizedStr = str;

  if (typeof normalizedStr == 'string' && !parseInt(normalizedStr)) {
    const encoder = new TextEncoder();
    normalizedStr = encoder.encode('Hello').join('');
  }

  const index = parseInt(normalizedStr.toString().slice(-1));
  return colors[index];
};

const ProfileImage = ({
  id,
  isOnline,
  name,
  size = 36,
  src,
  visibility
}: ProfileImage) => {
  const color = getColor(id || 0);

  const htmlSrc =
    src ||
    (name?.[0] == '+' ? '/static/img/profile-image/user.svg' : undefined);

  return (
    <div
      className={cn('profile-image', {'profile-image_online': isOnline})}
      style={{background: color, height: size, visibility, width: size}}
    >
      <ReactAvatar
        className="profile-image__avatar"
        color={color}
        maxInitials={2}
        name={name || undefined}
        size={size?.toString()}
        src={htmlSrc}
      />
    </div>
  );
};

export default ProfileImage;

import React from 'react';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import upd from 'immutability-helper';

import {Checkbox, Text, TimeInput, Wrapper} from 'components';
import {useChatContext} from '../../../../chat-context';

const WorkingHours = () => {
  const {setWidget, widget} = useChatContext();
  const {t} = useTranslation();

  const handleIsWorking = (index: number, value: boolean) => {
    const newValue = upd(widget.working_hours, {
      [index]: {isWorking: {$set: value}}
    });

    setWidget(prevValue => upd(prevValue, {working_hours: {$set: newValue}}));
  };

  const handleTime = (index: number, name: 'end' | 'start', value: string) => {
    const newValue = upd(widget.working_hours, {
      [index]: {[name]: {$set: value}}
    });

    setWidget(prevValue => upd(prevValue, {working_hours: {$set: newValue}}));
  };

  const handleWeek = (value: boolean) => {
    const newValue = widget.working_hours.map(item => ({
      ...item,
      isWorking: value
    }));

    setWidget(prevValue => upd(prevValue, {working_hours: {$set: newValue}}));
  };

  return (
    <Wrapper direction="column" gap={8}>
      <Checkbox
        label={t`Select all`}
        name="is-working"
        onChange={handleWeek}
        value={!widget.working_hours.some(item => !item.isWorking)}
      />

      {widget.working_hours.map((item, i) => (
        <Wrapper
          alignItems="center"
          flexWrap="nowrap"
          gap={8}
          key={i}
          style={!i ? {order: 1} : undefined}
        >
          <Checkbox
            name={`is-working-${i}`}
            label={moment.weekdaysShort(i)}
            value={item.isWorking}
            onChange={value => handleIsWorking(i, value)}
            style={{width: 72}}
          />

          <TimeInput
            name={`start-${i}`}
            onChange={value => handleTime(i, 'start', value)}
            value={item.start}
          />

          <Text size={14}>{'–'}</Text>

          <TimeInput
            name={`end-${i}`}
            onChange={value => handleTime(i, 'end', value)}
            value={item.end}
          />
        </Wrapper>
      ))}
    </Wrapper>
  );
};

export default WorkingHours;

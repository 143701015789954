import {useTranslation} from 'react-i18next';

import {getBaseUrl} from 'common/actions';
import {Product} from '../../../products-env';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';

import useBitrix24TopLevelDomain from './use-bitrix24-top-level-domain/use-bitrix24-top-level-domain';

const useWhatcrmProducts = () => {
  const {user} = useWorkspaceContext();

  const {i18n, t} = useTranslation();
  const bitrix24TopLevelDomain = useBitrix24TopLevelDomain();

  const {integration} = user;
  const {kommoUrl, webUrl} = getBaseUrl();

  const isBitrix24 =
    integration.type == 'B24' || integration.type == 'TELPHGRAM';

  const isKommo =
    integration.type == 'AMO' ||
    integration.type == 'AVITO' ||
    integration.type == 'TELEGRAM';

  const isPipedrive = integration.type == 'PIPEDRIVE';

  const products: Product[] = [
    {
      description_short: 'Whatcrm Web',
      description: t`Messaging using multiple WhatsApp, Telegram and Avito accounts.`,
      image: '/static/img/products/whatcrm-web.png',
      label: t`Open`,
      link: webUrl,
      skip: process.env.REACT_APP_TYPE == 'web'
    },
    {
      description_short: t`WhatsApp for Kommo`,
      description: t`Integration via WhatsApp Web API. Recommendations for stable operation: the phone is always online, on charge and with a good internet connection.`,
      image: '/static/img/products/whatcrm-whatsapp.png',
      label: t`Install`,
      link: `${kommoUrl}/oauth/?state=state&mode=popup&client_id=2c45cb68-3a03-4bad-a531-b2c83bda6b08`,
      skip: integration.type == 'AMO' || isBitrix24 || isPipedrive
    },
    {
      description_short: t`Telegram for Kommo`,
      description: t`Send and receive messages to your personal Telegram phone number. Dialogues and messages are unlimited.`,
      image: '/static/img/products/whatcrm-telegram.png',
      label: t`Install`,
      link: `${kommoUrl}/oauth/?state=state&mode=popup&client_id=559bf34b-9f26-4e73-83a2-28bb3b51595c`,
      skip: integration.type == 'TELEGRAM' || isBitrix24 || isPipedrive
    },
    {
      description_short: t`Avito for Kommo`,
      description: t`The integration allows customers to automate their work with Avito and communicate with customers directly from Kommo.`,
      image: '/static/img/products/whatcrm-avito.png',
      label: t`Install`,
      link: `${kommoUrl}/oauth/?mode=popup&client_id=9e031bf8-d33d-470a-9986-15ebfd9d7410`,
      skip: integration.type == 'AVITO' || isBitrix24 || isPipedrive
    },
    {
      description_short: t`WhatsApp for Bitrix24`,
      description: t`Integration via WhatsApp Web API. Recommendations for stable operation: the phone is always online, on charge and with a good internet connection.`,
      image: '/static/img/products/whatcrm-whatsapp.png',
      label: t`Install`,
      link: isBitrix24
        ? `https://${integration.domain}/market/detail/sipteco.whatsapp_dlya_bitriks24_wapp_im/`
        : `https://www.bitrix24.${bitrix24TopLevelDomain}/apps/app/sipteco.whatsapp_dlya_bitriks24_wapp_im/`,
      skip: integration.type == 'B24' || isKommo || isPipedrive
    },
    {
      description_short: t`Telegram for Bitrix24`,
      description: t`Send and receive messages to your personal Telegram phone number. Dialogues and messages are unlimited.`,
      image: '/static/img/products/whatcrm-telegram.png',
      label: t`Install`,
      link: isBitrix24
        ? `https://${integration.domain}/market/detail/telphin.telegram_dlya_bitriks24_whatcrm/`
        : `https://www.bitrix24.${bitrix24TopLevelDomain}/apps/app/telphin.telegram_dlya_bitriks24_whatcrm/`,
      skip: integration.type == 'TELPHGRAM' || isKommo || isPipedrive
    },
    {
      description_short: t`WhatsApp Checker for Bitrix24`,
      description: t`Verify phone number from entities (lead, contact, deal and company) in WhatsApp. Use with Bitrix24 business processes.`,
      image: '/static/img/products/whatcrm-checker.png',
      label: t`Install`,
      link: `https://www.bitrix24.ru/apps/app/telphin.whatsapp_checker_ot_whatcrm/`,
      skip: i18n.resolvedLanguage != 'ru' || isKommo || isPipedrive
    },
    {
      description_short: t`WhatsApp for monday.com`,
      description: t`Communicate effectively with customers via WhatsApp and integrate this communication with monday.com.`,
      image: '/static/img/products/whatcrm-whatsapp.png',
      label: t`Install`,
      link: 'https://auth.monday.com/oauth2/authorize?client_id=7ca9d4564e2f61767840e2c08ca36a43&response_type=install',
      skip: integration.type == 'MONDAY' || isBitrix24 || isKommo || isPipedrive
    }
  ].filter(item => !item.skip);

  return products;
};

export default useWhatcrmProducts;

import React, {useEffect, useState} from 'react';
import QRCode from 'qrcode';
import {useTranslation} from 'react-i18next';

import {
  Button,
  Link,
  PopupFooter,
  PopupWrapper,
  Preloader,
  Wrapper
} from 'components';
import {Label} from '../../../components';
import {useInstanceContext} from '../../../instance-context';
import * as AppEnv from 'app-env';

import Pending from './pending/pending';

interface QrCode {
  isSignInByPhoneNumberShown: boolean;
  setIsQrCodeMethod: AppEnv.SetState<boolean>;
}

const QrCode = ({isSignInByPhoneNumberShown, setIsQrCodeMethod}: QrCode) => {
  const {instance} = useInstanceContext();
  const [qrCode, setQrCode] = useState<string>();
  const {t} = useTranslation();

  const getQrCode = async () => {
    const {condition} = instance;
    if (instance.version != 'avito') return setQrCode(condition?.qrCode);

    const res = await QRCode.toDataURL(
      `https://avito.whatcrm.net/v1/auth?state=${instance.chat_key}`
    );

    setQrCode(res);
  };

  useEffect(() => {
    getQrCode();
  }, [instance.condition?.qrCode]);

  const {condition} = instance;

  return (
    <>
      <PopupWrapper>
        <Label style={{marginBottom: 8}} />

        <div className="qr-code">
          {qrCode ? (
            <div
              className="qr-code__image"
              style={{backgroundImage: `url('${qrCode}')`}}
            />
          ) : instance.status == 'PENDING' ? (
            <Pending />
          ) : (
            <Preloader />
          )}
        </div>

        {instance.version == 'avito' && (
          <Wrapper justifyContent="center">
            <Link
              href={`https://avito.whatcrm.net/v1/auth?state=${instance.chat_key}`}
              isTargetBlank
              isTextAlignCenter
            >
              {t`Follow the link`}
            </Link>
          </Wrapper>
        )}
      </PopupWrapper>

      {isSignInByPhoneNumberShown && (
        <PopupFooter>
          <Wrapper gap={12} justifyContent="space-between">
            <div />

            {isSignInByPhoneNumberShown && (
              <Button
                color="transparent"
                onClick={() => setIsQrCodeMethod(false)}
                state={condition?.qrCode ? undefined : 'disabled'}
              >
                {t`Use phone number`}
              </Button>
            )}
          </Wrapper>
        </PopupFooter>
      )}
    </>
  );
};

export default QrCode;

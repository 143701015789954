import React from 'react';
import {useTranslation} from 'react-i18next';
import moment from 'moment';

import {Text} from 'components';
import {useInstanceBilling} from 'common/hooks';
import {useInstanceContext} from '../../instance-context';

const DatePay = () => {
  const {instance} = useInstanceContext();

  const {t} = useTranslation();
  const billing = useInstanceBilling(instance);

  return (
    <>
      {billing.value != 'premium' && (instance.date_pay || 0) > 0 && (
        <Text>
          <Text color="wild" isSpan>
            {t`Payment date`}
            {':'}
          </Text>{' '}
          {moment
            .unix(instance.date_pay || 0)
            .utcOffset(3)
            .format('LLL')}
          {' '}
          {'UTC+3'}
        </Text>
      )}
    </>
  );
};

export default DatePay;

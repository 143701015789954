const getBaseUrl = () => {
  const slice = window.location.host.slice(-2);

  let apiUrl = process.env.REACT_APP_API;
  let kommoUrl = 'https://www.kommo.com';
  let messagesUrl = 'wss://messages.whatcrm.net';
  let webUrl = process.env.REACT_APP_WEB || 'https://web.whatcrm.net';

  if (slice != 'ru') return {apiUrl, kommoUrl, messagesUrl, webUrl};

  apiUrl = process.env.REACT_APP_API_RU;
  kommoUrl = 'https://www.amocrm.ru';
  messagesUrl = 'wss://messages.whatcrm.ru';
  webUrl = process.env.REACT_APP_WEB_RU || 'https://web.whatcrm.ru';

  return {apiUrl, kommoUrl, messagesUrl, webUrl};
};

export default getBaseUrl;

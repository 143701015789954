import React, {useEffect, useState} from 'react';

import {Icons, SelectMulti, SelectOption} from 'components';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

interface ManagerFilter {
  managers: string[];
  setManagers: AppEnv.SetState<string[]>;
}

const ManagerFilter = ({managers, setManagers}: ManagerFilter) => {
  const {getManagerList} = useWorkspaceContext();
  const [options, setOptions] = useState<SelectOption<string>[]>();

  const getOptions = async () => {
    const managerList = await getManagerList();

    const newOptions: SelectOption<string>[] = managerList.map(item => ({
      label: item.fullname || item.email,
      value: item.email
    }));

    setOptions(newOptions);
  };

  useEffect(() => {
    getOptions();
  }, []);

  const icon = (
    <Icons.Manager
      color={managers.length > 0 ? 'green' : undefined}
      size={20}
    />
  );

  return (
    <SelectMulti
      icon={icon}
      isAlt
      onChange={setManagers}
      options={options}
      value={managers}
    />
  );
};

export default ManagerFilter;

import {useCopyToClipboard} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {ProfileListItemParams} from '../../../../components';
import {useAppContext} from 'app-context';
import {useChatContext} from '../../../../../../chat-context';

const useUsername = (): ProfileListItemParams | null => {
  const {setNotification} = useAppContext();
  const {telegramUser} = useChatContext();

  const {t} = useTranslation();
  const copyToClipboard = useCopyToClipboard();

  const handleClick = async (str: string) => {
    const res = await copyToClipboard(str);
    setNotification({title: res.message});
  };

  if (!telegramUser?.usernames) return null;
  const {editableUsername} = telegramUser.usernames;

  const username: ProfileListItemParams = {
    label: t`username`,
    onClick: () => handleClick(editableUsername),
    type: 'copy',
    value: `@${editableUsername}`
  };

  return username;
};

export default useUsername;

import {useEffect} from 'react';
import upd from 'immutability-helper';

import {useRequest} from 'common/hooks';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

const useMe = () => {
  const {activeInstances, setUser} = useWorkspaceContext();
  const {fetchMe} = useRequest();

  const getMe = async (instance: AppEnv.Instance) => {
    if (!['avito', 'telegram', 'whatcrm'].includes(instance.version)) return;

    const res = await fetchMe(instance.chat_key);
    if (!res) return;

    setUser(prevValue =>
      upd(prevValue, {instances: {[instance.id]: {me: {$set: res}}}})
    );
  };

  useEffect(() => {
    activeInstances.forEach(instance => getMe(instance));
  }, []);
};

export default useMe;

import {useCallback, useEffect, useState} from 'react';
import _ from 'lodash';
import jwtEncode from 'jwt-encode';

import {useChatContext} from '../../../chat-context';

const getJwt = (values: unknown) => jwtEncode(values, '');

const useScript = () => {
  const {widget} = useChatContext();
  const [jwt, setJwt] = useState(getJwt(widget));

  const callback = useCallback(
    _.debounce(widget => setJwt(getJwt(widget)), 300),
    []
  );

  useEffect(() => {
    callback(widget);
  }, [widget]);

  return `<script src="${process.env.REACT_APP_WIDGET}/chat/scripts/${jwt}.js"></script>`;
};

export default useScript;

import React from 'react';
import {useTranslation} from 'react-i18next';
import moment from 'moment';

import {CardHead, Title} from 'components';
import {useInstanceBilling} from 'common/hooks';
import {useInstanceContext} from '../instance-context';

const InstanceHeader = () => {
  const {instance} = useInstanceContext();

  const {t} = useTranslation();
  const billing = useInstanceBilling(instance);

  return (
    <CardHead justifyContent="space-between">
      <Title.H3 isNoWrap isUppercase>
        {billing.tariff_plane || billing.label}
      </Title.H3>

      {typeof billing.date == 'number' && (
        <Title.H3 style={{flexShrink: 0}} isNoWrap>
          {t`until`} {moment.unix(billing.date).format('L')}
        </Title.H3>
      )}
    </CardHead>
  );
};

export default InstanceHeader;

import React, {useEffect, useState} from 'react';
import {Form, Formik} from 'formik';
import {jwtDecode} from 'jwt-decode';
import {useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';
import jwtEncode from 'jwt-encode';

import {Page, PageBody, PageMain, Placeholder, Preloader} from 'components';
import {useRequest} from 'common/hooks';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

import Aside from './aside/aside';
import ChatContext from './chat-context';
import Window from './window/window';

const validationSchema = yup.object().shape({
  instance_id: yup.number().required(),
  title: yup.string().trim().required()
});

const Chat = () => {
  const {user} = useWorkspaceContext();

  const [chat, setChat] = useState<AppEnv.Chat | null>();
  const [isCodeActive, setIsCodeActive] = useState(false);
  const [widget, setWidget] = useState<AppEnv.ChatWidget>();

  const {fetchChatsOne, putChatsUpdate} = useRequest();
  const {t} = useTranslation();
  const {uid} = useParams();

  useEffect(() => {
    document.title = `${chat?.title || t`Widget`} - Whatcrm Web`;
  }, [chat]);

  useEffect(() => {
    if (!chat) return;

    const newWidget = jwtDecode<AppEnv.ChatWidget>(chat.token);
    setWidget(newWidget);
  }, [chat]);

  const getChat = async () => {
    if (!uid) return setChat(null);

    const res = await fetchChatsOne(uid);
    if (!res) return setChat(null);

    setChat(res || null);
  };

  useEffect(() => {
    getChat();
  }, []);

  const submitForm = async (values: AppEnv.ChatPut) => {
    if (!chat) return null;

    const {integration} = user;
    const token = jwtEncode(widget, '');

    const res = await putChatsUpdate(integration.id, chat.id, {
      ...values,
      token
    });

    return res;
  };

  const handleSubmit = async (values: AppEnv.ChatPut) => {
    const res = await submitForm(values);
    if (!res) return true;

    setChat(res);
    setIsCodeActive(true);

    return true;
  };

  return (
    <>
      {chat !== undefined ? (
        chat && widget ? (
          <ChatContext.Provider
            value={{
              chat,
              isCodeActive,
              setIsCodeActive,
              setWidget,
              submitForm,
              widget
            }}
          >
            <div className="widget">
              <Formik
                initialValues={chat}
                onSubmit={handleSubmit}
                validateOnBlur={false}
                validationSchema={validationSchema}
              >
                <Form>
                  <Window />
                  <Aside />
                </Form>
              </Formik>
            </div>
          </ChatContext.Provider>
        ) : (
          <Page>
            <PageBody>
              <PageMain>
                <Placeholder isMaxHeight title="404 Not Found" />
              </PageMain>
            </PageBody>
          </Page>
        )
      ) : (
        <Page>
          <PageBody>
            <PageMain>
              <Preloader isMaxHeight />
            </PageMain>
          </PageBody>
        </Page>
      )}
    </>
  );
};

export default Chat;

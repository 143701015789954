import * as AppEnv from 'app-env';

const getInitialWidget = (
  instance: AppEnv.Instance | undefined
): AppEnv.ChatWidget => ({
  bottom_mobile: null,
  bottom: null,
  callback_button_text: '',
  callback_field_type: 'nonlinear',
  callback_header: '',
  callback_is_message: true,
  callback_type: 'horizontal',
  chat_message_type: 'minimalism',
  chat_type: 'max',
  colors: {primary: '#2f59cc', secondary: '#ffffff'},
  icon: 'faq-chat',
  instance_chat_key: instance?.chat_key || null,
  is_callback: true,
  is_chat: true,
  is_email: false,
  is_name: false,
  is_pulse: true,
  privacy: '',
  right_mobile: null,
  right: null,
  socials: [],
  theme: 'light',
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  type: 'radial',
  unavailable_text: '',
  working_hours: [
    {end: '', isWorking: false, start: ''},
    {end: '', isWorking: true, start: ''},
    {end: '', isWorking: true, start: ''},
    {end: '', isWorking: true, start: ''},
    {end: '', isWorking: true, start: ''},
    {end: '', isWorking: true, start: ''},
    {end: '', isWorking: false, start: ''}
  ]
});

export default getInitialWidget;

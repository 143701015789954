import React from 'react';
import {Route, Routes, useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {Body, Nav, Page} from 'components';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';

import Integration from './integration/integration';
import Partner from './partner/partner';
import Personal from './personal/personal';

const Settings = () => {
  const {user} = useWorkspaceContext();

  const {t} = useTranslation();
  const location = useLocation();

  const {integration, manager} = user;
  const {roles} = manager;

  const options = [{label: t`Personal settings`, value: '/settings'}];

  if (roles.settings_write != false) {
    options.push({label: t`Integration`, value: '/settings/integration'});

    if (
      integration.is_partner < 1 &&
      (process.env.REACT_APP_ENV == 'dev' ||
        process.env.REACT_APP_ENV == 'test')
    )
      options.push({label: t`Partner`, value: '/settings/partner'});
  }

  return (
    <Body>
      <Page>
        <Nav options={options} value={location.pathname} />

        <Routes>
          <Route path="/" element={<Personal />} />
          <Route path="/integration" element={<Integration />} />
          <Route path="/partner" element={<Partner />} />
        </Routes>
      </Page>
    </Body>
  );
};

export default Settings;

import React from 'react';
import {useTranslation} from 'react-i18next';
import upd from 'immutability-helper';

import {Switch} from 'components';
import {useChatContext} from '../../../../chat-context';

const CallbackIsMessage = () => {
  const {setWidget, widget} = useChatContext();
  const {t} = useTranslation();

  const handleChange = (value: boolean) =>
    setWidget(prevValue =>
      upd(prevValue, {callback_is_message: {$set: value}})
    );

  return (
    <Switch
      label={t`Message entry field`}
      onChange={handleChange}
      value={widget.callback_is_message}
    />
  );
};

export default CallbackIsMessage;

import React from 'react';
import {useCopyToClipboard} from 'whatcrm-core';

import {ProfileListItem} from '../components';
import {useAppContext} from 'app-context';
import {useChatContext} from '../../../chat-context';

const Utm = () => {
  const {setNotification} = useAppContext();
  const {dialog} = useChatContext();

  const copyToClipboard = useCopyToClipboard();

  const handleClick = async (str: string) => {
    const res = await copyToClipboard(str);
    setNotification({title: res.message});
  };

  const {user = undefined} = dialog.version == 'chat' ? dialog : {};
  const {utm} = user || {};

  return (
    <>
      {Object.values(utm || {}).some(Boolean) && (
        <div className="profile__list">
          {Object.entries(utm || {}).map(([key, value]) => (
            <React.Fragment key={key}>
              {value && (
                <ProfileListItem
                  label={key}
                  onClick={() => handleClick(`${key}=${value}`)}
                  type="copy"
                  value={value}
                />
              )}
            </React.Fragment>
          ))}
        </div>
      )}
    </>
  );
};

export default Utm;

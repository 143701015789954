import React from 'react';
import {useFormikContext} from 'formik';
import {useOnKeyDown} from 'whatcrm-core';

import {useWhatsAppMessageContext} from '../../../whatsapp-message-context';
import {Values} from '../listing-env';
import {Wrapper} from 'components';

import Section from './section/section';

interface SectionList {
  isListActive: boolean;
}

const SectionList = ({isListActive}: SectionList) => {
  const {message} = useWhatsAppMessageContext();
  const {isSubmitting, submitForm, values} = useFormikContext<Values>();

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key != 'Enter') return;
    submitForm();
  };

  useOnKeyDown(handleKeyDown, [isListActive, !isSubmitting, values.button]);

  return (
    <Wrapper direction="column" gap={16}>
      {message._data.list?.sections.map((item, i) => (
        <Section key={i} onSubmit={submitForm} section={item} />
      ))}
    </Wrapper>
  );
};

export default SectionList;

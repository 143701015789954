import React, {useState} from 'react';

import {Button} from 'components';
import {getDialogId} from 'common/actions';
import {useChatContext} from 'pages/workspace/pages/messenger/dispatch/chat-provider/chat/chat-context';
import {useDispatchContext} from 'pages/workspace/pages/messenger/dispatch/dispatch-context';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

interface ReplyButton {
  button: AppEnv.TelegramReplyMarkupButton;
}

const ReplyButton = ({button}: ReplyButton) => {
  const {instance} = useDispatchContext();
  const {dialog} = useChatContext();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const {postSendMessage} = useRequest();

  const handleClick = async () => {
    const chatId = getDialogId(dialog, true);
    if (!chatId) return;

    setIsSubmitting(true);
    await postSendMessage(instance.chat_key, {body: button.text, chatId});
    setIsSubmitting(false);
  };

  const isDisabled = button._ != 'keyboardButton';

  return (
    <Button
      color="green-border"
      minHeight={32}
      onClick={handleClick}
      state={isSubmitting ? 'submitting' : isDisabled ? 'disabled' : undefined}
      tooltip={button.text}
    >
      <span>{button.text}</span>
    </Button>
  );
};

export default ReplyButton;

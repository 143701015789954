import React, {useState} from 'react';
import {formatPhoneNumber, isolateNumbers} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import upd from 'immutability-helper';

import {
  Button,
  Confirm,
  Link,
  PageBody,
  PageFooter,
  PageMain,
  Prop,
  PropList,
  Text
} from 'components';
import {useAppContext} from 'app-context';
import {useRequest} from 'common/hooks';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';

const PartnerInfo = () => {
  const {setNotification} = useAppContext();
  const {setUser, user} = useWorkspaceContext();

  const [isConfirm, setIsConfirm] = useState(false);

  const {fetchPartnersCancel} = useRequest();
  const {t} = useTranslation();

  const cancelPartner = async () => {
    const {integration} = user;

    const res = await fetchPartnersCancel(integration.id);
    if (!res) return true;

    setNotification({title: `You have ended the partnership.`});
    setUser(prevValue => upd(prevValue, {partner: {$set: null}}));

    return true;
  };

  const handleClick = (res: boolean) => {
    if (res) return cancelPartner();
    setIsConfirm(false);
  };

  const {partner = undefined} = 'partner' in user ? user : {};

  return (
    <>
      <PageBody>
        <PageMain>
          <PropList>
            {partner?.title && (
              <Prop>
                <Text size={12}>{t`company name`}</Text>

                <Text fontWeight={600} size={16}>
                  {partner.title}
                </Text>
              </Prop>
            )}

            <Prop>
              <Text size={12}>{t`domain`}</Text>

              <Text fontWeight={600} size={16}>
                {partner?.domain}
              </Text>
            </Prop>

            {partner?.phone && (
              <Prop>
                <Text size={12}>{t`phone number`}</Text>

                <Text fontWeight={600} size={16}>
                  <Link href={`tel:${isolateNumbers(partner.phone)}`}>
                    {formatPhoneNumber(partner.phone)}
                  </Link>
                </Text>
              </Prop>
            )}

            {partner?.email && (
              <Prop>
                <Text size={12}>{t`email`}</Text>

                <Text fontWeight={600} size={16}>
                  <Link href={`mailto:${partner.email}`}>{partner.email}</Link>
                </Text>
              </Prop>
            )}

            {!!partner?.date_add && (
              <Prop>
                <Text size={12}>{t`partnership start date`}</Text>

                <Text fontWeight={600} size={16}>
                  {moment.unix(partner.date_add).format('L')}
                </Text>
              </Prop>
            )}
          </PropList>
        </PageMain>
      </PageBody>

      <PageFooter>
        {partner && (
          <Button onClick={() => setIsConfirm(true)}>
            {t`End partnership`}
          </Button>
        )}
      </PageFooter>

      <Confirm
        isActive={isConfirm}
        onClick={handleClick}
        title={t`End the partnership?`}
      />
    </>
  );
};

export default PartnerInfo;

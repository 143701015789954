import React, {useRef} from 'react';
import {useOnClickOutside, useScrollClassName} from 'whatcrm-core';
import cn from 'classnames';

import * as AppEnv from 'app-env';

interface Backdrop {
  children: React.ReactNode;
  isActive: boolean;
  setIsActive: AppEnv.SetState<boolean>;
  style: React.CSSProperties | undefined;
}

const Backdrop = ({children, isActive, setIsActive, style}: Backdrop) => {
  const ref = useRef<HTMLDivElement>(null);
  const scrollClassName = useScrollClassName();

  useOnClickOutside(ref, {
    isEventStart: true,
    onClick: () => setIsActive(false)
  });

  const maxWidth =
    window.innerWidth - (typeof style?.left == 'number' ? style.left : 0) - 16;

  return (
    <div
      className={cn('backdrop', {
        [scrollClassName]: scrollClassName,
        backdrop_active: isActive
      })}
    >
      <div className="backdrop__content" style={{...style, maxWidth}}>
        <div ref={ref}>{children}</div>
      </div>
    </div>
  );
};

export default Backdrop;

import * as AppEnv from 'app-env';

const getInstanceTariff = (instance: AppEnv.Instance) => {
  if (instance.is_premium) return 'Enterprise';
  else if (instance.date_trial && instance.date_trial > +new Date() / 1000)
    return 'Pro';

  return instance.tariff_plane || 'Start';
};

export default getInstanceTariff;

import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {
  Body,
  Button,
  Icons,
  Nav,
  Page,
  PageBody,
  PageMain,
  Placeholder
} from 'components';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';

import Blacklisted from './blacklisted/blacklisted';
import Block from './block/block';

const Blocked = () => {
  const {user} = useWorkspaceContext();
  const [isBlockActive, setIsBlockActive] = useState(false);
  const {t} = useTranslation();

  useEffect(() => {
    document.title = `${t`Blocked chats`} - Whatcrm Web`;
  }, []);

  const {roles} = user.manager;

  return (
    <>
      <Body>
        <Page>
          <Nav
            options={[{label: t`Blocked chats`, value: 'blocked'}]}
            value="blocked"
          />

          <PageBody>
            {roles.blacklists_write != false ? (
              <PageMain flexGrow={0}>
                <Button
                  color="blue-dash"
                  onClick={() => setIsBlockActive(true)}
                  width="100%"
                >
                  {t`Add`}
                  <Icons.Plus color="blue" size={20} />
                </Button>
              </PageMain>
            ) : (
              <div style={{marginBottom: 20}} />
            )}

            {user.blacklist?.length ? (
              user.blacklist.map(blacklisted => (
                <Blacklisted blacklisted={blacklisted} key={blacklisted.id} />
              ))
            ) : (
              <PageMain>
                <Placeholder isMaxHeight title={t`No blocked chats`} />
              </PageMain>
            )}
          </PageBody>
        </Page>
      </Body>

      <Block
        isBlockActive={isBlockActive}
        setIsBlockActive={setIsBlockActive}
      />
    </>
  );
};

export default Blocked;

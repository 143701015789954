import React from 'react';
import {Form, Formik} from 'formik';
import {useTranslation} from 'react-i18next';
import upd from 'immutability-helper';

import {
  Button,
  Popup,
  PopupWrapper,
  SaveButton,
  Text,
  Wrapper
} from 'components';
import {getDialogId} from 'common/actions';
import {useAppContext} from 'app-context';
import {useChatContext} from 'pages/workspace/pages/messenger/dispatch/chat-provider/chat/chat-context';
import {useDispatchContext} from 'pages/workspace/pages/messenger/dispatch/dispatch-context';
import {useMessageContext} from '../../../../message-context';
import {useMessengerContext} from 'pages/workspace/pages/messenger/messenger-context';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

import Revoke from './revoke/revoke';

interface Values {
  revoke: boolean;
}

interface DeleteMessage {
  isConfirmationActive: boolean;
  setIsConfirmationActive: AppEnv.SetState<boolean>;
}

const DeleteMessage = ({
  isConfirmationActive,
  setIsConfirmationActive
}: DeleteMessage) => {
  const {setNotification} = useAppContext();
  const {setDialogs} = useMessengerContext();
  const {instance} = useDispatchContext();
  const {dialog} = useChatContext();
  const {message} = useMessageContext();

  const {fetchDeleteMessage, fetchDeleteMessageAvito} = useRequest();
  const {t} = useTranslation();

  const deleteMessage = async (values: Values) => {
    const dialogId = getDialogId(dialog, true);
    if (!dialogId) return;

    await fetchDeleteMessage(
      instance.chat_key,
      dialogId,
      message.id,
      values.revoke
    );

    setIsConfirmationActive(false);

    await new Promise(resolve => setTimeout(resolve, 150));
    setNotification({title: t`The message has been deleted`});

    setDialogs(prevValue => {
      const dialogIndex = prevValue?.[instance.id]?.findIndex(
        item => getDialogId(item, true) == getDialogId(dialog, true)
      );

      if (typeof dialogIndex != 'number' || dialogIndex < 0) return;

      const messageIndex = prevValue?.[instance.id]?.[
        dialogIndex
      ].messages?.findIndex(item => item.id == message.id);

      if (typeof messageIndex != 'number' || messageIndex < 0) return;

      return upd(prevValue, {
        [instance.id]: {
          [dialogIndex]: {messages: {$splice: [[messageIndex, 1]]}}
        }
      });
    });
  };

  const deleteMessageAvito = async () => {
    const dialogId = getDialogId(dialog);
    if (!dialogId) return;

    const res = await fetchDeleteMessageAvito(
      instance.chat_key,
      instance.instanceId,
      dialogId.toString(),
      message.id.toString()
    );

    if (res) setIsConfirmationActive(false);
    return true;
  };

  const handleSubmit = async (values: Values) => {
    if (dialog.version == 'avito') return deleteMessageAvito();
    return deleteMessage(values);
  };

  const isRevokeShown =
    dialog.version == 'telegram' || ('_data' in message && message.fromMe);

  return (
    <Popup
      isActive={isConfirmationActive}
      isConfirm
      onClick={() => setIsConfirmationActive(false)}
    >
      <Formik initialValues={{revoke: false}} onSubmit={handleSubmit}>
        <Form>
          <PopupWrapper>
            <Wrapper direction="column" gap={16} style={{marginBottom: 16}}>
              <Text fontWeight={500} size={16} textAlign="center">
                {t`Delete the message`}
              </Text>
              {isRevokeShown && <Revoke />}
            </Wrapper>

            <div className="confirm">
              <SaveButton>{t`Delete`}</SaveButton>

              <Button
                color="fade"
                onClick={() => setIsConfirmationActive(false)}
              >
                {t`Close`}
              </Button>
            </div>
          </PopupWrapper>
        </Form>
      </Formik>
    </Popup>
  );
};

export default DeleteMessage;
